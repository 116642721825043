import React from 'react';
import "../BodyManage.scss";
import { useTranslation } from "react-i18next";
import {
    DataGrid,
    GridToolbar,
  } from "@mui/x-data-grid";
  

export default function FileDisplayGrid({
    rows,
    columnss,
    getDetailPanelHeight,
    getDetailPanelContent,
    CustomPagination,
    handleRowClick,
    setCurrentDirectory,
    navigate
}){
    const { t } = useTranslation("translation", {
        keyPrefix: "layouts.Body.BodyManageContent",
      });

    return (
        <>
          <DataGrid
            rows={rows}
            columns={columnss}
            pagination
            rowThreshold={0}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            slots={{
              pagination: CustomPagination,
              toolbar: GridToolbar,
            }}
            localeText={{
              toolbarColumns: t("columns"),
              toolbarFilters: t("filter"),
              toolbarDensity: t("density"),
              toolbarExport: t("export"),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            {...rows}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            onCellClick={(params, event) => {
              const { field } = params.colDef;
              if (
                field === "title"
              ) {
                handleRowClick({row:params,setCurrentDirectory,navigate});
              }
            }}
            className="grid"
          />
        </>
    )
}
