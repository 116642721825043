import React, { useState } from "react";
import "./SignupPage.scss";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  Select,
  InputAdornment,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import HeaderLanding from "../../layouts/Header/HeaderLanding/HeaderLanding";
import FooterLanding from "../../layouts/Footer/FooterLanding";
import { useTranslation } from "react-i18next";
import { createAccount } from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import handleErrorFunction from "../../funtions/handleErrorFunction";

const DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
const siteKey = process.env.REACT_APP_KEY_RECAPTCHA;

export default function SignupPage() {
  const navigate = useNavigate();

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  //Đa ngôn ngữ
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.SignupPage",
  });

  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  //Kiểm tra check chính sách bảo mật dữ liệu chưa
  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  //Dữ liệu form đăng ký
  const [newAccount, setNewAccount] = useState({
    firstName: "",
    lastName: "",
    email: "",
    accountName: "",
    subDomain: "",
    LMS: "",
  });
  const handleNewAccountChange = (event) => {
    const { name, value } = event.target;
    setNewAccount({
      ...newAccount,
      [name]: value,
    });

    switch (name) {
      case "firstName":
        setCheckFirstName("");
        break;
      case "lastName":
        setCheckLastName("");
        break;
      case "email":
        setCheckEmail("");
        break;
      case "accountName":
        setCheckAccountName("");
        break;
      case "subDomain":
        setCheckSubdomain("");
        setCheckDomainExist("");
        break;
      default:
        break;
    }
  };

  //Kiểm tra dữ liệu các field
  const Validate = async () => {
    let check = 0;
    if (newAccount.firstName === "") {
      check++;
    }
    if (newAccount.lastName === "") {
      check++;
    }
    if (newAccount.email === "") {
      check++;
    }
    if (newAccount.accountName === "") {
      check++;
    }
    if (newAccount.subDomain === "") {
      check++;
    }
    if (newAccount.LMS === "") {
      check++;
    }
    if (check === 0) {
      return true;
    } else {
      return false;
    }
  };

  //Đóng, mở Modal (Vui lòng chờ...)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [checkFirstName, setCheckFirstName] = useState("");
  const [checkLastName, setCheckLastName] = useState("");
  const [checkEmail, setCheckEmail] = useState("");
  const [checkAccountName, setCheckAccountName] = useState("");
  const [checkSubdomain, setCheckSubdomain] = useState("");
  const [checkDomainExist, setCheckDomainExist] = useState("");

  //Gửi dữ liệu form đăng ký
  const handleCreateAccount = async () => {
    if (recaptchaValue) {
      const valid = await Validate();

      if (valid === true) {
        handleOpen();
        try {
          const response = await createAccount(newAccount);
          if (response) {
            navigate(`/verify-email-enable?email=${newAccount.email}`);
          }
        } catch (error) {
          const errorReturn = handleErrorFunction(error, v);
          toast.error(errorReturn);

          const errorMappings = {
            domain_existed: setCheckDomainExist,
            firstName: setCheckFirstName,
            lastName: setCheckLastName,
            accountName: setCheckAccountName,
            subDomain: setCheckSubdomain,
            email: setCheckEmail,
          };

          const setErrorCheck = (errorReturn) => {
            for (const [key, value] of Object.entries(errorMappings)) {
              if (errorReturn === v(key)) {
                value(false);
              } else {
                value(true);
              }
            }
          };

          setErrorCheck(errorReturn);

          handleClose();
        }
      }
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div className="signup_page">
      <HeaderLanding />
      <div className="signup_background">
        <div className="title">{t("title1")}</div>
        <div className="subtitle">{t("content1")}</div>
      </div>

      <div className="form-submit">
        <div className="form-title">
          <div>{t("form_title")}</div>
        </div>
        <div className="signup_form-submit">
          <div className="form">
            <div className="form-item">
              <div className="Field">
                <TextField
                  id="outlined-required"
                  label={t("first_name")}
                  className="input"
                  name="firstName"
                  value={newAccount.firstName}
                  onChange={handleNewAccountChange}
                  required
                />
                <div
                  className={
                    newAccount.firstName === "" ? "error-text" : "hidden"
                  }
                >
                  * {t("first_name_is_not_empty")}
                </div>
                <div
                  className={checkFirstName === false ? "error-text" : "hidden"}
                >
                  * {v("firstName")}
                </div>
              </div>
              <div className="Field">
                <TextField
                  required
                  id="outlined-required"
                  label={t("last_name")}
                  className="input"
                  value={newAccount.lastName}
                  name="lastName"
                  onChange={handleNewAccountChange}
                />{" "}
                <div
                  className={
                    newAccount.lastName === "" ? "error-text" : "hidden"
                  }
                >
                  * {t("last_name_is_not_empty")}
                </div>
                <div
                  className={checkLastName === false ? "error-text" : "hidden"}
                >
                  * {v("lastName")}
                </div>
              </div>
            </div>
            <div className="form-item">
              <div className="Field">
                <TextField
                  required
                  id="outlined-required"
                  label={t("email_addr")}
                  className="input"
                  value={newAccount.email}
                  name="email"
                  onChange={handleNewAccountChange}
                />
                <div
                  className={newAccount.email === "" ? "error-text" : "hidden"}
                >
                  * {t("email_is_not_empty")}
                </div>
                <div className={checkEmail === false ? "error-text" : "hidden"}>
                  * {v("email")}
                </div>
              </div>
              <div className="Field">
                <TextField
                  required
                  id="outlined-required"
                  label={t("site_name")}
                  className="input"
                  value={newAccount.accountName}
                  name="accountName"
                  onChange={handleNewAccountChange}
                />
                <div
                  className={
                    newAccount.accountName === "" ? "error-text" : "hidden"
                  }
                >
                  * {t("organizatin_name_is_not_empty")}
                </div>
                <div
                  className={
                    checkAccountName === false ? "error-text" : "hidden"
                  }
                >
                  * {v("accountName")}
                </div>
              </div>
            </div>
            <div className="form-item">
              <div className="Field">
                <TextField
                  required
                  id="outlined-required"
                  label={t("URL")}
                  className="input"
                  value={newAccount.subDomain}
                  name="subDomain"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">.{DOMAIN}</InputAdornment>
                    ),
                  }}
                  onChange={handleNewAccountChange}
                />
                <div
                  className={
                    newAccount.subDomain === "" ? "error-text" : "hidden"
                  }
                >
                  * {t("domain_is_not_empty")}
                </div>
                <div
                  className={
                    checkDomainExist === false ? "error-text" : "hidden"
                  }
                >
                  * {v("domain_existed")}
                </div>
                <div
                  className={checkSubdomain === false ? "error-text" : "hidden"}
                >
                  * {v("subDomain")}
                </div>
              </div>
              <div className="Field">
                <Select
                  value={newAccount.LMS}
                  name="LMS"
                  onChange={handleNewAccountChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="input"
                >
                  <MenuItem value="">
                    <span>{t("title_dropdown")}</span>
                  </MenuItem>
                  <MenuItem value="Canvas">Canvas</MenuItem>
                  <MenuItem value="Blackboard">Blackboard</MenuItem>
                  <MenuItem value="Moodle">Moodle</MenuItem>
                  <MenuItem value="Sakai">Sakai</MenuItem>
                  <MenuItem value="Open edX">Open edX</MenuItem>
                </Select>
                <div
                  className={newAccount.LMS === "" ? "error-text" : "hidden"}
                >
                  * {t("LMS_is_not_empty")}
                </div>
              </div>
            </div>
          </div>
          <div className="submit">
            {/* <div className="warning">
              <ErrorOutlined className="warning-icon" />
              <div className="warning-text">
                {t("warning_text1")}
                <br /> {t("warning_text2")}
              </div>
            </div> */}

            <div>
              <ReCAPTCHA sitekey={siteKey} onChange={handleRecaptchaChange} />
              <div className={!recaptchaValue ? "error-text" : "hidden"}>
                {t("please_verify_recaptcha")}
              </div>
            </div>
            <div>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t("policy_text")}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_PRIVACY}
                >
                  {t("policy")}
                </a>
              </span>
            </div>
            {/* <div className="info">{t("info_text")}</div> */}

            <div className="button">
              <Button
                type="submit"
                variant="contained"
                size="large"
                className="button button-start"
                onClick={handleCreateAccount}
                disabled={!isChecked}
              >
                {t("start_free")}
              </Button>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="Box-Modal">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  style={{ textAlign: "center" }}
                >
                  {t("please_wait")}
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
      {/* <div className="signup_background signup_background-green">
        <div className="title">{t("title2")}</div>
        <div className="subtitle">{t("content2")}</div>
        <div>
          <Button variant="contained" size="large" className="signup_button">
            {t("request_a_demo")}
          </Button>
        </div>
      </div> */}
      <FooterLanding />
    </div>
  );
}
