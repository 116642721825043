import React from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import "../HomePageOrg.scss";
import "./PlansBillingPage.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlansBilling = () => {
  const [value, setValue] = React.useState(0);

  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.PlansBillingPage",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="homepageorg_layout">
      <div className="homepageorg_title">{t("Plan_and_billing")}</div>
      <div className="bottom">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("current_plan")} {...a11yProps(0)} />
              <Tab label={t("purchase_plan")} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="head-table" align="left">
                      {t("plan")}
                    </TableCell>
                    <TableCell className="head-table" align="left">
                      {t("authors")}
                    </TableCell>
                    <TableCell className="head-table" align="left">
                      {t("transfer")}
                    </TableCell>
                    <TableCell className="head-table" align="left">
                      {t("storage")}{" "}
                    </TableCell>
                    <TableCell className="head-table" align="left">
                      {t("reporting")}
                    </TableCell>
                    <TableCell className="head-table" align="left">
                      {t("price")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" className="bold">
                      {t("30_day_free")}
                    </TableCell>
                    <TableCell align="left">{t("unlimited")}</TableCell>
                    <TableCell align="left">{t("updating")}</TableCell>
                    <TableCell align="left">1 GB</TableCell>
                    <TableCell align="left">{t("unlimited")}</TableCell>
                    <TableCell align="left">{t("trial")}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {t("updating")}
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};
export default function PlansBillingPage() {
  return (
    <div>
      <HomeLayout bodyManage={<PlansBilling />} type="Manage Organization" />
    </div>
  );
}
