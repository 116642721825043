import React from "react";
import "../HomePageOrg.scss";
import "./AboutRolesPage.scss";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import {
  CheckCircleOutlineOutlined,
  DoDisturbOnOutlined,
} from "@mui/icons-material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const rows = [
  {
    id: 1,
    title: "View content",
    subtitle:
      "Access H5P content visible for the public or the organization, normally through LTI",
    leaner: true,
    author: true,
    superuser: true,
    admin: true,
  },
  {
    id: 2,
    title: "Manage own content",
    subtitle: "Can create, edit and delete own content.",
    leaner: false,
    author: true,
    superuser: true,
    admin: true,
  },
  {
    id: 3,
    title: "Collaborate on content",
    subtitle:
      "Can be added as collaborator, and can edit content owned by others if added as collaborator, but not delete it.",
    leaner: false,
    author: false,
    superuser: true,
    admin: true,
  },
  {
    id: 4,
    title: "Manage sharing for content",
    subtitle:
      "Can add users as collaborators for own content or content where they are collaborator.",
    leaner: false,
    author: false,
    superuser: true,
    admin: true,
  },
  {
    id: 5,
    title: "Change own identity",
    subtitle: "Can change own email, lastname and firstname",
    leaner: false,
    author: false,
    superuser: true,
    admin: true,
  },
  {
    id: 6,
    title: "Manage other's content",
    subtitle:
      "Can edit and delete(but not permanently) others content. Can also change who the content is visible for.",
    leaner: false,
    author: false,
    superuser: true,
    admin: true,
  },
  {
    id: 7,
    title: "Change the owner of the content",
    subtitle: "Able to change the owner of any content. ",
    leaner: false,
    author: false,
    superuser: false,
    admin: true,
  },
];

const AboutRoles = () => {
  return (
    <div className="homepageorg_layout">
      <div className="homepageorg_title">About the roles</div>
      <div className="aboutroles_table">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="TableRow">
                <TableCell></TableCell>
                <TableCell align="right" className="column">
                  Leaner
                </TableCell>
                <TableCell align="right" className="column">
                  Author
                </TableCell>
                <TableCell align="right" className="column">
                  Super User
                </TableCell>
                <TableCell align="right" className="column">
                  Administrator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" className="TableCell1">
                    <div className="title"> {row.title}</div>
                    <div className="subtitle"> {row.subtitle}</div>
                  </TableCell>
                  <TableCell align="right" className="TableCell2">
                    {row.leaner === true ? (
                      <CheckCircleOutlineOutlined className="true" />
                    ) : (
                      <DoDisturbOnOutlined className="false" />
                    )}
                  </TableCell>
                  <TableCell align="right" className="TableCell2">
                    {" "}
                    {row.author === true ? (
                      <CheckCircleOutlineOutlined className="true" />
                    ) : (
                      <DoDisturbOnOutlined className="false" />
                    )}
                  </TableCell>
                  <TableCell align="right" className="TableCell2">
                    {" "}
                    {row.superuser === true ? (
                      <CheckCircleOutlineOutlined className="true" />
                    ) : (
                      <DoDisturbOnOutlined className="false" />
                    )}
                  </TableCell>
                  <TableCell align="right" className="TableCell2">
                    {" "}
                    {row.admin === true ? (
                      <CheckCircleOutlineOutlined className="true" />
                    ) : (
                      <DoDisturbOnOutlined className="false" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default function AboutRolesPage() {
  return (
    <div>
      <HomeLayout bodyManage={<AboutRoles />} type="Manage Organization" />
    </div>
  );
}
