import { MenuItem, Select } from "@mui/material";
import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "../../constants";
import { common } from "../../utils/common";
import parseJwt from "../../funtions/parseJwt";
import "./Toolbar.scss";

export default function Toolbar({ title }) {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "layouts.Toolbar" });
  const [selected, setSelected] = useState("");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };



  return (
    <div className="toolbar_extend">
      <div className="toolbar_reponsive-title">{title}</div>
      {title === t("manage_content") ? (
        <Select
          value={selected}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
          }}
        >
          <MenuItem
            value={t("my_content")}
            onClick={() => navigate(routeConstants.MY_CONTENT)}
          >
            {t("my_content")}
          </MenuItem>
          <MenuItem
            value={t("share_with_me")}
            onClick={() => navigate(routeConstants.SHARE_ME)}
          >
            {t("share_with_me")}
          </MenuItem>
          <MenuItem
            value={t("all_content")}
            onClick={() => navigate(routeConstants.ALL_CONTENT)}
          >
            {t("all_content")}
          </MenuItem>
          
          <MenuItem
            value={t("trash")}
            onClick={() => navigate(routeConstants.TRASH)}
          >
            {t("trash")}
          </MenuItem>

        </Select>
      ) : (
        <Select
          value={selected}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
          }}
        >
          <MenuItem
            value={t("users")}
            onClick={() => navigate(routeConstants.USERS)}
          >
            {t("users")}
          </MenuItem>
          <MenuItem
            value={t("archived_users")}
            onClick={() => navigate(routeConstants.ARCHIVED_USER)}
          >
            {t("archived_users")}
          </MenuItem>

          <MenuItem
            value={t("organization_details")}
            onClick={() => navigate(routeConstants.ORGANIZATION_DETAILS)}
          >
            {t("organization_details")}
          </MenuItem>

          <MenuItem
            value={t("about_the_roles")}
            onClick={() => navigate(routeConstants.ABOUT_ROLES)}
          >
            {t("about_the_roles")}
          </MenuItem>

          <MenuItem
            value={t("connect_LMS")}
            onClick={() => navigate(routeConstants.CONNECT_LMS)}
          >
            {t("connect_LMS")}
          </MenuItem>

          <MenuItem
            value={t("settings")}
            onClick={() => navigate(routeConstants.SETTINGS)}
          >
            {t("settings")}
          </MenuItem>

          <MenuItem
            value={t("plans_and_billing")}
            onClick={() => navigate(routeConstants.PLANS_BILLING)}
          >
            {t("plans_and_billing")}
          </MenuItem>
          <MenuItem
            value={t("domain")}
            onClick={() => navigate(routeConstants.DOMAIN)}
          >
            {t("domain")}
          </MenuItem>
        </Select>
      )}
    </div>
  );
}
