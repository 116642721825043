import { H5PEditorUI } from "@lumieducation/h5p-react";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { editContent, getContentEdit } from "../../../api";
import HeaderPage from "../../../layouts/Header/HeaderPage/HeaderPage";
import NavContent from "../../../layouts/NavContent/NavContent";
import { common } from "../../../utils/common";

export default function EditContent() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.CRUDContent.EditContent",
  });
  const navigate = useNavigate();
  const h5pEditor = useRef();

  const [content_id, setContentId] = useState("");
  const [request_body, setRequestBody] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get("id");
    setContentId(value);
  }, []);

  // useEffect(() => {
  //   const requestBody = h5pEditor.current?.getContent();
  //   setRequestBody(requestBody);
  // }, [h5pEditor.current?.getContent()]);

  const getEdit = async () => {
    const res = await getContentEdit(content_id);
    return res.data;
  };

  const handleSave = async () => {
    await h5pEditor.current?.save();
  };

  const edit = async (contentId, requestBody) => {
    setRequestBody(requestBody);
    const res = await editContent(requestBody, content_id);
    if (res.status === 200) {
      navigate(`/content/view?id=${content_id}`);
    }
  };

  const handleCancel = () => {
    navigate(`/content/view?id=${content_id}`);
  };

  return (
    <div className="manage-content">
      {common.getLtiLMS() ? <></> : <HeaderPage />}
      <NavContent
        view="1"
        edit={true}
        folder="1"
        // preview="1"
        // back="1"
        option="1"
        contentId={content_id}
        requestBody={request_body}
      />
      <div className="content-area">
        <H5PEditorUI
          ref={h5pEditor}
          contentId={content_id}
          loadContentCallback={getEdit}
          saveContentCallback={edit}
        />
        <div className="two-buttons">
          <Button variant="contained" onClick={handleSave} className="button">
            {t("save")}
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            className="button cancel-button"
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
