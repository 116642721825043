import React, { useState, useRef, useEffect } from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { AccountCircle, FileUpload } from "@mui/icons-material";
import "../HomePageOrg.scss";
import "../MyAccountPage/MyAccountPage.scss";
import { Button, Stack } from "@mui/material";
import Input from "../../../components/Input/Input";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { getRoles, inviteUser } from "../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import handleErrorFunction from "../../../funtions/handleErrorFunction";

const AddUser = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.AddUserPage",
  });

  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  const [avatarImage, setAvatarImage] = useState(null);
  const inputRef = useRef(null);

  const [roles, setRoles] = useState([]);

  const [userAdd, setUserAdd] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const [checkFirstName, setCheckFirstName] = useState("");
  const [checkLastName, setCheckLastName] = useState("");
  const [checkEmail, setCheckEmail] = useState("");
  const [checkEmailExist, setCheckEmailExist] = useState("");

  const handleGetRoles = async () => {
    try {
      const res = await getRoles();
      setRoles(res.data.roles);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetRoles();
  }, []);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setAvatarImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUploadButtonClick = () => {
    inputRef.current.click(); // Kích hoạt sự kiện click trên input file ẩn
  };

  const handleAddUserChange = (event) => {
    const { name, value } = event.target;
    setUserAdd({
      ...userAdd,
      [name]: value,
    });

    switch (name) {
      case "firstName":
        setCheckFirstName("");
        break;
      case "lastName":
        setCheckLastName("");
        break;
      case "email":
        setCheckEmail("");
        setCheckEmailExist("");
        break;
      default:
        break;
    }
  };

  const handleAddUser = async () => {
    try {
      if (userAdd.role === "") {
        userAdd.role = roles[0].name;
      }
      const respond = await inviteUser(userAdd);
      if (respond.status === 200) {
        navigate(`/verify-email-adduser?email=${userAdd.email}`);
      }
    } catch (error) {
      const errorReturn = handleErrorFunction(error, v);
      toast.error(errorReturn);
      if (errorReturn === v("firstName")) {
        setCheckFirstName(false);
      } else {
        setCheckFirstName(true);
      }
      if (errorReturn === v("lastName")) {
        setCheckLastName(false);
      } else {
        setCheckLastName(true);
      }
      if (errorReturn === v("email")) {
        setCheckEmail(false);
      } else {
        setCheckEmail(true);
      }

      if (errorReturn === v("email_existed")) {
        setCheckEmailExist(false);
      } else {
        setCheckEmailExist(true);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddUser();
    }
  };

  return (
    <div className="homepageorg_layout">
      <div className="homepageorg_title">{t("add_user")}</div>
      <div className="myaccount_bottom">
        <div className="avatar-upload">
          <div className="avatar">
            {avatarImage ? (
              <img
                src={avatarImage}
                alt="Avatar"
                style={{ width: 50, height: 50 }}
              />
            ) : (
              <AccountCircle style={{ fontSize: 50 }} />
            )}
          </div>
          <div className="text-upload">
            <div className="text">{t("profile_picture")}</div>
            <div className="upload">
              <Button
                variant="contained"
                startIcon={<FileUpload />}
                className="button"
                onClick={handleUploadButtonClick}
              >
                {t("upload")}
              </Button>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUpload}
                ref={inputRef}
              />
            </div>
          </div>
        </div>

        <Stack className="input input-text-error">
          <Input
            label={t("first_name")}
            name="firstName"
            value={userAdd.firstName}
            onChange={handleAddUserChange}
            className={checkFirstName === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />

          <div className={checkFirstName === false ? "error-text" : "hidden"}>
            {v("firstName")}
          </div>
        </Stack>

        <Stack className="input input-text-error">
          <Input
            label={t("last_name")}
            name="lastName"
            value={userAdd.lastName}
            onChange={handleAddUserChange}
            className={checkLastName === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />
          <div className={checkLastName === false ? "error-text" : "hidden"}>
            {v("lastName")}
          </div>
        </Stack>

        <Stack className="input input-text-error">
          <Input
            label={t("email_address")}
            name="email"
            value={userAdd.email}
            onChange={handleAddUserChange}
            className={
              checkEmail === false || checkEmailExist === false
                ? "border-red"
                : ""
            }
            onKeyDown={handleKeyDown}
          />
          <div className={checkEmail === false ? "error-text" : "hidden"}>
            {v("email")}
          </div>
          <div className={checkEmailExist === false ? "error-text" : "hidden"}>
            {v("email_existed")}
          </div>
        </Stack>

        <Dropdown
          listItem={roles}
          label="Role"
          name="role"
          value={userAdd.role}
          onChange={handleAddUserChange}
        />

        <div className="two-button">
          <Button
            variant="contained"
            className="button"
            onClick={handleAddUser}
          >
            {t("add_user")}
          </Button>

          <Button variant="contained" className="button cancel-button">
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default function AddUserPage() {
  return (
    <div>
      <HomeLayout bodyManage={<AddUser />} type="Manage Organization" />
    </div>
  );
}
