import { H5PEditorUI } from "@lumieducation/h5p-react";
import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createContent, getH5P } from "../../../api";
import "../../../assets/styles/global.scss";
import HeaderPage from "../../../layouts/Header/HeaderPage/HeaderPage";
import NavContent from "../../../layouts/NavContent/NavContent";
import { common } from "../../../utils/common";
import "./Content.scss";

export default function AddContent() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.CRUDContent.AddContent",
  });
  const navigate = useNavigate();
  const h5pEditor = useRef();
  const queryParameters = new URLSearchParams(window.location.search);

  console.log(queryParameters.get("parent"));

  const getEdit = async () => {
    const res = await getH5P();
    return res.data;
  };

  const [requestBody, setRequestBody] = useState("");

  const [disabledClick, setDisableClick] = useState(false);

  const createH5pContent = async () => {
    await h5pEditor.current?.save();
  };

  const create = async (contentId, requestBody) => {
    setRequestBody(requestBody);
    setDisableClick(true);
    const res = await createContent(requestBody,queryParameters.get("parent"));

    const content_id = res.data.contentId;
    if (res.status === 200) {
      setDisableClick(false);
      navigate(`/content/view?id=${content_id}`);
    }
  };

  const handleCancel = () => {
    navigate("/content/my-content");
  };

  return (
    <div className="manage-content">
      {common.getLtiLMS() ? <></> : <HeaderPage />}
      <NavContent
        folder="1"
        createNewContent="1"
        // create="1"
        // cancel="1"
        requestBody={requestBody}
      />
      <div className="content-area">
        <H5PEditorUI
          ref={h5pEditor}
          contentId={"new"}
          loadContentCallback={getEdit}
          saveContentCallback={create}
        />
        <div className="two-buttons">
          <Button
            variant="contained"
            onClick={createH5pContent}
            className="button"
            disabled={disabledClick}
          >
            {t("create")}
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            className="button cancel-button"
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
