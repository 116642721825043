import React, { useState } from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import "../HomePageOrg.scss";
import "./SettingsPage.scss";
import ContentTypeSettings from "../../../layouts/According/ContentTypeSetting";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Settings = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [archivedLeaner, setArchivedLeaner] = useState("");
  const [archivedAuthor, setArchivedAuthor] = useState("");
  const handleChangeArchivedAuthor = (event) => {
    setArchivedAuthor(event.target.value);
  };
  const handleChangeArchivedLeaner = (event) => {
    setArchivedLeaner(event.target.value);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="homepageorg_layout">
      <div className="settings_container">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">
              Content types settings
            </Typography>

            <Typography className="item-subtitle">
              Restrict specific content types
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContentTypeSettings />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">User settings</Typography>
            <Typography className="item-subtitle">
              Automatic archiving of inactive users
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="archived">
              <div className="archived-title">Archive Authors</div>
              <div className="archived-dropdown">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Language"
                    value={archivedAuthor}
                    onChange={handleChangeArchivedAuthor}
                    name="language"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },

                      border: "2px solid #e2e5ee",
                      height: 40,
                    }}
                  >
                    <MenuItem
                      value={10}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 90 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={20}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 180 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={30}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 365 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={40}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      Don't archive automatically
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="archived">
              <div className="archived-title">Archive Leaner</div>
              <div className="archived-dropdown">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Language"
                    value={archivedLeaner}
                    onChange={handleChangeArchivedLeaner}
                    name="language"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },

                      border: "2px solid #e2e5ee",
                      height: 40,
                    }}
                  >
                    <MenuItem
                      value={10}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 30 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={20}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 60 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={20}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 90 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={20}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 180 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={30}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      After 365 days of inactivity
                    </MenuItem>
                    <MenuItem
                      value={40}
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: 14,
                      }}
                    >
                      Don't archive automatically
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">LTI settings</Typography>
            <Typography className="item-subtitle">
              LTI behavioural settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">
              Content behaviour settings
            </Typography>
            <Typography className="item-subtitle">
              Control aspects of how your content behaves
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">Security settings</Typography>
            <Typography className="item-subtitle">
              Control site and content security
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className="accordion"
        >
          <AccordionSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
            className="accordionsumary"
          >
            <Typography className="item-title">Permissions</Typography>
            <Typography className="item-subtitle">
              Control permissions for different roles
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="button_div">
        <Button variant="contained" size="large" className="button">
          Save settings
        </Button>
      </div>
    </div>
  );
};

export default function SettingsPage() {
  return (
    <div>
      <HomeLayout bodyManage={<Settings />} type="Manage Organization" />
    </div>
  );
}
