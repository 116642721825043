import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../ConnectLMSPage.scss";
import ConnectionSettingsForm from "../components/ConnectionSettingsForm";

const style = {
  // position: "absolute",
  position:"relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // boxShadow: "0 0 22px 0 rgba(0,0,0,.15)",
  boxShadow: 24,
  p: 4,
  overflowY:'auto',
  overflow:'hidden',

  "@media screen and (max-width:767.98px)":{
    width:'auto',
  } 
};
const ConnectionSettingsModal = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(!isOpen);

  return (
    <>
      <Button
      variant="outlined"
      size="small"
      sx={{textTransform:"none"}}
      onClick={handleOpenModal}
      >
        {t("connectionItem.connection_settings")}
      </Button>
      
      <Modal
        open={isOpen}
        onClose={handleOpenModal}
        className="connection-settings-modal"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {t("connectionItem.connection_settings")}
            <CloseSharpIcon onClick={handleOpenModal} />
          </Typography>

          
          <ConnectionSettingsForm isModal onCloseModal={handleOpenModal} />

        </Box>
      </Modal>
    </>
  );
});

export default ConnectionSettingsModal;
