import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./HeaderLanding.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CohotaLogo from "../../../components/CohotaLogo/CohotaLogo";
const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Header.HeaderLanding",
  });

  const navItems = [
    { id: 1, name: t("pricing"), link: "#" },
    { id: 2, name: t("integrations"), link: "#" },
    // { id: 3, name: t("request"), link: "request" },
    { id: 4, name: t("signup"), link: "signup" },
    { id: 5, name: t("login"), link: "login" },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Link to="/landing" className="link">
        <Typography variant="h6" sx={{ my: 2 }} style={{ marginLeft: 10 }}>
          <CohotaLogo color="#000" />
        </Typography>
      </Link>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item["id"]} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link to={`/${item["link"]}`} className="link">
                <ListItemText primary={item["name"]} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        className="AppBar"
        sx={{ backgroundColor: "#1e213b", height: 80, lineHeight: 80 }}
      >
        <Toolbar style={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
            }}
          >
            <Link to="/landing" className="link">
              <CohotaLogo color="#fff" />
            </Link>
          </Typography>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              height: "80px",
              alignItems: "center",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
            {navItems.map((item) => (
              <Link to={`/${item["link"]}`} key={item["id"]}>
                <Button
                  sx={{
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 600,
                    height: "10px",
                  }}
                  className="headerlandingpage_button-hover"
                >
                  {item["name"]}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
