import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleChangeFolderName } from "../function/fileManager";


export default function EditFileDialog({
  openEditingDialog,
  handleCloseEditingDialog,
  editFileName,
  editFileId
}) {
  const navigate = useNavigate();

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });

  const [fileName, setFileName] = useState(editFileName);


  return (
    <>
      <Dialog
        open={openEditingDialog}
        onClose={() => {
          setFileName('');
          handleCloseEditingDialog();
        }}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            await handleChangeFolderName({ directory_id: editFileId, newName: fileName, handleCloseEditingDialog, navigate })
          },
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{t("edit")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("title")}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="normal"
            type="text"
            fullWidth
            variant="outlined"
            // value={fileName}
            defaultValue={editFileName}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            pb: '2em',
            mr: '1em'

          }}
        >
          <Button onClick={() => {
            setFileName('')
            handleCloseEditingDialog();
          }}>{t("cancel")}</Button>
          <Button
            type="submit"
            style={{
              backgroundColor: '#186df7',
              color: '#ffffff'
            }}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}