import React from "react";
import CohotaLogo from "../../assets/img/Logo/logoCohota.png";
import "./CohotaLogo.scss";

export default function Cohota({ color, scale }) {
  return (
    <div className="cohota_component" style={{ transform: `scale(${scale})` }}>
      <div className="logo-div">
        <img src={CohotaLogo} alt="cohota-logo.png" className="logo-icon" />
      </div>
      <div style={{ color }} className="cohota-text">
        Cohota
      </div>
    </div>
  );
}
