import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../../layouts/Header/HeaderLanding/HeaderLanding";
import Slider from "../../layouts/Slider/Slider";
import ContentLanding from "../../layouts/Content/ContentLanding/ContentLanding";
import Footer from "../../layouts/Footer/FooterLanding";
import vd1 from "../../assets/videos/3.mp4";
import vd2 from "../../assets/videos/4.mp4";
import vd3 from "../../assets/videos/5.mp4";
import vd4 from "../../assets/videos/6.mp4";
import "./LandingPage.scss";
import { useTranslation } from "react-i18next";

const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
export default function LandingPage() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.LandingPage",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.hostname != ROOT_DOMAIN) {
      navigate("/login");
    }
  });
  const listContentInteractive = [
    {
      id: 1,
      video: "vd1",
      title: t("ListContentInteractive.Item1.title"),
      content: t("ListContentInteractive.Item1.content"),
      buttonTitle: t("ListContentInteractive.Item1.button_title"),
    },
    {
      id: 2,
      video: "vd2",
      title: t("ListContentInteractive.Item2.title"),
      content: t("ListContentInteractive.Item2.content"),
      buttonTitle: t("ListContentInteractive.Item2.button_title"),
    },
    {
      id: 3,
      video: "vd3",
      title: t("ListContentInteractive.Item3.title"),
      content: t("ListContentInteractive.Item3.content"),
      buttonTitle: t("ListContentInteractive.Item3.button_title"),
    },
  ];
  const listContent = [
    {
      id: 1,
      title: t("ListContent.Item1.title"),
      content: t("ListContent.Item1.content"),
      video: vd1,
      option: "reserve", //
    },
    {
      id: 2,
      title: t("ListContent.Item2.title"),
      content: t("ListContent.Item2.content"),
      video: vd2,
      option: "reserve",
    },
    {
      id: 3,
      title: t("ListContent.Item3.title"),
      content: t("ListContent.Item3.content"),
      video: vd3,
      option: "reserve", //
    },
    {
      id: 4,
      title: t("ListContent.Item4.title"),
      content: t("ListContent.Item4.content"),
      video: vd1,
      option: "reserve",
    },
    {
      id: 5,
      title: t("ListContent.Item5.title"),
      content: t("ListContent.Item5.content"),
      video: vd4,
      option: "reserve", //
    },
    {
      id: 6,
      title: t("ListContent.Item6.title"),
      content: t("ListContent.Item6.content"),
      video: vd3,
      option: "reserve",
    },
  ];
  return (
    <div className="landingpage_container">
      <HeaderLanding />

      <Slider />
      <div className="landingpage_heading">
        <div className="landingpage_heading-title">
          {t("title_content_interacrive")}
        </div>
        <div className="landingpage_heading-description">
          <div>{t("subtitle_content_interactive1")} </div>{" "}
        </div>
      </div>
      {/* <ContentInteractive listContentInteractive={listContentInteractive} />
      <div className="landingpage_heading">
        <div className="landingpage_heading-title">{t("title_content")}</div>
        <div className="landingpage_heading-description">
          {t("subtitle_content")}
        </div>
      </div> */}
      <ContentLanding listContent={listContent} />
      {/* <div className="landingpage_LMSIntegerations">
        <div className="LMS_title">{t("LMS_Integrations")}</div>
        <div className="LMS_subtitle">
          {t(
            "below_are_some_of_the_LMSs_where_H5Ps_LTI_integration_has_been_tested"
          )}
        </div>
        <div className="list_logo">
          <img
            src="https://h5p.com/frontpage/lms-integrations/moodle.png?id=1b818e1128de1dba25615fe1366ebde2"
            alt=""
            className="logo-img"
          />
          <img
            src="https://h5p.com/frontpage/lms-integrations/brightspace.png?id=0fb3d782719a505ab3db704673e58566"
            alt=""
            className="logo-img"
          />
          <img
            src="https://h5p.com/frontpage/lms-integrations/canvas.png?id=2bb7e1a10085b9215f22aac85c1381eb"
            alt=""
            className="logo-img"
          />
          <img
            src="https://h5p.com/frontpage/lms-integrations/blackboard.png?id=5f75742d6b31b24253895a05df171d95"
            alt=""
            className="logo-img"
          />
          <img
            src="https://h5p.com/frontpage/lms-integrations/sakai.png?id=26dd15352b40cc4b660b99e36aa5919f"
            alt=""
            className="logo-img"
          />
          <img
            src="https://h5p.com/frontpage/lms-integrations/open-edx-logo-white.png?id=e3bb988cae99aa87786ae79922c2d1f4"
            alt=""
            className="logo-img"
          />
        </div>
        <div className="landingpage_button">
          <Button variant="contained" size="large" className="button">
            {t("see_all_integrations")}
          </Button>
        </div>
      </div> */}
      {/* <div className="landingpage_subfooter">
        <div className="landingpage_image">
          <img src={Student_img} alt="" />
        </div>
        <div className="landingpage_right">
          <div className="right-top">
            <div className="box">
              <AccountBalance className="box-icon" />
              <div className="box-number">433</div>
              <div className="box-text">{t("box_text1")}</div>
            </div>
            <div className="in">{t("in")}</div>
            <div className="box">
              <Public className="box-icon" />
              <div className="box-number">42</div>
              <div className="box-text">{t("box_text2")}</div>
            </div>
          </div>
          <div className="right-bottom">{t("text_bottom")}</div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
