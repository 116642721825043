import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { memo } from "react";

const AlertDialog = memo(
  ({
    open,
    toggle,
    handleSubmit,
    title,
    desc,
    okText = "OK",
    cancelText = "Cancel",
  }) => {
    return (
      <Dialog
        open={open}
        onClose={toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>{cancelText}</Button>
          <Button onClick={handleSubmit} autoFocus>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AlertDialog;
