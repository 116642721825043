import { H5PPlayerUI } from "@lumieducation/h5p-react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { viewContent } from "../../../api";
import HeaderPage from "../../../layouts/Header/HeaderPage/HeaderPage";
import NavContent from "../../../layouts/NavContent/NavContent";
import { common } from "../../../utils/common";
import parseJwt from "../../../funtions/parseJwt";
import { useAppContext } from "../../../context/AppContext";

export default function ViewContent() {
  const h5pPlayer = useRef();
  const { id } = useParams();
  const [content_id, setContentId] = useState("");
  const { userInfo, setUserInfo } = useAppContext();

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    setUserInfo(json);
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get("id");
    if (value) setContentId(value);
    else {
      setContentId(id);
    }
  }, []);

  const getEdit = async () => {
    if (content_id) {
      const res = await viewContent(content_id);
      try {
        window.parent.postMessage(JSON.stringify(res.data), "*");
        // window.parent.parent.postMessage(JSON.stringify(res.data),"*");
      } catch (error) {
        console.log(error)
      }
      
      return res.data;
    }
  };

  return (
    <div className={id ? "lti" : "manage-content"}>
      {common.getLtiLMS() ? <></> : <HeaderPage />}
      {content_id && (
        <>
          {userInfo.roleName !== "Learner" && (
            <NavContent
              view={true}
              edit="1"
              folder={id ? false : "1"}
              // option="1"
              contentId={content_id}
            />
          )}

          <div className={`content-area${id && userInfo.roleName !== "Learner"? "-author" : id? "-leaner" : ""}`}>

            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={content_id}
              loadContentCallback={getEdit}
            />
          </div>
        </>
      )}
    </div>
  );
}
