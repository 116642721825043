import React, { memo } from "react";
import CopyToClipboardButton from "../../../../components/Button/CopyToClipboardButton";
import "../ConnectLMSPage.scss";

const RegistrationURLField = memo(({ value }) => {
  return (
    <>
      <td>
            <CopyToClipboardButton value={value} />
      </td>
    </>
  );
});

export default RegistrationURLField;
