import React from "react";
import { Typography, Button } from "@mui/material";
import { PlayArrowRounded } from "@mui/icons-material";
import "./Slider.scss";
import Slider_img from "../../assets/img/LandingPage/slider-human.jpg";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Slider() {
  const { t } = useTranslation("translation", { keyPrefix: "layouts.Slider" });
  return (
    <div className="slider_container">
      <div className="slider_item slider_item1">
        <div className="title">{t("title")}</div>
        <Typography variant="h6" gutterBottom className="subtitle">
          {t("hosted_near_")}
        </Typography>
        <div className="buttons">
          <Link to="/signup">
            {" "}
            <Button variant="contained" size="large" className="button button1">
              {t("get_started")}
            </Button>
          </Link>
         <Link to='https://docs.hoclieutuongtac.com'>
         <Button
            variant="contained"
            startIcon={<PlayArrowRounded />}
            size="large"
            className=" button button2"
          >
            {t("instructions")}
          </Button>
         </Link>
        </div>

        <Typography variant="subtitle1" gutterBottom className="question">
          {t("need_a_")}
        </Typography>
      </div>
      <div className="slider_item slider_item2">
        <img src={Slider_img} alt="#" className="image" />
      </div>
    </div>
  );
}
