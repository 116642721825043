import {
  Archive,
  Edit,
  FileCopy,
  FolderOpenOutlined,
  MoreHoriz,
  RemoveRedEye,
  Share,
} from "@mui/icons-material";
import {
  Box,
  Button,
  // Stack,
  Divider,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createContent, editContent } from "../../api";
import ComingSoon from "../../assets/img/ComingSoon/folder.png";
import Search from "../../components/Search/Search";
import parseJwt from "../../funtions/parseJwt";
import { common } from "../../utils/common";
import "./NavContent.scss";

export default function NavContent({
  folder,
  createNewContent,
  view,
  edit,
  report,
  save,
  create,
  cancel,
  option,
  active,
  contentId,
  requestBody,
  preview,
  back,
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.NavContent",
  });

  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    setRoleId(json.roleId);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModalComingSoon, setOpenModalComingSoon] = useState(false);
  const handleOpenModalComingSoon = () => setOpenModalComingSoon(true);
  const handleCloseModalComingSoon = () => setOpenModalComingSoon(false);

  const navigate = useNavigate();
  const hanldeEditVideo = () => {
    navigate(`/content/edit?id=${contentId}`);
  };
  const handleViewVideo = () => {
    navigate(`/content/view?id=${contentId}`);
  };
  const handleCreateContent = async () => {
    const res = await createContent(requestBody);
    const content_id = res.data.contentId;
    if (res.status === 200) {
      navigate(`/content/view?id=${content_id}`);
    }
  };

  const handleCancel = () => {
    navigate("/content/my-content");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditContent = async () => {
    const res = await editContent(requestBody, contentId);
    if (res.status === 200) {
      navigate(`/content/view?id=${contentId}`);
    }
  };

  const [openModalShare, setOpenModalShare] = useState(false);
  const handleOpenModalShare = () => setOpenModalShare(true);
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    // setEmailCollab([]);
  };

  const handleOpenModal = () => {
    handleOpenModalShare();
  };

  // const handleChangeEmailCollab = async (event) => {
  //   setEmailCollab(event.target.value);
  //   if (event.target.value.length > 2) {
  //     const respond = await searchUser({
  //       query_param: event.target.value,
  //     });
  //     const formattedData = respond.data.message.map((item) => ({
  //       id: item.id,
  //       fullName: item.fullName,
  //       email: item.channels[0].path,
  //     }));
  //     setUserSuggestion(formattedData);
  //   }
  //   setCheckEmailShare("");
  //   setCheckEmailValid("");
  //   setCheckEmailOwner("");
  //   event.stopPropagation();
  // };

  return (
    <div className={`navbar-content ${common.getLtiLMS() ? "has-lti" : ""}`}>
      <div className="left">
        {folder && (
          <Button
            variant="contained"
            className="button folder-button"
            onClick={handleCancel}
          >
            <FolderOpenOutlined className="folder-icon" />
          </Button>
        )}
        {createNewContent && (
          <div className="active">
            <Edit />
            <div>{t("create_new_content")}</div>
          </div>
        )}
        {view && (
          <div
            onClick={handleViewVideo}
            className={view === true ? "active" : "no-active"}
          >
            <RemoveRedEye />
            <div>{t("view")}</div>
          </div>
        )}
        {roleId === 7 ? (
          <></>
        ) : (
          edit && (
            <div
              onClick={hanldeEditVideo}
              className={edit === true ? "active" : "no-active"}
            >
              <Edit />
              <div>{t("edit")}</div>
            </div>
          )
        )}
        {report && <div>{t("report")}</div>}
      </div>
      <div className="right">
        {save && (
          <Button variant="contained" className="button">
            {t("save")}
          </Button>
        )}
        {preview && (
          <Button
            variant="contained"
            className="button"
            onClick={handleEditContent}
          >
            {t("save")}
          </Button>
        )}
        {create && (
          <Button
            variant="contained"
            className="button"
            onClick={handleCreateContent}
          >
            {t("create")}
          </Button>
        )}
        {cancel && (
          <Button
            variant="contained"
            className="button cancel-button"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
        )}
        {back && (
          <Button
            variant="contained"
            className="button cancel-button"
            onClick={handleBack}
          >
            {t("cancel")}
          </Button>
        )}
        {option && (
          <>
            <div className="share-button">
              <Button
                variant="contained"
                className="button cancel-button "
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                disableElevation
              >
                <MoreHoriz />
              </Button>

              <div className="menu-secondary">
                <div onClick={handleOpenModal}>
                  <Share />
                  {t("share")}
                </div>
                <Modal
                  open={openModalShare}
                  onClose={handleCloseModalShare}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    className="Box-Modal"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    {/* <Stack className="input input-text-error">

                      <div
                        className={
                          checkEmailShare === false ? "error-text" : "hidden"
                        }
                      >
                        {v("collaborate_existed")}
                      </div>
                      <div
                        className={
                          checkEmailValid === false ? "error-text" : "hidden"
                        }
                      >
                        {v("email")}
                      </div>
                      <div
                        className={
                          checkEmailOwner === false ? "error-text" : "hidden"
                        }
                      >
                        {v("owner_existed")}
                      </div>
                    </Stack> */}
                    <Search
                      contentId={contentId}
                      handleCloseModalShare={handleCloseModalShare}
                    />
                  </Box>
                </Modal>
                <div onClick={handleOpenModalComingSoon}>
                  <FileCopy />
                  {t("duplicate")}
                </div>
                <Modal
                  open={openModalComingSoon}
                  onClose={handleCloseModalComingSoon}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    className="Box-Modal"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    <img src={ComingSoon} alt="" width={200} />
                  </Box>
                </Modal>
                <Divider sx={{ my: 0.5 }} />
                <div onClick={handleOpenModalComingSoon}>
                  <Archive />
                  Archive
                </div>
                <div onClick={handleOpenModalComingSoon}>
                  <MoreHoriz />
                  More
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
