import HomePageContent from "../pages/HomePageContent/HomePageContent/HomePageContent";
import AllContentPage from "../pages/HomePageContent/AllContentPage/AllContentPage";
import ShareMePage from "../pages/HomePageContent/ShareMePage/ShareMePage";
import TrashPage from "../pages/HomePageContent/TrashPage/TrashPage";
import AddContent from "../pages/HomePageContent/CRUDContent/AddContent";
import EditContent from "../pages/HomePageContent/CRUDContent/EditContent";
import ViewContent from "../pages/HomePageContent/CRUDContent/ViewContent";

import HomePageOrg from "../pages/HomePageOrg/HomePageOrg/HomePageOrg";
import AboutRolesPage from "../pages/HomePageOrg/AboutRolesPage/AboutRolesPage";
import ArchivedUserPage from "../pages/HomePageOrg/ArchivedUserPage/ArchivedUserPage";
import ConnectLMSPage from "../pages/HomePageOrg/ConnectLMSPage";
import OrganizationDetailPage from "../pages/HomePageOrg/OrganizationDetailPage/OrganizationDetailPage";
import PlansBillingPage from "../pages/HomePageOrg/PlansBillingPage/PlansBillingPage";
import SettingsPage from "../pages/HomePageOrg/SettingsPage/SettingsPage";
import MyAccountPage from "../pages/HomePageOrg/MyAccountPage/MyAccountPage";
import AddUserPage from "../pages/HomePageOrg/AddUserPage/AddUserPage";
import DomainPage from "../pages/HomePageOrg/DomainPage/DomainPage";

import LandingPage from "../pages/LandingPage/LandingPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import SignUpPage from "../pages/SignupPage/SignupPage";
import EnterPasswordPage from "../pages/PasswordPage/EnterPasswordPage";
import ForgotPassword from "../pages/PasswordPage/ForgotPassword";
import ResetPassword from "../pages/PasswordPage/ResetPassword";
import VerifyEmailEnable from "../pages/VerifyEmailPage/VerifyEmailEnable";
import VerifyEmailForgot from "../pages/VerifyEmailPage/VerifyEmailForgot";
import AnnouncementsPage from "../pages/AnnouncementsPage/AnnouncementsPage";
import ChangePasswordPage from "../pages/PasswordPage/ChangePasswordPage";
import VerifyEmailAddUser from "../pages/VerifyEmailPage/VerifyEmailAddUser";

const routes = [
  {
    path: "/lti/content/:id",
    component: ViewContent,
  },
  {
    path: "/lti/my-content",
    component: HomePageContent,
  },
  {
    path: "/content/my-content/",
    component: HomePageContent,
  },
  {
    path: "/content/my-content/:directory_id",
    component: HomePageContent,
  },
  {
    path: "/content/all-content",
    component: AllContentPage,
  },
  {
    path: "/content/share-me",
    component: ShareMePage,
  },
  {
    path: "/content/trash",
    component: TrashPage,
  },
  {
    path: "/content/new",
    component: AddContent,
    roleId: 7,
  },
  {
    path: "/content/edit",
    component: EditContent,
    roleId: 7,
  },
  {
    path: "/content/view",
    component: ViewContent,
  },
  {
    path: "/organization/users",
    component: HomePageOrg,
  },
  {
    path: "/organization/about-roles",
    component: AboutRolesPage,
  },
  {
    path: "/organization/archived-user",
    component: ArchivedUserPage,
  },
  {
    path: "/organization/connect-LMS",
    component: ConnectLMSPage,
  },
  {
    path: "/organization/organization-details",
    component: OrganizationDetailPage,
  },
  {
    path: "/organization/plans-billing",
    component: PlansBillingPage,
  },
  {
    path: "/organization/settings",
    component: SettingsPage,
  },
  {
    path: "/organization/domain",
    component: DomainPage,
  },
  {
    path: "/account",
    component: MyAccountPage,
  },
  {
    path: "/user/create",
    component: AddUserPage,
    roleId: 7,
  },
  {
    path: "/landing",
    component: LandingPage,
  },
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/signup",
    component: SignUpPage,
  },
  {
    path: "/enter-password",
    component: EnterPasswordPage,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/change-password",
    component: ChangePasswordPage,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/verify-email-enable",
    component: VerifyEmailEnable,
  },
  {
    path: "/verify-email-forgot",
    component: VerifyEmailForgot,
  },
  {
    path: "/verify-email-adduser",
    component: VerifyEmailAddUser,
  },
  {
    path: "/announcements",
    component: AnnouncementsPage,
  },
];

export default routes;
