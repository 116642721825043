import axios from "axios";
import parseJwt from "../funtions/parseJwt";
import { common } from "../utils/common";

const BASE_URL = process.env.REACT_APP_BE_URL;

//API ĐĂNG KÝ TÀI KHOẢN
// Đăng ký tài khoản
export const createAccount = async (account) => {
  return await axios({
    method: "post",
    data: account,
    url: `${BASE_URL}/accounts/create`,
  });
};

// Tạo mật khẩu
export const createPassword = async (password, pseudonym) => {
  return await axios({
    method: "put",
    data: password,
    url: `${BASE_URL}/auth/password/set?confirmation=${pseudonym}`,
  });
};

//Thay đổi mật khẩu
export const changePassword = async (password) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    data: password,
    url: `${BASE_URL}/auth/password`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//Quên mật khẩu
export const forgotPassword = async (account) => {
  return await axios({
    method: "post",
    data: account,
    url: `${BASE_URL}/auth/password/recover`,
  });
};

//Reset mật khẩu
export const resetPassword = async (password, resetPasswordID) => {
  return await axios({
    method: "post",
    data: password,
    url: `${BASE_URL}/auth/password/reset?resetToken=${resetPasswordID}`,
  });
};

//API ĐĂNG NHẬP
// Gửi email đăng nhập
export const sendEmailLogin = async (email) => {
  return await axios({
    method: "post",
    data: email,
    url: `${BASE_URL}/accounts/email/all`,
  });
};

// Gửi thông tin đăng nhập
export const sendInfoLogin = async (dataSend) => {
  return await axios({
    method: "post",
    data: dataSend,
    url: `${BASE_URL}/auth/login`,
  });
};


// OBSOLETE
// // Tạo thư mục
// export const createNewFolder = async (folder) => {
//   const JWT_loginToken = common.getToken();
//   return await axios({
//     method: "post",
//     data: folder,
//     url: `${BASE_URL}/folder/create`,
//     headers: {
//       Authorization: `Bearer ${JWT_loginToken}`,
//     },
//   });
// };

// OBSOLETE
// //API FOLDER
// // Lấy tất cả thư mục của người dùng (COMMENT)
// export const getAllFolder = async () => {
//   const JWT_loginToken = common.getToken();
//   return await axios.get(`${BASE_URL}/folder/all`, {
//     headers: {
//       Authorization: `Bearer ${JWT_loginToken}`,
//     },
//   });
// };

// Lấy thông tin tổ chức
export const getInfoAccount = async (accountID) => {
  const token = common.getToken();
  return await axios.get(`${BASE_URL}/account/${accountID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// API TỔ CHỨC
// Cập nhật thông tin tổ chức
export const updateInfoAccount = async (dataSend, accountID) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    data: dataSend,
    url: `${BASE_URL}/account/${accountID}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

// Thêm user vào tổ chức
export const inviteUser = async (user) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "post",
    data: user,
    url: `${BASE_URL}/auth/invitation`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const updateUser = async (user, id) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    data: user,
    url: `${BASE_URL}/client/role/${id}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

// Lấy danh sách user của tổ chức
export const getAllUser = async (accountID) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/account/${accountID}/members`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });


};

export const deleteUser = async (user, accountID) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "delete",
    url: `${BASE_URL}/account/${accountID}/member/${user.id}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const searchUser = async (queryParam) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "post",
    data: queryParam,
    url: `${BASE_URL}/clients/search`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//domain
export const getDomain = async (accountID) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/account/${accountID}/domains`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const addNewDomain = async (domain, accountID) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "post",
    data: domain,
    url: `${BASE_URL}/accounts/${accountID}/domain`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const updateDomain = async (domain, domain_id) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    data: domain,
    url: `${BASE_URL}/domain/update/${domain_id}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//check domain exist
export const checkDomain = async (host) => {
  return await axios({
    method: "post",
    data: host,
    url: `${BASE_URL}/domains/valid`,
  });
};

//my_account
export const getMyAccount = async (id) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/client/${id}`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const updateMyAccount = async (account, id) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    data: account,
    url: `${BASE_URL}/client/${id}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

// Content
export const getContentClient = async (id) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/clients/${id}/contents`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//share content
export const shareContent = async (email, contentId) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "post",
    data: email,
    url: `${BASE_URL}/contents/${contentId}/sharing`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//H5P
export const getH5P = async () => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/h5p/new`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const createContent = async (requestBody,parent) => {
  const JWT_loginToken = common.getToken();

  if (parent){
    return await axios({
      method: "post",
      data: requestBody,
      url: `${BASE_URL}/h5p/new?parent=${parent}`,
      headers: {
        Authorization: `Bearer ${JWT_loginToken}`,
      },
    });
  } else return await axios({
    method: "post",
    data: requestBody,
    url: `${BASE_URL}/h5p/new`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });


};

export const viewContent = async (content_id) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/h5p/play/${content_id}`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const getContentEdit = async (content_id) => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/h5p/edit/${content_id}`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const editContent = async (requestBody, contentId) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "post",
    data: requestBody,
    url: `${BASE_URL}/h5p/edit/${contentId}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export const deleteContent = async (contentId) => {
  const JWT_loginToken = common.getToken();
  return await axios({
    method: "put",
    url: `${BASE_URL}/h5p/contents/${contentId}`,
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

//getRole
export const getRoles = async () => {
  const JWT_loginToken = common.getToken();
  return await axios.get(`${BASE_URL}/users-permissions/roles`, {
    headers: {
      Authorization: `Bearer ${JWT_loginToken}`,
    },
  });
};

export { connectLMSApi } from "./connectLMSApi";
export {folderManagementApi} from "./folderManagementApi"
