import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import "./button.scss";

const CopyToClipboardButton = memo(
  ({ value, iconFontSize = "12px", className }) => {
    const { t } = useTranslation("translation", {
      keyPrefix: "common",
    });
    const [copiedText, copy] = useCopyToClipboard();

    return (
      <>
        <span className={`${className ? className : ""}`}>{value}</span>
        <button onClick={() => copy(value)} className="copy-clipboard-wrapper">
          <ContentCopyRoundedIcon fontSize={iconFontSize} />{" "}
          {copiedText ? t("copied") : t("copy_to_clipboard")}
        </button>
      </>
    );
  }
);

export default CopyToClipboardButton;
