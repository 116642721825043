import * as React from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { handleChangeLocation } from '../function/fileManager';

import { useTranslation } from "react-i18next";
import DynamicTreeItem from './DynamicTreeItem';


export default function MoveDialog({
  openMoveDialog,
  handleCloseMoveDialog,
  moveFileId,
  rootContents
}) {

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });

  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);

  const [expandedItems, setExpandedItems] = React.useState(["root"]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };



  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };


  return (
    <Dialog
      open={openMoveDialog}
      onClose={handleCloseMoveDialog}
      fullWidth={true}
      maxWidth={'sm'}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          await handleChangeLocation({ contentId: moveFileId, newParentId: lastSelectedItem, handleCloseMoveDialog });
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {t("move_directory")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("pick_destination_directory")}
        </DialogContentText>

        <Box sx={{ minHeight: 352, minWidth: 250 }}>
          <SimpleTreeView
            onItemSelectionToggle={handleItemSelectionToggle}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          >
            <TreeItem key={0} itemId={"0"} label={t("root_directory")}>
              {rootContents && rootContents.map((content) =>
                // Only render item if it is a directory 
                // And if it is not the current directory
                (content.is_directory && content.id != moveFileId) &&
                (
                  <TreeItem key={content.id} itemId={`${content.id}`} label={content.title}>
                    <>
                      <DynamicTreeItem
                        moveFileId={moveFileId}
                        currentDirectory={content.id}
                      />
                    </>
                  </TreeItem>

                ))}
            </TreeItem>



          </SimpleTreeView>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          pb: '2em',
          mr: '1em'

        }}
      >
        <Button onClick={() => {
          handleCloseMoveDialog();
        }}>{t("cancel")}</Button>
        <Button
          type="submit"
          style={{
            backgroundColor: '#186df7',
            color: '#ffffff'
          }}
        >
          {t("save")}
        </Button>
      </DialogActions>

    </Dialog>
  );
}