import React from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import BodyManageContent from "../../../layouts/Body/BodyManageContent";
import { useTranslation } from "react-i18next";

const AllContent = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.AllContentPage",
  });

  return (
    <div className="homepagecontent_layout">
      <div className="homepagecontent_top">{t('all_content')}</div>

      <div className="allcontent_bottom">
        <BodyManageContent allContent={true} />
      </div>
    </div>
  );
};

export default function AllContentPage() {
  return (
    <div>
      <HomeLayout
        bodyManage={<AllContent />}
        addContent={true}
        newFolder={true}
        newOrganizationFolder={true}
        type="Manage Content"
      />
    </div>
  );
}
