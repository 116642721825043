export const LMS_TYPE = {
  CANVAS: "canvas",
  BLACKBOARD: "blackboard",
  BRIGHTSPACE: "brightspace",
  MOODLE: "moodle",
  OTHER: "other",
};

export const LTI_VERSION = {
  V1: "ltiv1p1",
  V3: "ltiv1p3",
};

export const CONNECTION_STATUS = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const tutorialUrl = "https://help.h5p.com/hc/en-us/articles";

export const TUTORIAL_LINK = {
  CANVAS: `${tutorialUrl}/7507920890653`,
  BLACKBOARD: `${tutorialUrl}/7505840839325`,
  BRIGHTSPACE: `${tutorialUrl}/7506356356893`,
  MOODLE: `${tutorialUrl}/7505789964701`,
};
