import parseJwt from "../../../funtions/parseJwt";
import TimeVN from "../../../funtions/timeVN";
import handleErrorFunction from "../../../funtions/handleErrorFunction";
import { useTranslation } from "react-i18next";
import {
    changePassword,
    connectLMSApi,
    deleteContent,
    getContentClient,
    getInfoAccount,
    folderManagementApi
} from "../../../api";
import { toast } from "react-toastify";
import { BASE_ROOT_DIRECTORY_ID } from "../../../constants";



export const handleCreateFolder = async ({directory_id, folderName,accountID,handleCloseModalNewFolder,navigate}) =>{

    // GET CURRENT CLIENT INFORMATION
    const client = (parseJwt(localStorage.getItem('JWT_loginToken')));


    const body= {
        data: {
            "name":folderName,
            "parent":directory_id?directory_id:null,
        }
    }
    // localStorage.setItem('directory_id',JSON.stringify({directory_id,body}));
    await folderManagementApi.createDirectory(body);
    handleCloseModalNewFolder();
    navigate(`/content/my-content/${directory_id}`);   
}



