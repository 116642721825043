import React from "react";
import { useTranslation } from "react-i18next";
import { common } from "../../utils/common";
import HeaderPage from "../Header/HeaderPage/HeaderPage";
import Navbar from "../Navbar/Navbar";
import SubHeader from "../SubHeader/SubHeader";
import Toolbar from "../Toolbar/Toolbar";
import parseJwt from "../../funtions/parseJwt";
import "./HomeLayout.scss";

export default function HomeLayout({
  bodyManage,
  type,
  newFolder,
  addContent,
  addUser,
  addDomain,
  newOrganizationFolder,
  addLMSConnection,
  showSubHeader = true,
  showToolbar = true,
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.HomeLayout",
  });


  const JWT_loginToken = common.getToken();
  const json = parseJwt(JWT_loginToken);
  const newRoleName = json.roleName;

  let listNavbarItem1;

  if (newRoleName === "Administrator" || newRoleName === "Super User"){
    listNavbarItem1 = [
      { id: 1, title: t("my_content"), link: "content/my-content" },
      { id: 2, title: t("share_with_me"), link: "content/share-me" },
      { id: 3, title: t("all_content"), link: "content/all-content" },
      { id: 4, title: t("trash"), link: "content/trash" },
    ];
  } else {
    listNavbarItem1 = [
      { id: 1, title: t("my_content"), link: "content/my-content" },
      { id: 2, title: t("share_with_me"), link: "content/share-me" },
      { id: 3, title: t("all_content"), link: "content/all-content" },
    ];
  }




  const listNavbarItem2 = [
    { id: 1, title: t("users"), link: "organization/users" },
    { id: 2, title: t("archived_users"), link: "organization/archived-user" },
    {
      id: 3,
      title: t("organization_details"),
      link: "organization/organization-details",
    },
    { id: 4, title: t("about_the_roles"), link: "organization/about-roles" },
    { id: 5, title: t("connect_LMS"), link: "organization/connect-LMS" },
    { id: 6, title: t("settings"), link: "organization/settings" },
    {
      id: 7,
      title: t("plans_and_billing"),
      link: "organization/plans-billing",
    },
    { id: 8, title: t("domain"), link: "organization/domain" },
  ];

  return (
    <div>
      {type === "Manage Content" ? (
        <div>
          {common.getLtiLMS() ? <></> : <HeaderPage />}
          {showSubHeader && (
            <SubHeader
              newFolder={newFolder}
              addContent={addContent}
              addUser={addUser}
              addDomain={addDomain}
              newOrganizationFolder={newOrganizationFolder}
              addLMSConnection={addLMSConnection}
            />
          )}
          {common.getLtiLMS() ? (
            <></>
          ) : (
            <Navbar
              listNavbarItem={listNavbarItem1}
              title={t("manage_content")}
            />
          )}
          {showToolbar && (
            <Toolbar title={t("manage_content")} className="toolbar" />
          )}
        </div>
      ) : (
        <div>
          {common.getLtiLMS() ? <></> : <HeaderPage />}
          {showSubHeader && (
            <SubHeader
              newFolder={newFolder}
              addContent={addContent}
              addUser={addUser}
              addDomain={addDomain}
              newOrganizationFolder={newOrganizationFolder}
              addLMSConnection={addLMSConnection}
              title={t("manage_organization")}
            />
          )}
          {common.getLtiLMS() ? (
            <></>
          ) : (
            <Navbar
              listNavbarItem={listNavbarItem2}
              title={t("manage_organization")}
            />
          )}
          {showToolbar && (
            <Toolbar title={t("manage_content")} className="toolbar" />
          )}
        </div>
      )}
      <div className={`responsive ${common.getLtiLMS() ? "has-lti" : ""}`}>
        {bodyManage}
      </div>
    </div>
  );
}
