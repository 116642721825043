import React from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DeletefilePermanentlyDialog({
    openPermanentlyDeletingDialog,
    handleClosePermanentlyDeletingDialog,
    deletePermanentlyDirectoryName,
    deletePermanentlyDirectoryId,
    handleConfirmedPermanentlyDeleteFile,
    setOpenPermanentlyDeletingDialog
}){
    const { t } = useTranslation("translation", {
        keyPrefix: "layouts.Body.BodyManageContent",
      });
    return (
    <>
        {/* File deleting confirmation dialog*/}
        <Dialog
            open={openPermanentlyDeletingDialog}
            onClose={handleClosePermanentlyDeletingDialog}
        >
            <DialogTitle id="alert-dialog-title">
            {t("delete_permanently")}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t("delete_file_permanently_confirmation")}
            </DialogContentText>
            <strong
                style={{fontWeight:'800'}}
            >{deletePermanentlyDirectoryName}</strong>
            ?
            </DialogContent>
            <DialogActions
                sx={{
                    pb:'2em',
                    mr:'1em'
                }}
            >
            <Button 
                onClick={()=>{
                    handleConfirmedPermanentlyDeleteFile({deletePermanentlyDirectoryId,setOpenPermanentlyDeletingDialog})
                }}
                style={{
                backgroundColor:'#c93724',
                color:'#ffffff'

                }}
            >{t("delete")}</Button>
            <Button onClick={handleClosePermanentlyDeletingDialog} autoFocus>
                {t("cancel")}
            </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}