import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import "../HomePageOrg.scss";
import "./ConnectLMSPage.scss";
import { useConnectLMSConext } from "./Context";
import AddConnectLMS from "./components/AddConnectLMS";
import ImportantInfo from "./components/ImportantInfo";
import LMSConnectionList from "./components/LMSConnectionList";

import ConnectionSettingsModal from "./modal/ConnectionSettingsModal";

const Inner = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const { paginationConnectLMSList: pagination } = useConnectLMSConext();

  return (
    <div className="homepageorg_layout">
      <div className="homepageorg_title">{t("connect_LMS")}</div>
      <div className="connectLMS_bottom">
        <div className="initial">
          <div className="text-normal">{t("if_your_LMS_")}</div>
          {pagination.total > 0 && <ImportantInfo />}

          {/* add 1 lms connection */}
          {pagination.total === 0 && (
            <>
              <div className="text-normal">{t("you_start_setting_")}</div>
              <AddConnectLMS />
            </>
          )}

          {/* lms connection list */}
          {pagination.total > 0 && <LMSConnectionList />}
        </div>
      </div>
    </div>
  );
});

export default Inner;
