import React, { useState, useEffect, useCallback } from "react";
import EditFileDialog from "./component/EditFileDialog";
import FileDisplayGrid from "./component/FileDisplayGrid";
import CustomPagination from "./component/CustomPagination";
import DetailPanelContent from "./component/DetailPanelContent";
import EmptyFolder from "../EmptyFolder/EmptyFolder";
import OptionsDropdown from "./component/OptionsDropdown";
import NotFound from "../NotFound/NotFound";

import "./BodyManage.scss";
import {
  handleInsert,
  loadFiles,
  handleRowClick,
  handleConfirmedPermanentlyDeleteFile,
  handleRestoreFile,
  handlePermanentlyDeleteFile,
  loadRootFiles
} from './function/fileManager';
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  IconButton
} from "@mui/material";
import {
  DriveFileRenameOutlineOutlined,
  Restore,
  DeleteForever,
} from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { useTranslation } from "react-i18next";
import { useNavigate,useParams } from "react-router-dom";
import { common } from "../../utils/common";
import { useAppContext } from "../../context/AppContext";
import DeletefilePermanentlyDialog from "./component/DeleteFilePermanentlyDialog";
import { toast } from "react-toastify";

export default  function BodyContent({ allContent, myContent, collabContent,trashContent }) {
  const navigate = useNavigate();
  // Get URL and extract params
  const {directory_id} = useParams();

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });
  const v = useTranslation("translation", {
    keyPrefix: "validates",
  });
  const {
    userInfo: { accountID, id },
  } = useAppContext();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const VISIBLE_FIELDS = matches
    ? ["title", "owner", "lastModified", "actions"]
    : ["title", "owner", "actions"];

  const [rows, setRows] = useState("");
  const [rootContents,setRootContents] = useState();

 
  const [loading, setLoading] = useState(false);

  const [openEditingDialog, setOpenEditingDialog] = useState(false);
  const [openPermanentlyDeletingDialog, setOpenPermanentlyDeletingDialog] = useState(false);


  const [currentDirectory, setCurrentDirectory] = useState(directory_id);

  const [editFileName,setEditFileName] = useState('');
  const [editFileId,setEditFileId] = useState(-1);

  const [deletePermanentlyDirectoryName,setDeletePermanentlyDirectoryName] = useState("");
  const [deletePermanentlyDirectoryId,setDeletePermanentlyDirectoryId] = useState(-1);
  const [menuOpen, setMenuOpen] = useState(false);

  
  const handleOpenPermanentlyDeletingDialog = () => setOpenPermanentlyDeletingDialog(true);
  const handleClosePermanentlyDeletingDialog = () => setOpenPermanentlyDeletingDialog(false);

  const handleOpenEditingDialog = () => setOpenEditingDialog(true);
  const handleCloseEditingDialog = () => setOpenEditingDialog(false);
  
  const handleOpenOption = () => setMenuOpen(true);
  const handleCloseOption = () => setMenuOpen(false);


  const accessMode = {
    "private" : t("private"),
    "public" : t("public"),
    "protected" : t("protected"),
  };

  const columns = [
    { 
      field: "id", 
      headerName: t("STT"),
      flex: 1,
    },
    { 
      field: "title", 
      headerName: t("title"), 
      flex: 1,
      renderCell: (params)=>{        
        return (
        <Box sx={{
            display: 'flex', 
            justifyContent: 'center', 
            cursor:'pointer',
          }}>

          {
            params.row.is_directory ? 
            (
              params.row.access_modifier === 'private' ?
              <FolderIcon/> : <FolderSharedIcon color="success"/>
            ) : (
                  params.row.access_modifier === 'private'?
                  <TouchAppIcon color="action"/>:<TouchAppIcon color="success"/>

                )
          }
          <div 
            style={{
              marginLeft:'1em',
            }}
            >
              <div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  {params.row.title}
                  { params.row.collaborators.length != 0 && ( 
                    <Tooltip title={t("shared_content")}>
                      <GroupIcon sx={{ fontSize: '14px', margin: '2px 0 0 7px' }} /> 
                    </Tooltip>
                    
                    )}
                </div>
                  
                <p 
                  style={{
                    fontSize : '10px',
                    color: 'rgba(153,153,153)',
                  }}
                >
                  {params.row.is_directory ? (
                    <>
                      {t("folder")}  {accessMode[params.row.access_modifier]}
                    </>
                  ):(
                    <>
                      {t("content")} {accessMode[params.row.access_modifier]}  
                    </>
                  )}
                </p>
              </div>
            </div>
        </Box>
      )}
      
    },

    {
      field: "owner",
      headerName: t("owner"),
      flex: 1,
    },
    {
      field: "lastModified",
      headerName: t("lastModified"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("createdAt"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 4 }}>
          {(common.getLtiLMS() && !params.row.is_directory)&& (
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => handleInsert({row:params.row})}
            >
              {t("insert")}
            </Button>
          )}
          {trashContent?
          (
            <>
              <Tooltip title={t("restore")}>
                <Restore
                  style={{ cursor: "pointer" }}
                  onClick={async () => {         
                    await handleRestoreFile({fileId:params.row.id});
                  }}
                />
              </Tooltip>
              <Tooltip title={t("delete_permanently")}>
                <DeleteForever
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePermanentlyDeleteFile({fileId:params.row.id, title:params.row.title, isFolder:true,setDeletePermanentlyDirectoryName,setDeletePermanentlyDirectoryId,handleOpenPermanentlyDeletingDialog})}
                />
              </Tooltip>

            </>
          ):(
            <>
              {params.row.isAbleToEdit && (

              <Tooltip title={t("edit")}>
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() => {             
                    if (params.row.is_directory){
                      setEditFileName(params.row.title);
                      setEditFileId(params.row.id);
                      handleOpenEditingDialog();
                    } else{
                      navigate(`/content/edit?id=${params.row.mongoId}`);    
                    }

                  }}
                >
                  <DriveFileRenameOutlineOutlined/>
                </IconButton>
              </Tooltip>
              )}
              {!params.row.hasNoPermission && (
  
                <OptionsDropdown 
                  style={{ cursor: "pointer" }}
                  open={menuOpen}
                  handleClose={handleCloseOption} 
                  row={params.row}
                  rootContents={rootContents}
                />
      
              )}

            </>
          )}
        </Box>
      ),
    },
  ];





  const loadData = useCallback(async () => {
    try {
    await loadFiles({accountID,currentDirectory,id,v,setRows,setLoading,navigate},
              { allContent, myContent, collabContent,trashContent });
    
    const contents = await loadRootFiles({accountID,id,v,},
    { allContent, myContent, collabContent,trashContent })

    setRootContents(contents);

    } catch(error){
      toast.error("Error happens");
    }

  },[accountID,currentDirectory])


  const columnss = React.useMemo(
    () => columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [columns]
  );
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const getDetailPanelHeight = React.useCallback(() => 240, []);


  useEffect( () => {

    loadData();


    const handleUrlChange = () =>{
      const currentUrlDirectory = window.location.pathname.split('/')[3];
      if (currentUrlDirectory != currentDirectory){
        setCurrentDirectory(currentUrlDirectory);
        // loadData();
        window.location.reload();
      }
    };

    // Add event listener to catch back navigation button press event
    window.addEventListener('popstate',handleUrlChange);

    return () => window.removeEventListener('popstate',handleUrlChange);

  }, [currentDirectory,loadData]);

  return (
    <>
      {loading === true && (
        <>
          {rows.length === 0 ? (
            <>
              {allContent === true ? (
                <EmptyFolder type="allContent" />
              ) : myContent === true ? (
                <EmptyFolder type="myContent" />
              ) : (
                <EmptyFolder type="shareContent" />
              )}
            </>
          ) : (
      <div className="bodymanage_layout">
        <div className="bodymanage_table">
        {/* Files display area */}
        <FileDisplayGrid
          rows={rows}
          columnss={columnss}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          CustomPagination={CustomPagination}
          handleRowClick={handleRowClick}
          setCurrentDirectory={setCurrentDirectory}
          navigate={navigate}


        />

        {/* Edit dialog */}
        <EditFileDialog
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
          handleCloseEditingDialog={handleCloseEditingDialog}
          editFileName={editFileName}
          editFileId={editFileId}
        />

        {/* Delete permanently dialog */}
        <DeletefilePermanentlyDialog
          openPermanentlyDeletingDialog={openPermanentlyDeletingDialog}
          setOpenPermanentlyDeletingDialog={setOpenPermanentlyDeletingDialog}
          handleClosePermanentlyDeletingDialog={handleClosePermanentlyDeletingDialog}
          deletePermanentlyDirectoryName={deletePermanentlyDirectoryName}
          deletePermanentlyDirectoryId={deletePermanentlyDirectoryId}
          handleConfirmedPermanentlyDeleteFile={handleConfirmedPermanentlyDeleteFile}
        />
        </div>
      </div>
          )}
        </>
      )}
    </>
  );
}
