import React from "react";
import { Facebook, Twitter, EmailOutlined } from "@mui/icons-material";
import Cohota from "../../components/CohotaLogo/CohotaLogo";
import "./VerifyEmail.scss";
import { useTranslation } from "react-i18next";

export default function VerifyEmail({
  title,
  subtitle,
  text1,
  email,
  text2,
  text3,
}) {
  //Đa ngôn ngữ
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.VerifyEmail",
  });

  return (
    <div className="verify-email">
      <div className="verify-email_top">
        <div className="logo">
          <Cohota color="#fff" />
        </div>
        <div className="background-green">{title}</div>
        <div className="background-fff">
          <div className="content">
            <EmailOutlined className="email-icon" />

            <div className="content-text">
              <div className="title-content">{subtitle}</div>
              <div className="subtitle-content">
                {text1} <span className="text-bold">{email}</span>
                {text2}
              </div>
              <div className="subtitle-content">{text3}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="verify-email_bottom">
        <div className="title">{t("help_spread_the_world_")}</div>
        <div className="subtitle">{t("H5P_Sass")}</div>
        <div className="two-icons">
          <Facebook className="icon" />
          <Twitter className="icon" />
        </div>
        <a
          className="privacy"
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_PRIVACY}
        >
          {t("privacy_policy")}
        </a>
      </div>
    </div>
  );
}
