import { Button, Grid } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import Select from "../../../../components/Dropdown/Select";
import Input from "../../../../components/Input/Input";
import "../../../HomePageOrg/HomePageOrg.scss";
import "../ConnectLMSPage.scss";
import { useConnectLMSConext } from "../Context";

const HYPHEN = "-";

const LTI_VERSION = [
  { id: "ltiv1p1", name: "LTI v1.1" },
  { id: "ltiv1p3", name: "LTI v1.3" },
];
const AddConnectLMS = memo(({ isModal = false, onCloseModal }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const { reloadPage } = useConnectLMSConext();

  const [LMS, setLMS] = useState(HYPHEN);
  const [version, setVersion] = useState("ltiv1p1");
  const [connectName, setConnectName] = useState("");
  const [lmsList, setLmsList] = useState([]);

  const handleLMSChange = (e) => {
    const { value } = e.target;
    setLMS(value);
    const selectedOption = lmsList.find(
      (option) => option.id === Number(value)
    );
    if (selectedOption.id !== HYPHEN) {
      setConnectName(selectedOption.name);
    } else {
      setConnectName("");
    }
  };

  const handleChangeVersion = (e) => {
    setVersion(e.target.value);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setConnectName(value);
  };

  const handleSubmit = useCallback(async () => {
    const payload = {
      data: {
        name: connectName,
        lms: [LMS],
        version,
      },
    };

    // //log version 
    // console.log("Version = " + version);

    try {
      const response = await connectLMSApi.createConnectionLMS(payload);
      if (response.status === 200) {
        toast.success(t("add.successText"));
        reloadPage();
        onCloseModal?.();
      }
    } catch (err) {
      toast.error(err);
    }
  }, [LMS, connectName, onCloseModal, reloadPage, t, version]);

  const getLMSList = useCallback(async () => {
    try {
      const response = await connectLMSApi.getLMSs();
      if (response.status === 200) {
        const options = [{ id: "-", name: "-" }, ...(response.data.data ?? [])];
        setLmsList(options);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getLMSList();
  }, [getLMSList]);

  return (
    <>
      <Grid
        container
        spacing={2}
        // columns={16}
        // className="add-connection-wrapper"
      >
        {/* <Grid item xs={8} md={7}> */}
        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
          <Select
            value={LMS}
            listItem={lmsList}
            label={t("add.select_your_LMS")}
            onChange={handleLMSChange}
          />
        </Grid>
        {LMS !== HYPHEN && (
          <>
            <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
              <Input
                type="text"
                label={t("add.connection_name")}
                value={connectName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
              <Select
                value={version}
                listItem={LTI_VERSION}
                label={t("add.lti_version")}
                onChange={handleChangeVersion}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div>
        <Button
          variant="contained"
          className={`button ${isModal ? "save-btn" : ""}`}
          onClick={handleSubmit}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
});

export default AddConnectLMS;
