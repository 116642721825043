export const BASE_URL = process.env.REACT_APP_BE_URL; // /api/v1
export const BASE_ROOT_DIRECTORY_ID = 1;
export const DEFAULT_PAGE_SIZE = 25;

export const routeConstants = {
  LOGIN: "/login",
  ANNOUNCEMENTS: "/announcements",
  ACCOUNT: "/account",
  CHANGE_PASSWORD: "/change-password",

  // content
  CONTENT: "/content",
  MY_CONTENT: "/content/my-content",
  ADD_NEW_CONTENT: "/content/new",
  EDIT_CONTENT: "/content/edit",
  VIEW_CONTENT: "/content/view",
  SHARE_ME: "/content/share-me",
  ALL_CONTENT: "/content/all-content",
  TRASH: "/content/trash",

  // organization
  USERS: "/organization/users",
  ARCHIVED_USER: "/organization/archived-user",
  ORGANIZATION_DETAILS: "/organization/organization-details",
  ABOUT_ROLES: "/organization/about-roles",
  CONNECT_LMS: "/organization/connect-LMS",
  SETTINGS: "/organization/settings",
  PLANS_BILLING: "/organization/plans-billing",
  DOMAIN: "/organization/domain",
};
