import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./layouts/NotFound/NotFound";
import routes from "./routes/index";
import React from "react";
import UnauthorizedPage from "./layouts/NotFound/Unauthorized";

function App({ roleId, unauthorizedRoutes }) {
  return (
    <Router>
      <div className="app">
        <Routes>
          {routes.map((route, index) => {
            const Page = route.component;
            const isUnauthorized = unauthorizedRoutes.some(
              (unauthorizedRoute) => unauthorizedRoute.path === route.path
            );

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  isUnauthorized && roleId === 7 ? (
                    <UnauthorizedPage />
                  ) : (
                    <Page />
                  )
                }
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
