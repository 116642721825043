import React from "react";
import "./ContentLanding.scss";
import img from "../../../assets/img/LandingPage/image.jpg";
import { Typography } from "@mui/material";

export default function ContentLanding({ listContent }) {
  return (
    <div className="slider_container_temp">
      <div className="slider_item slider_item2">
        <img src={img} className="image" alt="" />
      </div>
      <div className="slider_item slider_item1">
        {listContent.map((index) => {
          return (
            <Typography variant="subtitle1" gutterBottom className="question">
              {index["title"]}
            </Typography>
          );
        })}
      </div>
    </div>
  );
}
