import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';



import {
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleAddCollaborator, handleRemoveCollaborator } from "../function/userManager";


export default function AddCollaboratorDialog({
  openAddCollaboratorDialog,
  handleCloseAddCollaboratorDialog,
  editFileId,
  currentCollaborators,
  currentOwner,
  users
}) {
  const navigate = useNavigate();
  // Get account ID
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });

  const [collaborator, setCollaborator] = useState();


  const handleChange = (event) => {
    setCollaborator(event.target.value);
  };

  return (
    <>
      <Dialog
        open={openAddCollaboratorDialog}
        onClose={() => {
          setCollaborator('');
          handleCloseAddCollaboratorDialog();
        }}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            await handleAddCollaborator({ contentId: editFileId, collaboratorId: collaborator, handleCloseAddCollaboratorDialog, navigate });

          },
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{t("add_collaborator")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("pick_new_collaborator")}
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={collaborator}
              label="collaborator"
              onChange={handleChange}
            >
              {users && users.map((user) => (
                <MenuItem value={user.id}>{user.fullName}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <List dense={false}>
            <ListItem
              key={currentOwner.id}

            >
              <ListItemAvatar>
                <AccountCircleIcon />
              </ListItemAvatar>
              <ListItemText
                primary={`${currentOwner.fullName} (Owner)`}
              // secondary={collaborator.email}
              />
            </ListItem>
            {currentCollaborators && currentCollaborators.map((collaborator) => (
              <ListItem
                key={collaborator.id}
                secondaryAction={
                  <IconButton onClick={async () => {
                    if (window.confirm("Are you sure you want to remove this collaborator?")) {
                      await handleRemoveCollaborator({ contentId: editFileId, collaboratorId: collaborator.id, handleCloseAddCollaboratorDialog, navigate });
                    }
                  }
                  }>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <AccountCircleIcon />
                </ListItemAvatar>
                <ListItemText
                  primary={collaborator.fullName}
                // secondary={collaborator.email}
                />
              </ListItem>
            ))}
          </List>


        </DialogContent>
        <DialogActions
          sx={{
            pb: '2em',
            mr: '1em'

          }}
        >
          <Button onClick={() => {
            setCollaborator('')
            handleCloseAddCollaboratorDialog();
          }}>{t("cancel")}</Button>
          <Button
            type="submit"
            style={{
              backgroundColor: '#186df7',
              color: '#ffffff'
            }}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}