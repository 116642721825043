import {
  Delete,
  DriveFileRenameOutlineOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {
  DataGrid,
  GridPagination,
  GridToolbar,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { deleteUser, getAllUser, getRoles, updateUser } from "../../api";
import Dropdown from "../../components/Dropdown/Dropdown";
import Input from "../../components/Input/Input";
import { useAppContext } from "../../context/AppContext";
import parseJwt from "../../funtions/parseJwt";
import "./BodyManage.scss";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function DetailPanelContent({ row }) {
  const apiRef = useGridApiContext();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: row,
    mode: "onChange",
  });

  const onSubmit = (data) => {
    apiRef.current.updateRows([data]);
    apiRef.current.toggleDetailPanel(row.id);
  };

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack
          component="form"
          justifyContent="space-between"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ height: 1, maxWidth: 400, gap: 3 }}
        >
          <Typography variant="h6">{`Edit ID #${row.id}`}</Typography>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field, fieldState: { invalid } }) => (
              <TextField
                label="Name"
                size="small"
                error={invalid}
                required
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            rules={{ required: true }}
            render={({ field, fieldState: { invalid } }) => (
              <Select
                label="Role"
                size="small"
                error={invalid}
                required
                fullWidth
                {...field}
              >
                <MenuItem value="Administrator">Administrator</MenuItem>
                <MenuItem value="Super user">SuperUser</MenuItem>
                <MenuItem value="Author">Author</MenuItem>
              </Select>
            )}
          />
          <div>
            <Button
              type="submit"
              variant="outlined"
              size="small"
              disabled={!isValid}
            >
              Save
            </Button>
          </div>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default function BodyContent() {
  const {
    userInfo: { accountID },
  } = useAppContext();
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageOrg",
  });
  const columns = [
    { field: "fullName", headerName: t("name"), flex: 6 },

    {
      field: "roleName",
      headerName: t("role"),
      flex: 5,
    },
    {
      field: "lastVisit",
      headerName: t("last_visit"),
      flex: 5,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 5,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 3 }}>
          <DriveFileRenameOutlineOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit(params.row)}
          />
          <Delete
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(params.row)}
          />
          <InfoOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleDetail(params.row)}
          />
        </Box>
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [rowDelete, setRowDelete] = useState("");

  const [selectedRow, setSelectedRow] = useState({
    id: "",
    fullName: "",
    roleName: "",
    lastVisit: "",
  });

  const [roles, setRoles] = useState([]);

  const [openModalEditRows, setOpenModalEditRows] = React.useState(false);
  const handleOpenModalEditRows = () => setOpenModalEditRows(true);
  const handleCloseModalEditRows = () => setOpenModalEditRows(false);

  const [openModalDetailRows, setOpenModalDetailRows] = React.useState(false);
  const handleOpenModalDetailRows = () => setOpenModalDetailRows(true);
  const handleCloseModalDetailRows = () => setOpenModalDetailRows(false);

  const [openModalDeleteRows, setOpenModalDeleteRows] = React.useState(false);
  const handleOpenModalDeleteRows = () => setOpenModalDeleteRows(true);
  const handleCloseModalDeleteRows = () => {
    setOpenModalDeleteRows(false);
    setRowDelete("");
  };

  const handleEdit = (row) => {
    handleOpenModalEditRows();
    setSelectedRow({
      id: row.id,
      fullName: row.fullName,
      roleName: row.roleName,
    });
  };

  const handleDelete = (row) => {
    handleOpenModalDeleteRows();
    setRowDelete(row);
  };

  const handleDetail = (row) => {
    handleOpenModalDetailRows();
    setSelectedRow({
      id: row.id,
      fullName: row.fullName,
      roleName: row.roleName,
      lastVisit: row.lastVisit,
    });
  };

  const mobile = useMediaQuery("(max-width:767px)");
  const VISIBLE_FIELDS =
    mobile === true
      ? ["fullName", "roleName", "actions"]
      : ["fullName", "roleName", "actions", "lastVisit"];

  const loadData = async () => {
    try {
      const respond = await getAllUser(accountID);
      const json = parseJwt(respond.data.jwt);
      setRows(json.clients);
    } catch (err) {}
  };

  const handleGetRoles = async () => {
    try {
      const res = await getRoles();
      setRoles(res.data.roles);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetRoles();
    loadData();
  }, []);

  const columnss = React.useMemo(
    () => columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [columns]
  );

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const getDetailPanelHeight = React.useCallback(() => 240, []);

  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setSelectedRow({
      ...selectedRow,
      [name]: value,
    });
  };

  const handleDeleteUser = async () => {
    try {
      const respond = await deleteUser(rowDelete);
      if (respond.status === 200) {
        handleCloseModalDeleteRows();
        toast.success(t("delete_user_successfully"));
        loadData();
        setRowDelete("");
      }
    } catch (error) {
      toast.error(t("Cannot_delete_this_user"));
      handleCloseModalDeleteRows();
      setRowDelete("");
    }
  };

  const handleEditUser = async () => {
    try {
      const userUpdate = {
        role: selectedRow.roleName,
        name: selectedRow.fullName,
      };
      const id = selectedRow.id;
      const respond = await updateUser(userUpdate, id);
      if (respond.status === 200) {
        toast.success(t("user_information_update_successful"));
        handleCloseModalEditRows();
        loadData();
      }
    } catch (err) {
      toast.error("Không thể chỉnh sửa người dùng này");
      handleCloseModalEditRows();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEditUser();
    }
  };

  const handleRowClick = () => {};

  return (
    <div className="bodymanage_layout">
      <div className="bodymanage_table">
        <DataGrid
          rows={rows}
          columns={columnss}
          pagination
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          slots={{
            pagination: CustomPagination,
            toolbar: GridToolbar,
          }}
          localeText={{
            toolbarColumns: t("columns"),
            toolbarFilters: t("filter"),
            toolbarDensity: t("density"),
            toolbarExport: t("export"),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          {...rows}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[5, 10, 15, 20, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onCellClick={(params, event) => {
            const { field } = params.colDef;
            if (
              field === "fullName" ||
              field === "roleName" ||
              field === "lastVisit"
            ) {
              handleRowClick(params);
            }
          }}
          className="grid"
        />
      </div>
      <Modal
        open={openModalEditRows}
        onClose={handleCloseModalEditRows}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="Box-Modal"
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
        >
          <Input
            label="Name"
            value={selectedRow.fullName}
            name="fullName"
            onChange={handleChangeName}
            onKeyDown={handleKeyDown}
          />
          <Dropdown
            value={selectedRow.roleName}
            listItem={roles}
            label="Role"
            name="roleName"
            onChange={handleChangeName}
            info
            nameInfo={selectedRow.fullName}
          />

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              className="button"
              onClick={handleEditUser}
            >
              {t("save")}
            </Button>
            <Button
              size="large"
              className="button"
              style={{ color: "#3c4859" }}
              onClick={handleCloseModalEditRows}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalDetailRows}
        onClose={handleCloseModalDetailRows}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="Box-Modal"
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
        >
          <Input
            label="Name"
            value={selectedRow.fullName}
            name="fullName"
            disabled="disabled"
          />
          <Input
            label="Role"
            value={selectedRow.roleName}
            name="role"
            disabled="disabled"
          />
          <Input
            label="Last Visit"
            value={selectedRow.lastVisit}
            name="lastVisit"
            disabled="disabled"
          />

          <Box sx={{ display: "flex" }}>
            <Button
              size="large"
              className="button cancel-button"
              style={{ color: "#3c4859" }}
              onClick={handleCloseModalDetailRows}
            >
              {t("close")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalDeleteRows}
        onClose={handleCloseModalDeleteRows}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="Box-Modal"
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
        >
          <div>
            {t("are_you_sure_you_want_to_delete_user")}:<br />
            <Typography variant="h5">{rowDelete.fullName} ?</Typography>
          </div>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              className="button"
              onClick={handleDeleteUser}
            >
              {t("delete")}
            </Button>
            <Button
              size="large"
              className="button"
              style={{ color: "#3c4859" }}
              onClick={handleCloseModalDeleteRows}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
