import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import parseJwt from "../../../funtions/parseJwt";
import BodyManageContent from "../../../layouts/Body/BodyManageContent";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { common } from "../../../utils/common";

const HomeContent = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.HomePageContent",
  });
  return (
    <div className="homepagecontent_layout">
      <div className="homepagecontent_top">{t("my_content")}</div>

      <div className="allcontent_bottom">
        <BodyManageContent myContent={true} />
      </div>
    </div>
  );
};

export default function HomePageContent() {
  const { setUserInfo } = useAppContext();

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    setUserInfo(json);
  }, []);

  return (
    <div>
      <HomeLayout
        addContent={true}
        newFolder={true}
        bodyManage={<HomeContent />}
        type="Manage Content"
      />
    </div>
  );
}
