import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const MyTable = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.According.ContentTypeSetting",
  });
  const contentTypes = [
    t("content_types.accordion"),
    t("content_types.complex"),
    t("content_types.agamotto"),
    t("content_types.appear"),
    t("content_types.arithmetic"),
    t("content_types.scavenger"),
    t("content_types.audio"),
    t("content_types.audio_recorder"),
    t("content_types.fill"),
    t("content_types.branching"),
    t("content_types.chart"),
    t("content_types.chase"),
    t("content_types.collage"),
    t("content_types.column"),
  ];

  const [rowStates, setRowStates] = useState(
    contentTypes.map(() => ({
      allowChecked: false,
      allowInsideChecked: false,
      allowImportChecked: false,
    }))
  );

  const handleAllowChange = (index) => {
    setRowStates((prevRowStates) => {
      const newRowStates = [...prevRowStates];
      newRowStates[index].allowChecked = !newRowStates[index].allowChecked;
      newRowStates[index].allowInsideChecked = false;
      newRowStates[index].allowImportChecked = false;
      return newRowStates;
    });
  };

  const handleAllowInsideChange = (index) => {
    if (!rowStates[index].allowChecked) {
      setRowStates((prevRowStates) => {
        const newRowStates = [...prevRowStates];
        newRowStates[index].allowInsideChecked =
          !newRowStates[index].allowInsideChecked;
        return newRowStates;
      });
    }
  };

  const handleAllowImportChange = (index) => {
    if (!rowStates[index].allowChecked) {
      setRowStates((prevRowStates) => {
        const newRowStates = [...prevRowStates];
        newRowStates[index].allowImportChecked =
          !newRowStates[index].allowImportChecked;
        return newRowStates;
      });
    }
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "60%" }}>
                {t("header_table.content")}
              </TableCell>
              <TableCell>{t("header_table.allow")}</TableCell>
              <TableCell>{t("header_table.allow_inside")}</TableCell>
              <TableCell>{t("header_table.allow_import")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contentTypes.map((index, rowIndex) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rowStates[rowIndex].allowChecked}
                      onChange={() => handleAllowChange(rowIndex)}
                      disabled={
                        rowStates[rowIndex].allowInsideChecked ||
                        rowStates[rowIndex].allowImportChecked
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rowStates[rowIndex].allowInsideChecked}
                      onChange={() => handleAllowInsideChange(rowIndex)}
                      disabled={rowStates[rowIndex].allowChecked}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={rowStates[rowIndex].allowImportChecked}
                      onChange={() => handleAllowImportChange(rowIndex)}
                      disabled={rowStates[rowIndex].allowChecked}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MyTable;
