import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../../../components/Dialog/Alerts";

const UpdateEmails = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage.connectionItem.updateEmails",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setCheckedValue(checked);
    if (checked) {
      setIsOpen(!isOpen);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = useCallback(() => {
    console.log("invoke api UpdateEmails");
  }, []);

  return (
    <>
      <FormControlLabel
        control={<Checkbox />}
        label={t("label")}
        value={checkedValue}
        onChange={handleChange}
      />
      <AlertDialog
        open={isOpen}
        toggle={handleOnClick}
        handleSubmit={handleSubmit}
        title={t("title")}
        desc={t("desc")}
      />
    </>
  );
});

export default UpdateEmails;
