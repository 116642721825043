import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Button } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import AlertDialog from "../../../../components/Dialog/Alerts";
import { CONNECTION_STATUS } from "../../../../constants/connectLMS";
import { useConnectLMSConext } from "../Context";

const DisableButton = memo(({ id }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const { reloadPage } = useConnectLMSConext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDisable = useCallback(async () => {
    if (id) {
      const payload = {
        data: {
          status: CONNECTION_STATUS.DISABLED,
        },
      };
      try {
        const response = await connectLMSApi.updateConnectionLMS(id, payload);
        if (response.status === 200) {
          handleOnClick();
          toast.success(t("disable.successText"));
          reloadPage();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [handleOnClick, id, reloadPage, t]);

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        startIcon={<ClearRoundedIcon />}
        onClick={handleOnClick}
      >
        {t("disable.title")}
      </Button>
      <AlertDialog
        open={isOpen}
        toggle={handleOnClick}
        handleSubmit={handleDisable}
        title={t("disable.title")}
        desc={t("disable.successText")}
      />
    </>
  );
});

export default DisableButton;
