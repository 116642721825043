import React, { useState } from "react";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletefileDialog from "./DeleteFileDialog";
import MoveDialog from "./MoveDialog";
import ChangeOwnerDialog from "./ChangeOwnerDialog";
import AddCollaboratorDialog from "./AddCollaboratorDialog";
import ChangeAccessModifierDialog from "./ChangeAccessModifierDialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

import {
  handleDeleteFile,
  handleConfirmedDeleteFile,
  handleMoveFile,
  handleChangeOwner,
  handleAddCollaborator,
  handleChangeAccessModifier,
  handleCloneContent

} from '../function/fileManager';

import  {excludeUsers, fetchCollaborators, fetchOwner, fetchUsers} from "../function/userManager";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function OptionsDropdown({row,rootContents}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });
  const v = useTranslation("translation", {
    keyPrefix: "validates",
  });

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeletingDialog, setOpenDeletingDialog] = useState(false);

  const [editFileName,setEditFileName] = useState("");
  const [editFileId,setEditFileId] = useState(row.id);
  const [currentCollaborators,setCurrentCollaborators] = useState([]);
  const [currentOwner,setCurrentOwner] = useState("");
  const [currentAccessModifier,setCurrentAccessModifier] = useState(row.access_modifier);
  const [users, setUsers] = useState([]);


  const {isAbleToChangeLocation,
  isAbleToEdit,
  isAbleToClone,
  isAbleToDelete,
  isAbleToAddCollaborator,
  isAbleToAssignOwner,
  isAbleToChangeAccessModifier} = row;


  const [openMoveDialog,setOpenMoveDialog] = useState(false);
  const [openChangeOwnerDialog,setOpenChangeOwnerDialog] = useState(false);
  const [openAddCollaboratorDialog,setOpenAddCollaboratorDialog] = useState(false);
  const [openChangeAccessModifierDialog,setOpenChangeAccessModifierDialog] = useState(false);
  
  const handleOpenMoveDialog = () => setOpenMoveDialog(true);
  const handleCloseMoveDialog = () => setOpenMoveDialog(false);

  const handleOpenChangeOwnerDialog = () =>setOpenChangeOwnerDialog(true);
  const handleCloseChangeOwnerDialog = () => setOpenChangeOwnerDialog(false);

  const handleOpenAddCollaboratorDialog = () => setOpenAddCollaboratorDialog(true);
  const handleCloseAddCollaboratorDialog = () => setOpenAddCollaboratorDialog(false);

  const handleOpenChangeAccessModifierDialog = () =>setOpenChangeAccessModifierDialog(true);
  const handleCloseChangeAccessModifierDialog = () => setOpenChangeAccessModifierDialog(false);


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeletingDialog = () => {
    setOpenDeletingDialog(true);
  };

  const handleCloseDeletingDialog = () => {
    setOpenDeletingDialog(false);
  };

  return (
    <div>
      <Tooltip title={t("more")}>
        <IconButton
            onClick={handleClick}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            // disableElevation
            style={{ cursor: "pointer" }}
        >
          <MoreVertIcon

          />
        </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
        {isAbleToChangeLocation &&
          <MenuItem onClick={()=>{
            handleMoveFile({
              fileId:row.id,
              title:row.title,
              isFolder:row.is_directory,
              contents:row.contents,
              rootContents:rootContents,
              setMoveFileName:setEditFileName,
              setMoveFileId:setEditFileId,
              handleOpenMoveDialog
            });
            handleClose();
          }} disableRipple>
                    <>
                      <ZoomOutMapIcon />
                      {t("move")}
                    </>
          </MenuItem>
        }  
          {isAbleToClone &&
            <MenuItem onClick={async ()=>{
              await handleCloneContent({mongoId:row.mongoId},navigate);
              handleClose();
            }} disableRipple>
                    <>
                      <ContentCopyIcon />
                      {t("clone")}
                    </>
            </MenuItem>
            }  
            {isAbleToAddCollaborator &&
              <MenuItem onClick={async ()=>{
                  handleClose();
                  const owner = await fetchOwner(row.id);
                  const collaborators = await fetchCollaborators(row.id);
                  const allUsers = await fetchUsers();
                  // setUsers(allUsers.filter(user=>user.id!==row.ownerId));
                  setUsers(excludeUsers(allUsers,collaborators,owner));
                  setCurrentOwner(owner);
                  setCurrentCollaborators(collaborators);
                  handleAddCollaborator({
                    fileId:row.id,
                    title:row.title,
                    setAddCollaboratorFileName:setEditFileName,
                    setAddCollaboratorFileId:setEditFileId,
                    handleOpenAddCollaboratorDialog
                  });
                
                }} disableRipple>
                    <>
                      <GroupAddIcon />
                      {t("add_collaborator")}
                      </>
              </MenuItem>
            }  

          {isAbleToAssignOwner&&
            <MenuItem onClick={async()=>{
                handleClose();
                const owner = await fetchOwner(row.id);
                const collaborators = await fetchCollaborators(row.id);
                const allUsers = await fetchUsers();
                setUsers(allUsers.filter(user=>user.id!==row.ownerId));
                setCurrentOwner(owner);
                setCurrentCollaborators(collaborators);
                handleChangeOwner({
                  fileId:row.id,
                  title:row.title,  
                  setEditFileName,
                  setEditFileId,
                  handleOpenChangeOwnerDialog
                })
              }} disableRipple>
                  <>
                    <AddCircleOutlineIcon />
                    {t("assign_new_owner")}
                  </>
            </MenuItem>
            }
          { isAbleToChangeAccessModifier &&
            <MenuItem onClick={()=>{
              handleClose();
              handleChangeAccessModifier({
                fileId:row.id,
                title:row.title,
                isFolder:row.is_directory,
                setEditFileName,
                setEditFileId,
                handleOpenChangeAccessModifierDialog
              })

            }} disableRipple>
                  <ChangeCircleIcon />
                  {t("change_access_modifier")}
            </MenuItem>
            }
          {isAbleToDelete&&
          <MenuItem onClick={()=>{
            handleDeleteFile({
              fileId:row.id,
              title:row.title,
              isFolder:row.is_directory,
              setDeleteFileName:setEditFileName,
              setDeleteFileId:setEditFileId,
              handleOpenDeletingDialog
            });
            handleClose();
            
            }} disableRipple>
              <DeleteIcon/> 
              {t("delete_file")}
          </MenuItem>
          }
      </StyledMenu>

      <DeletefileDialog
        openDeletingDialog={openDeletingDialog}
        setOpenDeletingDialog={setOpenDeletingDialog}
        handleCloseDeletingDialog={handleCloseDeletingDialog}
        deleteFileName={editFileName}
        deleteFileId={editFileId}
        handleConfirmedDeleteFile={handleConfirmedDeleteFile}
      />

      <MoveDialog
        openMoveDialog={openMoveDialog}
        setOpenMoveDialog={setOpenMoveDialog}
        handleCloseMoveDialog={handleCloseMoveDialog}
        moveFileName={editFileName}
        moveFileId={editFileId}
        contents={row.contents}
        rootContents={rootContents}
      />

      <ChangeOwnerDialog
        openChangeOwnerDialog={openChangeOwnerDialog}
        setOpenChangeOwnerDialog={setOpenChangeOwnerDialog}
        handleCloseChangeOwnerDialog={handleCloseChangeOwnerDialog}
        editFileName={editFileName}
        editFileId={editFileId}
        handleChangeOwner={handleChangeOwner}
        users={users}
      />

      <AddCollaboratorDialog
        openAddCollaboratorDialog={openAddCollaboratorDialog}
        setOpenAddCollaboratorDialog={setOpenAddCollaboratorDialog}
        handleCloseAddCollaboratorDialog={handleCloseAddCollaboratorDialog}
        editFileName={editFileName}
        editFileId={editFileId}
        currentCollaborators={currentCollaborators}
        currentOwner={currentOwner}
        users={users}
      />

      <ChangeAccessModifierDialog
        openChangeAccessModifierDialog={openChangeAccessModifierDialog}
        setOpenChangeAccessModifierDialog={setOpenChangeAccessModifierDialog}
        handleCloseChangeAccessModifierDialog={handleCloseChangeAccessModifierDialog}
        editFileName={editFileName}
        editFileId={editFileId}
        currentAccessModifier={currentAccessModifier}
        isFolder={row.is_directory}
      />

    </div>
  );
}
