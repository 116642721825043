import React, { useState, useEffect, useCallback } from "react";
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useAppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { loadTreeItem } from "../function/fileManager";


export default function DynamicTreeItem({
    moveFileId,
    currentDirectory,
}) {


  const v = useTranslation("translation", {
    keyPrefix: "validates",
  });

  const {
    userInfo: { accountID, id },
  } = useAppContext();

  const [treeContents,setTreeContents] = useState("");


//   console.log(moveFileName,contents);
const loadData = useCallback(async () => {
    
    const contents = await loadTreeItem({accountID,id,currentDirectory,v,},
    { allContent:true, myContent:false, collabContent:false })

    setTreeContents(contents);

  },[accountID]);

  useEffect( () => {

    loadData();


  }, [loadData]);


  return (  
    <>
        {treeContents && treeContents.map((content) => 
            // Only render item if it is a directory (mongoId == null)
            (content.is_directory && content.id != moveFileId) &&
        (
            <TreeItem key={content.id} itemId={`${content.id}`} label={content.title}>
                <DynamicTreeItem
                    contents={treeContents}
                    currentDirectory={content.id}
                />

            </TreeItem>
        ))}

    </>

  );
}