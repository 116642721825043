import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";

export default function Navbar({ listNavbarItem, title }) {
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className="navbar_container">
      <div className="navbar_title">{title}</div>
      {listNavbarItem.map((item) => {
        return (
          <NavLink
            to={`/${item["link"]}`}
            key={item["id"]}
            style={{ textDecoration: "none", color: "#000" }}
            onClick={() => handleItemClick(item["id"])}
            className={`navbar_item ${
              activeItem === item["id"] ? "active" : ""
            }`}
          >
            <div>{item["title"]}</div>
          </NavLink>
        );
      })}
    </div>
  );
}
