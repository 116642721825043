import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleChangeAccess } from "../function/fileManager";


export default function ChangeAccessModifierDialog({
  openChangeAccessModifierDialog,
  handleCloseChangeAccessModifierDialog,
  editFileId,
  currentAccessModifier,
  isFolder,
}) {

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });

  const [accessModifier, setAccessModifier] = useState(currentAccessModifier);

  const handleChange = (event) => {
    setAccessModifier(event.target.value);
  };



  return (
    <>
      <Dialog
        open={openChangeAccessModifierDialog}
        onClose={() => {
          setAccessModifier('');
          handleCloseChangeAccessModifierDialog();
        }}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            await handleChangeAccess({ content_id: editFileId, access_modifier: accessModifier, handleCloseChangeAccessModifierDialog });
          },
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{t("change_access_modifier")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("pick_new_access_modifier")}
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={currentAccessModifier}
              label="access-modifier"
              onChange={handleChange}
            >
              {/* {!isFolder && (
                <MenuItem value={"public"}>{t("public_access")}</MenuItem>
              )} */}
              <MenuItem value={"protected"}>{t("protected_access")}</MenuItem>
              <MenuItem value={"private"}>{t("private_access")}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            pb: '2em',
            mr: '1em'

          }}
        >
          <Button onClick={() => {
            setAccessModifier('')
            handleCloseChangeAccessModifierDialog();
          }}>{t("cancel")}</Button>
          <Button
            type="submit"
            style={{
              backgroundColor: '#186df7',
              color: '#ffffff'
            }}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}