import React from "react";
import "./Unathorized.scss";
// import {Link, Navigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Unauthorized() {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Unauthorized",
  });

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="page_401">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg401">
                  <h1 className="text-center ">401</h1>
                </div>

                <div className="contant_box_401">
                  <h3 className="h2">{t("look_like_you_re_lost")}</h3>

                  <p>{t("the_page_you_are_looking_for_not_avaible")}</p>

                  <div onClick={handleBack} className="link_401">
                    {t("go_to_home")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
