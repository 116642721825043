import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Button } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import AlertDialog from "../../../../components/Dialog/Alerts";
import { CONNECTION_STATUS } from "../../../../constants/connectLMS";
import { useConnectLMSConext } from "../Context";
import ConnectionSettingsModal from "../modal/ConnectionSettingsModal";
import "../ConnectLMSPage.scss";


const ConnectionSettingsButton = memo(() => {
    // const { t } = useTranslation("translation", {
    //     keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
    //     });

    return (
    <span
        className={'connection-settings-btn'}
    >

        <ConnectionSettingsModal/>

    </span>
    );

});

export default ConnectionSettingsButton;
