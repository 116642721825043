
import { useTranslation } from "react-i18next";
import {
    DataGrid,
    GridToolbar,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Button,
    Typography,
    Paper,
    Stack,
    TextField,
    MenuItem,
    Select,
    Modal,
    useTheme,
    useMediaQuery,
  } from "@mui/material";
import "../BodyManage.scss";


export default function DetailPanelContent({ row }) {
    const { t } = useTranslation("translation", {
      keyPrefix: "layouts.Body.BodyManageContent",
    });
    const apiRef = useGridApiContext();
    const {
      control,
      handleSubmit,
      formState: { isValid },
    } = useForm({
      defaultValues: row,
      mode: "onChange",
    });
  
    const onSubmit = (data) => {
      apiRef.current.updateRows([data]);
      apiRef.current.toggleDetailPanel(row.id);
    };
  
    return (
      <Stack
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
          <Stack
            component="form"
            justifyContent="space-between"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ height: 1, maxWidth: 400, gap: 3 }}
          >
            <Typography variant="h6">{`Edit ID #${row.id}`}</Typography>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field, fieldState: { invalid } }) => (
                <TextField
                  label="Name"
                  size="small"
                  error={invalid}
                  required
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({ field, fieldState: { invalid } }) => (
                <Select
                  label="Role"
                  size="small"
                  error={invalid}
                  required
                  fullWidth
                  {...field}
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                  <MenuItem value="Super user">Super user</MenuItem>
                  <MenuItem value="Author">Author</MenuItem>
                </Select>
              )}
            />
            <div>
              <Button
                type="submit"
                variant="outlined"
                size="small"
                disabled={!isValid}
              >
                {t("save")}
              </Button>
            </div>
          </Stack>
        </Paper>
      </Stack>
    );
  }
  