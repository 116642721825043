import React from "react";
import LoginForm from "../../layouts/LoginForm/LoginForm";
import "./LoginPage.scss";

export default function LoginPage() {
  return (
    <div className="loginpage_container">
      <LoginForm />
    </div>
  );
}
