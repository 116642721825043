import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Button } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import AlertDialog from "../../../../components/Dialog/Alerts";
import { CONNECTION_STATUS } from "../../../../constants/connectLMS";
import { useConnectLMSConext } from "../Context";
import "../ConnectLMSPage.scss";
import { AlignVerticalBottom } from "@mui/icons-material";


const handleOnClick= ()=>{
    const confirmation = window.confirm("Are you sure you wish to regenerate keys?");
    console.log(confirmation);
}


const RegenerateKeysButton = () => {
    const { t } = useTranslation("translation", {
        keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
      });

    return (
    <span
        className={'regenerate-keys-btn'}
    >
        <Button
        variant="outlined"
        size="small"
        sx={{textTransform:"none"}}
        onClick={handleOnClick}
        >
        {t("connectionItem.regenerate_keys")}
        </Button>

    </span>
    );


};

export default RegenerateKeysButton;
