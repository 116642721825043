import React,{useCallback} from "react";
import parseJwt from "../../../funtions/parseJwt";
import TimeVN from "../../../funtions/timeVN";
import handleErrorFunction from "../../../funtions/handleErrorFunction";
import { useTranslation } from "react-i18next";
import {
  changePassword,
  connectLMSApi,
  deleteContent,
  getContentClient,
  getInfoAccount,
  folderManagementApi
} from "../../../api";

import { hasPermission } from "./userManager";

import { toast } from "react-toastify";
import { t } from "i18next";

export const handleChangeLocation = async ({contentId,newParentId,handleCloseMoveDialog}) =>{
  if (!newParentId){
    return;
  }

  const body = {
    "parent":newParentId === "0"?null:newParentId
  }

  try{
    await folderManagementApi.changeLocation(contentId,body);
  }
  catch (error){
    toast.error("Error changing location");
  }
  

  handleCloseMoveDialog();

}

export const handleCloneContent = async ({mongoId},navigate) =>{
  if (!mongoId){
    return;
  }

  try{
    const res = await folderManagementApi.cloneContent({mongoId});
    navigate(`/content/edit?id=${res.data.contentId}`);  
  } catch(error){
    toast.error("Error cloning content");
  }


}

export const handleChangeAccessModifier = async ({fileId,title, isFolder,setEditFileName,setEditFileId,handleOpenChangeAccessModifierDialog}) =>{
  setEditFileName(title);
  setEditFileId(fileId);
  handleOpenChangeAccessModifierDialog();
}

export const handleChangeOwner = ({fileId,title,isFolder,setEditFileName,setEditFileId,handleOpenChangeOwnerDialog})=>{
  setEditFileName(title);
  setEditFileId(fileId);
  handleOpenChangeOwnerDialog();
}

export const handleMoveFile = ({fileId,title,isFolder,setMoveFileName,setMoveFileId,handleOpenMoveDialog}) =>{
  setMoveFileName(title);
  setMoveFileId(fileId);
  handleOpenMoveDialog();
}

export const handleAddCollaborator = ({fileId,title,isFolder,setAddCollaboratorFileName,setAddCollaboratorFileId,handleOpenAddCollaboratorDialog}) =>{
  setAddCollaboratorFileName(title);
  setAddCollaboratorFileId(fileId);
  handleOpenAddCollaboratorDialog();
}

export const handleEditFile = ({fileId,title,isFolder,setEditFileName,setEditFileId,handleOpenEditingDialog}) =>{
  setEditFileName(title);
  setEditFileId(fileId);
  handleOpenEditingDialog();
};

// Send request to change folder's name
export const handleChangeFolderName = async ({directory_id,newName,handleCloseEditingDialog,navigate}) => {
  const body = {
    "name":newName
  }

  try{
    await folderManagementApi.renameDirectory(directory_id,body);
  } catch(error){
    toast.error("Error changing folder name");
  }
  handleCloseEditingDialog();
  // navigate(`/content/my-content/${directory_id}`);   

};

export const handleChangeAccess = async ({content_id, access_modifier, handleCloseChangeAccessModifierDialog}) => {
  const body = {
    "access_modifier": access_modifier
  };

  try{
    await folderManagementApi.changeAccessModifier(content_id,body);
  } catch(error){
    toast.error("Error changing access modifier");
  }
  handleCloseChangeAccessModifierDialog();
}

export const handleInsert = async ({row}) => {
  try {
    const response = await connectLMSApi.selectContent(row.mongoId);
    document.open();
    document.write(response.data);
    document.close();
  } catch(error){
    toast.error("Error inserting content");
  }
  
};

export const handleDeleteFile = ({fileId, title, isFolder, setDeleteFileName,setDeleteFileId,handleOpenDeletingDialog}) => {
  setDeleteFileName(title);
  setDeleteFileId(fileId);
  handleOpenDeletingDialog();
};

export const handlePermanentlyDeleteFile = ({fileId, title, isFolder, setDeletePermanentlyDirectoryName,setDeletePermanentlyDirectoryId,handleOpenPermanentlyDeletingDialog}) => {
  setDeletePermanentlyDirectoryName(title);
  setDeletePermanentlyDirectoryId(fileId);
  handleOpenPermanentlyDeletingDialog();
};

export const handleRestoreFile = async ({fileId})=>{
  try{
    await folderManagementApi.restoreDirectory(fileId);
    window.location.reload();
  } catch (error){
    toast.error("Error restoring file");
  }
}

export const handleDetail = ({row,handleOpenModalDetailRows,setSelectedRow}) => {
  handleOpenModalDetailRows();
  setSelectedRow({
    title: row.title,
    owner: row.owner,
    lastModified: row.lastModified,
    lastModifiedBy: row.lastModifiedBy,
    feedback: row.feedback,
    published: row.published,
  });
};

export const loadFiles = async ({accountID,currentDirectory,id,v,setRows,setLoading,navigate},
  { allContent, myContent, collabContent,trashContent }) =>{
  try {

    let subdirectories = null;
    
    // If user is in the main page where currentDirectory = undefined
    if(!currentDirectory){

      // If user is at my-content route. Send request with scope = mine
      if (myContent){ 
        subdirectories = await folderManagementApi.getSubDirectoriesScopeMine();
      } 
      // If user is at all-content route. Send request with scope = all 
      else if (allContent){
        subdirectories = await folderManagementApi.getSubDirectoriesScopeAll();
      }
      // If user is at share-me route. Send request with scope = shared
      else if (collabContent){
        subdirectories = await folderManagementApi.getSubDirectoriesScopeShared();
      } 
      // If user is at trahsed route. Send request with scope = trashed
      else if (trashContent){
        subdirectories = await folderManagementApi.getSubDirectoriesScopeTrashed();
      }
    } 
    // If user navigate to a specific directory (currentDirectory = something)
    else{
      subdirectories = await folderManagementApi.getSubDirectoriesFromDirectoryId(currentDirectory);
    }


    
    if (!accountID) return;
        //Lấy ngôn ngữ hiện tại của tổ chức
        const responseLanguage = await getInfoAccount(accountID);
        const infoAccountData = parseJwt(responseLanguage.data.jwt);
        const language = infoAccountData.defaultLocale;
    

      //Extract all sub-directories of current directory
      const contentType2 = subdirectories.data.data;

      const convertedArray = contentType2.map((obj) => {

        const {
          isAbleToChangeLocation,
          isAbleToEdit,
          isAbleToClone,
          isAbleToDelete,
          isAbleToAddCollaborator,
          isAbleToAssignOwner,
          isAbleToChangeAccessModifier
        } = hasPermission(obj);

        return {
          id: obj.id,
          title: obj.name,
          owner: obj.owner.fullName,
          ownerId: obj.owner.id,
          mongoId: obj.mongoId,
          access_modifier: obj.access_modifier,
          is_directory:obj.is_directory,
          contents:obj.contents,
          collaborators:obj.collaborators,
          lastModified:
            language === "vi" ? TimeVN(obj.updatedAt) : obj.updateAt,
          createdAt: obj.createdAt,
          parent: obj.parent?.id,
          isAbleToChangeLocation,
          isAbleToEdit,
          isAbleToClone,
          isAbleToDelete,
          isAbleToAddCollaborator,
          isAbleToAssignOwner,
          isAbleToChangeAccessModifier,
          hasNoPermission: !isAbleToChangeLocation && 
                          !isAbleToClone && 
                          !isAbleToDelete && 
                          !isAbleToAddCollaborator && 
                          !isAbleToAssignOwner && 
                          !isAbleToChangeAccessModifier
        };
      });
      setRows(convertedArray);
      setLoading(true);
  } catch (error) {
    if (error.response.status == 404){
      toast.error("Content not found");
    } else {
      toast.error("Error loading files");
    }

  }
}


export const loadRootFiles = async ({accountID,id,v},
  { allContent, myContent, collabContent,trashContent }) =>{
  try{
    let subdirectories = null;
    
      subdirectories = await folderManagementApi.getSubDirectoriesScopeAll();


    
    if (!accountID) return;
        //Lấy ngôn ngữ hiện tại của tổ chức
        const responseLanguage = await getInfoAccount(accountID);
        const infoAccountData = parseJwt(responseLanguage.data.jwt);
        const language = infoAccountData.defaultLocale;
    

      //Extract all sub-directories of current directory
      const contentType2 = subdirectories.data.data;

      const convertedArray = contentType2.map((obj) => {

        const {
          isAbleToChangeLocation,
          isAbleToEdit,
          isAbleToClone,
          isAbleToDelete,
          isAbleToAddCollaborator,
          isAbleToAssignOwner,
          isAbleToChangeAccessModifier
        } = hasPermission(obj);



        return {
          id: obj.id,
          title: obj.name,
          owner: obj.owner.fullName,
          ownerId: obj.owner.id,
          mongoId: obj.mongoId,
          access_modifier: obj.access_modifier,
          is_directory:obj.is_directory,
          contents:obj.contents,
          collaborators:obj.collaborators,
          lastModified:
            language === "vi" ? TimeVN(obj.updatedAt) : obj.updateAt,
          createdAt: obj.createdAt,
          isAbleToChangeLocation,
          isAbleToEdit,
          isAbleToClone,
          isAbleToDelete,
          isAbleToAddCollaborator,
          isAbleToAssignOwner,
          isAbleToChangeAccessModifier,
          hasNoPermission: !isAbleToChangeLocation && 
                          !isAbleToClone && 
                          !isAbleToDelete && 
                          !isAbleToAddCollaborator && 
                          !isAbleToAssignOwner && 
                          !isAbleToChangeAccessModifier
        };
      });
      return convertedArray;
  } catch (error) {
    toast.error("Error loading files");
  }
}



export const loadTreeItem = async ({accountID,currentDirectory,id,v},
  { allContent, myContent, collabContent }) =>{
  try {

  let subdirectories = null;
  
  // If user is in the main page where currentDirectory = undefined
  if(!currentDirectory){

    // If user is at my-content route. Send request with scope = mine
    if (myContent){ 
      subdirectories = await folderManagementApi.getSubDirectoriesScopeMine();
    } 
    // If user is at all-content route. Send request with scope = all 
    else if (allContent){
      subdirectories = await folderManagementApi.getSubDirectoriesScopeAll();
    }
    // If user is at share-me route. Send request with scope = shared
    else if (collabContent){
      subdirectories = await folderManagementApi.getSubDirectoriesScopeShared();
    } 
  } 
  // If user navigate to a specific directory (currentDirectory = something)
  else{
    subdirectories = await folderManagementApi.getSubDirectoriesFromDirectoryId(currentDirectory);
  }


  
  if (!accountID) return;
      //Lấy ngôn ngữ hiện tại của tổ chức
      const responseLanguage = await getInfoAccount(accountID);
      const infoAccountData = parseJwt(responseLanguage.data.jwt);
      const language = infoAccountData.defaultLocale;
  

    //Extract all sub-directories of current directory
    const contentType2 = subdirectories.data.data;

    const convertedArray = contentType2.map((obj) => {

      const {
        isAbleToChangeLocation,
        isAbleToEdit,
        isAbleToClone,
        isAbleToDelete,
        isAbleToAddCollaborator,
        isAbleToAssignOwner,
        isAbleToChangeAccessModifier
      } = hasPermission(obj);



      return {
        id: obj.id,
        title: obj.name,
        owner: obj.owner.fullName,
        ownerId: obj.owner.id,
        mongoId: obj.mongoId,
        access_modifier: obj.access_modifier,
        is_directory:obj.is_directory,
        contents:obj.contents,
        collaborators:obj.collaborators,
        lastModified:
          language === "vi" ? TimeVN(obj.updatedAt) : obj.updateAt,
        createdAt: obj.createdAt,
        isAbleToChangeLocation,
        isAbleToEdit,
        isAbleToClone,
        isAbleToDelete,
        isAbleToAddCollaborator,
        isAbleToAssignOwner,
        isAbleToChangeAccessModifier,
        hasNoPermission: !isAbleToChangeLocation && 
                         !isAbleToClone && 
                         !isAbleToDelete && 
                         !isAbleToAddCollaborator && 
                         !isAbleToAssignOwner && 
                         !isAbleToChangeAccessModifier
      };
    });

    // setContents(convertedArray);
    return convertedArray;

  } catch (error) {
    // toast.error(handleErrorFunction(error, v));
    toast.error("Error loading files");
  }
}

export const handleRowClick = async ({row,setCurrentDirectory,navigate}) =>{
  if (row.row.is_directory){
    setCurrentDirectory(row.row.id);
    navigate(`/content/my-content/${row.row.id}`);    
    window.location.reload();
  } else{
    navigate(`/content/view?id=${row.row.mongoId}`);    
  }


}

export const handleConfirmedDeleteFile = async ({deleteFileId,setOpenDeletingDialog}) =>{
  if (deleteFileId === -1){
    return;
  }
  try {
    await folderManagementApi.deleteDirectory(deleteFileId);
  } catch(error){
    toast.error("Error deleting file");
  }
  setOpenDeletingDialog(false);
  window.location.reload();
}

export const handleConfirmedPermanentlyDeleteFile = async ({deletePermanentlyDirectoryId,setOpenPermanentlyDeletingDialog}) => {
  if (deletePermanentlyDirectoryId === -1){
    return;
  }
  try{
    await folderManagementApi.deletePermanentlyDirectory(deletePermanentlyDirectoryId);
  } catch(error){
    toast.error("Error permanently deleting file");
  }
  setOpenPermanentlyDeletingDialog(false);
  window.location.reload();
};
