import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import AlertDialog from "../../../../components/Dialog/Alerts";
import { useConnectLMSConext } from "../Context";

const DeleteButton = memo(({ id }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const { reloadPage } = useConnectLMSConext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDelete = useCallback(async () => {
    if (id) {
      try {
        const response = await connectLMSApi.deleteConnectionLMS(id);
        if (response.status === 200) {
          toast.success(t("delete.successText"));
          reloadPage();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [id, reloadPage, t]);

  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={handleOnClick}
      >
        {t("delete.title")}
      </Button>
      <AlertDialog
        open={isOpen}
        toggle={handleOnClick}
        handleSubmit={handleDelete}
        title={t("delete.title")}
        desc={t("delete.desc")}
      />
    </>
  );
});

export default DeleteButton;
