import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleAssignOwner } from "../function/userManager";


export default function ChangeOwnerDialog({
  openChangeOwnerDialog,
  handleCloseChangeOwnerDialog,
  editFileId,
  users
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Body.BodyManageContent",
  });

  const [owner, setOwner] = useState();

  const handleChange = (event) => {
    setOwner(event.target.value);
  };

  return (
    <>
      <Dialog
        open={openChangeOwnerDialog}
        onClose={() => {
          setOwner('');
          handleCloseChangeOwnerDialog();
        }}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            await handleAssignOwner({ contentId: editFileId, newOwnerId: owner, handleCloseChangeOwnerDialog });
          },
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{t("assign_new_owner")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("pick_new_owner")}
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={owner}
              label="owner"
              onChange={handleChange}
            >
              {users && users.map((user) => (
                <MenuItem value={user.id}>{user.fullName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            pb: '2em',
            mr: '1em'

          }}
        >
          <Button onClick={() => {
            setOwner('')
            handleCloseChangeOwnerDialog();
          }}>{t("cancel")}</Button>
          <Button
            type="submit"
            style={{
              backgroundColor: '#186df7',
              color: '#ffffff'
            }}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}