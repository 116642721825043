import React from "react";
import { AlertTitle, Alert } from "@mui/material";
import "./Dropdown.scss";
import { useTranslation } from "react-i18next";

export default function Dropdown({
  label,
  listItem,
  info,
  nameInfo,
  value,
  name,
  onChange,
  disabled,
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.Dropdown",
  });
  return (
    <>
      <div className="input_component">
        <label htmlFor="dropdown" className="label">
          {label}
        </label>
        <select
          id="dropdown"
          className="input"
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
        >
          {listItem.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {info &&
        (value === "SuperUser" ? (
          <Alert severity="info" style={{ color: "#31708f" }}>
            <AlertTitle>{t("warning")}</AlertTitle>
            {t("as_a_superuser")}, {nameInfo} {t("will_be")}
          </Alert>
        ) : value === "Administrator" ? (
          <Alert severity="info" style={{ color: "#31708f" }}>
            <AlertTitle>{t("warning")}</AlertTitle>
            {t("as_an_admin")}, {nameInfo} {t("will_be2")}
          </Alert>
        ) : (
          <></>
        ))}
    </>
  );
}
