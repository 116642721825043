import React from "react";
import VerifyEmail from "../../layouts/VerifyEmail/VerifyEmail";
import { useTranslation } from "react-i18next";

export default function VerifyEmailForgot() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.VerifyEmailPage.VerifyEmailForgot",
  });
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  return (
    <VerifyEmail
      title={t("title")}
      subtitle={t("subtitle")}
      text1={t("text1")}
      text2={t("text2")}
      text3={t("text3")}
      email={email}
    />
  );
}
