import React, { memo } from "react";
import { CONNECTION_STATUS } from "../../../../constants/connectLMS";
import "../ConnectLMSPage.scss";
import ConnectionSettingsButton from "../button/ConnectionSettingsButton";
import RegenerateKeysButton from "../button/RegenerateKeysButton";


const LMSItemFunctions = memo(({ id, status,version }) => {
  return (
    <div className="functions-wrapper">
        <ConnectionSettingsButton/>
        <RegenerateKeysButton/>
    </div>
  );
});

export default LMSItemFunctions;
