import MuiPagination from "@mui/material/Pagination";
import {
    DataGrid,
    GridToolbar,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
  } from "@mui/x-data-grid";

  
export default function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }