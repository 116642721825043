import React, { memo } from "react";
import { CONNECTION_STATUS } from "../../../../constants/connectLMS";
import "../ConnectLMSPage.scss";
import DeleteButton from "../button/DeleteButton";
import DisableButton from "../button/DisableButton";
import EnableButton from "../button/EnableButton";

const LMSItemActions = memo(({ id, status,version }) => {
  return (
    <div className="actions">
      {version === 'ltiv1p1' &&(
        <>
          {status === CONNECTION_STATUS.ENABLED ? (
            <DisableButton id={id} />
          ) : (
            <EnableButton id={id} />
          )}
        </>
      )}
      <DeleteButton id={id} />
    </div>
  );
});

export default LMSItemActions;
