import {get, post, put, remove} from "./axiosClient";


// Fetch a directory by id and return all of its sub directories
const getSubDirectoriesFromDirectoryId = async (id) => {    
    return get(`/contents/${id}`);
}

// Fetch all subdirectories with scope = all
const getSubDirectoriesScopeAll = async () => {    
    return get(`/contents?scope=all`);
}

// Fetch all subdirectories with scope = mine
const getSubDirectoriesScopeMine = async () => {    
    return get(`/contents?scope=mine`);
}

// Fetch all subdirectories with scope = shared
const getSubDirectoriesScopeShared = async () => {    
    return get(`/contents?scope=shared`);
}

// Fetch all subdirectories with scope = shared
const getSubDirectoriesScopeTrashed = async () => {    
    return get(`/contents?scope=trashed`);
}

// Get all user's available contents
const getContents = async (accountId)=>{
    return get(`/account/${accountId}/contents`);
}

// Create new directory
const createDirectory = async (body) =>{
    return post(`/contents/directories`,body);
}

// Delete folder
// Change workflowState to 'trashed' instead of removing them from database
const deleteDirectory = async (id) => {
    return remove(`/contents/${id}`);
}

// Update folder's name
const renameDirectory = async (id,body) => {
    return put(`/contents/${id}/name`,body);
}

const restoreDirectory = async (id) => {
    return put(`/contents/${id}/trashed-contents`);
}

const deletePermanentlyDirectory = async (id) => {
    return remove(`/contents/${id}/permanent`);
}

const getCollaborators = async (id) =>{
    return get(`/contents/${id}/collaborators`);
}

const getOwner = async (id) =>{
    return get(`/contents/${id}/owners`);
}

const addCollaborator = async (id,body) => {
    return put(`/contents/${id}/collaborators`,body);
}

const changeOwner = async (id,body) => {
    return put(`/contents/${id}/owners`,body);
}

const changeLocation = async (id,body) => {
    return put(`/contents/${id}/parents`,body);
}

const changeAccessModifier = async (id,body) => {
    return put(`/contents/${id}/access_modifier`, body);
}

const removeCollaborator = async (content_id,collaborator_id) => {
    return remove (`/contents/${content_id}/collaborators/${collaborator_id}`);
}

const cloneContent = async ({mongoId}) =>{
    return post(`/h5p/clone/${mongoId}`);
}


export const folderManagementApi ={
    getSubDirectoriesFromDirectoryId,
    getSubDirectoriesScopeAll,
    getSubDirectoriesScopeMine,
    getSubDirectoriesScopeShared,
    getSubDirectoriesScopeTrashed,
    getContents,
    createDirectory,
    deleteDirectory,
    renameDirectory,
    restoreDirectory,
    deletePermanentlyDirectory,
    getCollaborators,
    getOwner,
    addCollaborator,
    changeOwner,
    changeLocation,
    removeCollaborator,
    changeAccessModifier,
    cloneContent
};