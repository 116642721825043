import { AddCircleOutline, Menu } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import CohotaLogo from "../../../components/CohotaLogo/CohotaLogo";
import Menuu from "../../../components/Menu/Menu";
import parseJwt from "../../../funtions/parseJwt";
import { common } from "../../../utils/common";
import "./HeaderPage.scss";

export default function HeaderPage() {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Header.HeaderPage",
  });
  const [stateMenu, setStateMenu] = useState("false");
  const [activeItem, setActiveItem] = useState(t(""));
  const [roleId, setRoleId] = useState("");
  const currentParentDirectory = window.location.pathname.split('/')[3] ? window.location.pathname.split('/')[3] : "";
  const handleActive = (item) => {
    setActiveItem(item);
  };

  const handleToggleMenu = () => {
    setStateMenu(!stateMenu);
  };

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    setRoleId(json.roleId);
  }, []);

  return (
    <div className="headerpage_layout">
      <div className="headerpage_item">
        <CohotaLogo scale="0.7" />
      </div>
      <div className="headerpage_item">
        <div className="headerpage_itemchild">
          <NavLink
            className={`link ${
              activeItem === t("manage_content") ? "active" : ""
            } `}
            to="/content/my-content"
            onClick={() => handleActive(t("manage_content"))}
          >
            <div>{t("manage_content")}</div>
          </NavLink>
          {roleId === 7 ? (
            <></>
          ) : (
            <NavLink
              className={`link ${
                activeItem === t("manage_org") ? "active" : ""
              } `}
              to="/organization/users"
              onClick={() => handleActive(t("manage_org"))}
            >
              <div>{t("manage_org")}</div>
            </NavLink>
          )}
          <Menuu />
        </div>
        <div className="headerpage_item-responsive">
          <Menu onClick={handleToggleMenu} />

          {stateMenu === true && (
            <div className="menu-responsive">
              <NavLink className="link" to="/content/my-content">
                <div>{t("manage_content")}</div>
              </NavLink>
              <NavLink className="link" to="/organization/users">
                <div>{t("manage_org")}</div>
              </NavLink>
              <div>
                <Menuu />
              </div>
            </div>
          )}
        </div>

        {roleId === 7 ? (
          <></>
        ) : (
          <Link to={`/content/new?parent=${currentParentDirectory}`}>
            <Button
              variant="contained"
              startIcon={<AddCircleOutline />}
              size="large"
              className="button headerpage_button"
            >
              {t("add_content")}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}
