import { get, post, put, remove } from "./axiosClient";

const getLMSs = async () => {
  return get(`/lmss`);
};

const createLMS = async (body) => {
  return post(`/lmss`, body);
};

const createConnectionLMS = async (body) => {
  return post(`/connections`, body);
};

const updateConnectionLMS = async (id, body) => {
  return put(`/connections/${id}`, body);
};

const deleteConnectionLMS = async (id) => {
  return remove(`/connections/${id}`);
};

const getConnectionLMSs = async (params) => {
  return get(`/connections`, params);
};

const insertContent = async (contentId) => {
  // return post(`/content/${contentId}`);
  return post(`/lti`);
};

const selectContent = async (contentId, body) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return post(`lti/contents/${contentId}/select`, body, { headers });
};

export const connectLMSApi = {
  getLMSs,
  createLMS,
  getConnectionLMSs,
  createConnectionLMS,
  updateConnectionLMS,
  deleteConnectionLMS,
  insertContent,
  selectContent,
};
