import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "../SubHeader.scss";
import { handleCreateFolder } from "../function/folderManager";
import { BASE_ROOT_DIRECTORY_ID } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";


export default function NewFolderForm({ openModalNewFolder, handleCloseModalNewFolder }) {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.SubHeader",
  });
  // Get account ID
  const {
    userInfo: { accountID, id },
  } = useAppContext();


  const { directory_id } = useParams();
  const [folderName, setFolderName] = useState("");

  // const [currentDirectory,setCurrentDirectory] = useState(directory_id || BASE_ROOT_DIRECTORY_ID)

  return (
    <>
      <Dialog
        open={openModalNewFolder}
        onClose={() => {
          setFolderName('');
          handleCloseModalNewFolder();
        }}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            await handleCreateFolder({ directory_id, folderName, accountID, handleCloseModalNewFolder, navigate });
          },
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{t("new_folder_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("new_folder_name_input")}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="normal"
            type="text"
            fullWidth
            variant="outlined"
            value={folderName}
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            pb: '2em',
            mr: '1em'

          }}
        >
          <Button onClick={() => {
            setFolderName('');
            handleCloseModalNewFolder();
          }}>{t("cancel")}</Button>
          <Button
            type="submit"
            style={{
              backgroundColor: '#186df7',
              color: '#ffffff'
            }}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// export default function NewFolderForm ({openModalNewFolder,handleCloseModalNewFolder}){

//     return (
//         <Modal
//         open={openModalNewFolder}
//         onClose={handleCloseModalNewFolder}
//       >
//         <Box
//           className="Box-Modal"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             gap: 20,
//           }}
//         >
//             <div>Hello world</div>
//           {/* COMING SOON */}
//           {/* <Input
//             label={t("enter_folder_name")}
//             name="name"
//             onChange={handleNewFolderChange}
//           />
//           <Input
//             label={t("enter_context_type")}
//             name="contextType"
//             onChange={handleNewFolderChange}
//           />
//           <Input
//             name="contextId"
//             label={t("enter_context_id")}
//             onChange={handleNewFolderChange}
//           />
//           <Box sx={{ display: "flex", gap: 2 }}>
//             <Button
//               variant="contained"
//               size="large"
//               className="button"
//               onClick={handleCreateNewFolder}
//             >
//               {t("create")}
//             </Button>
//             <Button
//               size="large"
//               className="button"
//               style={{ color: "#3c4859" }}
//               onClick={handleCloseModalNewFolder}
//             >
//               {t("cancel")}
//             </Button>
//           </Box> */}
//         </Box>
//       </Modal>

//     )
// }