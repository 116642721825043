import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../ConnectLMSPage.scss";
import AddConnectLMS from "../components/AddConnectLMS";

const style = {
  // position: "absolute",
  position:"relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // boxShadow: "0 0 22px 0 rgba(0,0,0,.15)",
  boxShadow: 24,
  p: 4,
  overflowY:'auto',
  overflow:'hidden',

  "@media screen and (max-width:767.98px)":{
    width:'auto',
  } 
};
const AddConnectLMSModal = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(!isOpen);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<LinkOutlinedIcon />}
        size="large"
        className="button"
        onClick={handleOpenModal}
      >
        {t("add.title")}
      </Button>
      <Modal
        open={isOpen}
        onClose={handleOpenModal}
        className="add-lms-connection-modal"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {t("add.title")}
            <CloseSharpIcon onClick={handleOpenModal} />
          </Typography>
          <AddConnectLMS isModal onCloseModal={handleOpenModal} />
          {/* <Box className="save-btn">
          <Button variant="contained" onClick={handleSubmit}>
            {t("save")}
          </Button>
        </Box> */}
        </Box>
      </Modal>
    </>
  );
});

export default AddConnectLMSModal;
