import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../../../../components/Button/CopyToClipboardButton";
import "../ConnectLMSPage.scss";

const ImportantInfo = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });
  const [origin] = useState(window.location.origin);

  return (
    <div className="important-info-wrapper">
      <div className="header">{t("importantInfo.title")}</div>
      <p>{t("importantInfo.desc")}</p>
      <div className="text">{t("importantInfo.v1_1.forTitle")}</div>
      <ul className="unordered-lst">
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_1.text1")}
          <CopyToClipboardButton
            value={`${origin}/api/v1/lti`}
            className="link"
          />
        </li>
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_1.text2")}
        </li>
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_1.text3")}
        </li>
      </ul>
      <div className="text">{t("importantInfo.v1_3.forTitle")}</div>
      <ul className="unordered-lst">
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_3.text1")}
          <CopyToClipboardButton
            value={`${origin}/lti/login`}
            className="link"
          />
        </li>
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_3.text3")}
          <CopyToClipboardButton
            value={`${origin}/lti/launch`}
            className="link"
          />
        </li>
        <li className="list-item">
          <span className="circle"></span>
          {t("importantInfo.v1_3.text3")}
        </li>
      </ul>
    </div>
  );
});

export default ImportantInfo;
