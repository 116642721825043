import React, { useState } from "react";
import Input from "../../components/Input/Input";
import { Button, Stack } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material"
import { forgotPassword } from "../../api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Password.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();

  //Đa ngôn ngữ
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.PasswordPage.ForgotPasswordPage",
  });

  const [email, setEmail] = useState ('')

 
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleForgotPassword = async () => {
      try {
        const respond = await forgotPassword({
          email: email,
        });

        if (respond) {
          navigate(`/verify-email-forgot?email=${email}`);
        }
      } catch (error) {}
    
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleForgotPassword();
    }
  };

  return (
    <div className="password_page">
      <Stack spacing={2} className="password_layout">
       
        <div className="title-back">
          <ArrowBackOutlined
            className="arrowbackoutlined-icon"
            onClick={handleBack}
          />
          <div className="title-text">{t("forgot_password")}</div>
        </div>

        <Input
          label={t("input_email")}
          placeholder={t("input_email")}
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
        />
        
        <Button variant="contained" onClick={handleForgotPassword}>
          {t("submit")}
        </Button>
      </Stack>
    </div>
  );
}
