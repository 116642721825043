import React, { useState, useEffect } from "react";
import Input from "../../components/Input/Input";
import { Button, Stack, Typography } from "@mui/material";
import { resetPassword } from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Password.scss";

export default function ResetPassword() {
  const navigate = useNavigate();

  //Đa ngôn ngữ
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.PasswordPage.ResetPassword",
  });

  //Mật khẩu (enterPassword và confirmPassword)
  const [password, setPassword] = useState({
    enterPassword: "",
    confirmPassword: "",
  });
  const handleNewPasswordChange = (event) => {
    const { name, value } = event.target;
    setPassword({
      ...password,
      [name]: value,
    });
  };

  //Lấy pseudonym từ đường link truy cập bên email
  const [pseudonym, setPseudonym] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pseudonymValue = urlParams.get("resetpassword");
    setPseudonym(pseudonymValue);
  }, []);

  //Gửi mật khẩu và pseudonym của account đó
  const handleResetPassword = async () => {
    try {
      const respond = await resetPassword(password, pseudonym);
      if (respond.status === 200) {
        toast.success(t("password_reset_successful"));
        navigate("/login");
      }
    } catch (error) {
      try {
        if (error.response.data.error.status === 400) {
          toast.error(t("password_does_not_match"));
          setCheckPasswordMatch(false);
        }
      } catch (error) {
        toast.error(t("connection_error"));
      }
    }
  };

  const [checkPasswordMatch, setCheckPasswordMatch] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleResetPassword();
    }
  };

  return (
    <div className="password_page">
      <Stack spacing={2} className="password_layout">
        <Typography variant="h6" gutterBottom className="title">
          {t("reset_password")}
        </Typography>

        <Input
          label={t("enter_password")}
          placeholder={t("enter_password")}
          type="password"
          id="enterPassword"
          name="enterPassword"
          value={password.enterPassword}
          onChange={handleNewPasswordChange}
          onKeyDown={handleKeyDown}
        />
        <Stack className="input-text-error">
          <Input
            label={t("confirm_password")}
            placeholder={t("confirm_password")}
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={password.confirmPassword}
            onChange={handleNewPasswordChange}
            className={checkPasswordMatch === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />
          <div
            className={checkPasswordMatch === false ? "error-text" : "hidden"}
          >
            {t("password_does_not_match")}
          </div>
        </Stack>
        <Button variant="contained" onClick={handleResetPassword}>
          {t("reset_password")}
        </Button>
      </Stack>
    </div>
  );
}
