import {
    getAllUser,
    folderManagementApi
  } from "../../../api";

import parseJwt from "../../../funtions/parseJwt";
import { common } from "../../../utils/common";
import { toast } from "react-toastify";




export const fetchCollaborators = async (id)=>{
    try{
        return (await folderManagementApi.getCollaborators(id)).data.data;
    }   catch(error){
        toast.error("Error fetching collaborators");
    }
}

export const fetchOwner = async (id) =>{
    try{
        return (await folderManagementApi.getOwner(id)).data.data
    }   catch(error){
        toast.error("Error fetching owner");
    }
}

export const fetchUsers = async () =>{
    try{
        const JWT_loginToken = common.getToken();
        const info = parseJwt(JWT_loginToken);
        const res = (await getAllUser(info.accountID)).data.jwt;
        return parseJwt(res).clients.filter(client => client.role.name !== 'Learner');
    }   catch(error){
        toast.error("Error fetching users");
    }
}

export const hasPermission = (obj)=>{
    const JWT_loginToken = common.getToken();
    const info = parseJwt(JWT_loginToken);

    const isUserAdmin = info.roleName === "Administrator";
    const isUserSuperUser = info.roleName === "Super User";
    const isUserOwner = info.id == obj.owner.id;
    const isUserCollaborator = !isNotCollaborator(info.id, obj.collaborators);
    const isOuterMostContent = obj.parent === null && !obj.is_directory;


    // Change location: admin, superuser, owner
    const isAbleToChangeLocation = (isUserAdmin || isUserSuperUser || 
                                    (isUserOwner && obj.access_modifier === "private"))&&
                                    !(obj.access_modifier==='protected' || obj.access_modifier==='public');
    // Edit content: admin, superuser, owner, collaborator
    const isAbleToEdit = isUserAdmin || isUserSuperUser || isUserOwner || isUserCollaborator;
    // Clone content: as long as content is content type
    const isAbleToClone = !obj.is_directory;
    // Delete content: admin, superuser, owner
    const isAbleToDelete = isUserAdmin || isUserSuperUser || isUserOwner;
    // Add collaborator: admin, superuser, owner, collaborator
    const isAbleToAddCollaborator = ((isUserOwner || isUserCollaborator) && 
                                    obj.access_modifier === "private" ||
                                    isUserAdmin || isUserSuperUser ) &&
                                    !(obj.is_directory && obj.access_modifier !== "private");
    // Assign owner: admin, superuser, owner. Directory cannot be organization directory
    const isAbleToAssignOwner = (isUserAdmin || isUserSuperUser || isUserOwner) && !(obj.access_modifier === 'protected' && obj.is_directory);
    // Change access modifier: admin, superuser, owner
    const isAbleToChangeAccessModifier = (isUserAdmin || isUserSuperUser || isUserOwner)&& isOuterMostContent;

    return { 
        isAbleToChangeLocation,
        isAbleToEdit,
        isAbleToClone,
        isAbleToDelete,
        isAbleToAddCollaborator,
        isAbleToAssignOwner,
        isAbleToChangeAccessModifier
    }

}

export const excludeUsers = (users, collaborators,owner) =>{
    return users.filter(user => isNotCollaborator(user.id, collaborators) && user.id != owner.id);
}

export const handleAddCollaborator = async ({contentId, collaboratorId,handleCloseAddCollaboratorDialog}) => {
    if (!collaboratorId){
        return;
    }
    const body = {
        collaborator_id: collaboratorId
    }
    try{
        await folderManagementApi.addCollaborator(contentId, body);
    }   catch(error){
        toast.error("Error adding collaborator");
    }
    handleCloseAddCollaboratorDialog();
}

export const handleAssignOwner = async ({contentId, newOwnerId, handleCloseChangeOwnerDialog}) => {
    if (!newOwnerId){
        return;
    }
    const body = {
        owner_id: newOwnerId
    }
    try{
        await folderManagementApi.changeOwner(contentId, body);
    }   catch(error){
        toast.error("Error changing owner");
    }
    handleCloseChangeOwnerDialog();
}

export const handleRemoveCollaborator = async ({contentId, collaboratorId,handleCloseAddCollaboratorDialog, navigate}) => {
    if (!collaboratorId){
        return;
    }

    try{
        await folderManagementApi.removeCollaborator(contentId,collaboratorId);
        window.location.reload();
    }   catch(error){
        toast.error("Error removing collaborator");
    }


}

// Check if a client_id is in collaborators array of objects
const isNotCollaborator = (clientId, collaborators) => {
    return collaborators.every(collaborator => collaborator.id != clientId);
}