import { ArrowBackOutlined } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../api";
import Input from "../../components/Input/Input";
import handleErrorFunction from "../../funtions/handleErrorFunction";
import HeaderBack from "../../layouts/HeaderBack";
import { common } from "../../utils/common";
import "./Password.scss";

export default function ChangePasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.PasswordPage.ChangePasswordPage",
  });
  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  const [checkPasswordOld, setCheckPasswordOld] = useState("");
  const [checkPasswordMatch, setCheckPasswordMatch] = useState("");
  const [checkPasswordNull, setCheckPasswordNull] = useState("");
  const [checkPasswordFalse, setCheckPasswordFalse] = useState("");
  const [checkPasswordConfirmation, setCheckPasswordConfirmation] =
    useState("");

  const [password, setPassword] = useState({
    password: "",
    passwordConfirmation: "",
    currentPassword: "",
  });

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setPassword({
      ...password,
      [name]: value,
    });
    switch (name) {
      case "password":
        setCheckPasswordOld("");
        setCheckPasswordNull("");
        break;
      case "passwordConfirmation":
        setCheckPasswordMatch("");
        setCheckPasswordConfirmation("");
        break;
      case "currentPassword":
        setCheckPasswordFalse("");
        break;

      default:
        break;
    }
  };

  const handleChangePassword = async () => {
    try {
      const response = await changePassword(password);
      if (response) {
        toast.success(t("change_password_successfully"));
        navigate("/content/my-content");
      }
    } catch (error) {
      const errorReturn = handleErrorFunction(error, v);
      toast.error(errorReturn);
      if (errorReturn === v("notMatch")) {
        setCheckPasswordMatch(false);
      } else {
        setCheckPasswordMatch(true);
      }

      if (errorReturn === v("same_password")) {
        setCheckPasswordOld(false);
      } else {
        setCheckPasswordOld(true);
      }

      if (errorReturn === v("wrong_password")) {
        setCheckPasswordFalse(false);
      } else {
        setCheckPasswordFalse(true);
      }

      if (errorReturn === v("password")) {
        setCheckPasswordNull(false);
      } else {
        setCheckPasswordNull(true);
      }

      if (errorReturn === v("passwordConfirmation")) {
        setCheckPasswordConfirmation(false);
      } else {
        setCheckPasswordConfirmation(true);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChangePassword();
    }
  };

  return (
    <div className="password_page">
      {common.getLtiLMS() && <HeaderBack />}
      <Stack spacing={2} className="password_layout">
        <div className="title-back">
          {!common.getLtiLMS() && (
            <ArrowBackOutlined
              className="arrowbackoutlined-icon"
              onClick={handleBack}
            />
          )}
          <div className="title-text">{t("change_password")}</div>
        </div>

        <Stack className="input input-text-error">
          <Input
            name="currentPassword"
            value={password.currentPassword}
            type="password"
            label={t("current_password")}
            onChange={handleChangeInput}
            className={checkPasswordFalse === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />
          <div
            className={checkPasswordFalse === false ? "error-text" : "hidden"}
          >
            {v("wrong_password")}
          </div>
        </Stack>
        <Stack className="input input-text-error">
          <Input
            name="password"
            value={password.password}
            type="password"
            label={t("new_password")}
            onChange={handleChangeInput}
            className={
              checkPasswordOld === false || checkPasswordNull === false
                ? "border-red"
                : ""
            }
            onKeyDown={handleKeyDown}
          />
          <div className={checkPasswordOld === false ? "error-text" : "hidden"}>
            {v("same_password")}
          </div>
          <div
            className={checkPasswordNull === false ? "error-text" : "hidden"}
          >
            {v("password")}
          </div>
        </Stack>
        <Stack className="input input-text-error">
          <Input
            name="passwordConfirmation"
            value={password.passwordConfirmation}
            type="password"
            label={t("confirm_password")}
            onChange={handleChangeInput}
            className={
              checkPasswordMatch === false ||
              checkPasswordConfirmation === false
                ? "border-red"
                : ""
            }
            onKeyDown={handleKeyDown}
          />
          <div
            className={checkPasswordMatch === false ? "error-text" : "hidden"}
          >
            {v("notMatch")}
          </div>
          <div
            className={
              checkPasswordConfirmation === false ? "error-text" : "hidden"
            }
          >
            {v("passwordConfirmation")}
          </div>
        </Stack>

        <Button
          variant="contained"
          className="button"
          onClick={handleChangePassword}
        >
          {t("save")}
        </Button>
      </Stack>
    </div>
  );
}
