import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const HeaderBack = memo(() => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common",
  });
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="back-wrapper">
      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        size="large"
        className="button-back"
        onClick={goBack}
      >
        {t("back")}
      </Button>
    </div>
  );
});

HeaderBack.displayName = "HeaderBack";

export default HeaderBack;
