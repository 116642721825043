import { createContext, useContext } from "react";

const ConnectLMSContext = createContext({
  connectLMSList: [],
  paginationConnectLMSList: {},
  reloadPage: () => {},
});

export const useConnectLMSConext = () => useContext(ConnectLMSContext);

const ConnectLMSProvider = ConnectLMSContext.Provider;

export default ConnectLMSProvider;
