import React, { useState, useEffect } from "react";
import { Typography, Button, Stack } from "@mui/material";
import "./LoginForm.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sendEmailLogin } from "../../api";
import parseJwt from "../../funtions/parseJwt";
import { toast } from "react-toastify";
import { ArrowBackOutlined } from "@mui/icons-material";
import Input from "../../components/Input/Input";
import Select from "../../components/Dropdown/Select";
import { sendInfoLogin, checkDomain } from "../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import convertURL from "../../utils/convertURL";
import NotFound from "../NotFound/NotFound";
import handleErrorFunction from "../../funtions/handleErrorFunction";
const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
const DEFLAULT_ACCOUNTS = [
  {
    name: "--",
    id: 0,
  },
];

export default function LoginForm() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailDomain, setEmailDomain] = useState({
    email: "",
    name: "",
    domain: "",
    password: "",
  });

  const [checkHost, setCheckHost] = useState("");
  const [loading, setLoading] = useState("");

  const [displayDomainDropdown, setDisplayDomainDropdown] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [emailNotExist, setEmailNotExist] = useState(false);
  const [isRootDomain, setIsRootDomain] = useState(true);
  const [account, setAccount] = useState({});
  const [emailNotValid, setEmailNotValid] = useState(false);

  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordValid, setCheckPasswordValid] = useState("");
  const [checkTimes, setCheckTimes] = useState("");
  const [countLogin, setCountLogin] = useState("");
  const [checkEmailActive, setCheckEmailActive] = useState("");
  const [checkOrgActive, setCheckOrgActive] = useState("");

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.LoginForm",
  });

  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  const handleCheckHost = async () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const host = url.hostname;
    try {
      const respond = await checkDomain({ host: host });
      if (respond.data.message === null) {
        setCheckHost(false);
      } else {
        setCheckHost(true);
      }
      setLoading(true);
    } catch (error) {
      setCheckHost(false);
    }
  };

  useEffect(() => {
    if (window.location.hostname != ROOT_DOMAIN) {
      handleCheckHost();
    } else {
      setLoading(true);
      setCheckHost(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hostname != ROOT_DOMAIN) {
      setIsRootDomain(false);
    }
  }, [isRootDomain]);

  useEffect(() => {
    if (searchParams.has("email")) {
      setEmailDomain({
        ...emailDomain,
        ...{ email: searchParams.get("email") },
      });
      setDisplayDomainDropdown(true);
    }
  }, [JSON.stringify(emailDomain)]);

  const handleSendEmail = async () => {
    if (isRootDomain && account?.id) {
      const domain = account.domains.find((e) => e.primary == true)?.host;
      const url = convertURL(domain);
      // console.log('url',url);
      // console.log('window.location.pathname',window.location.pathname);
      window.location.replace(
        `${url}${window.location.pathname}?email=${emailDomain.email}`
      );
    }

    const emailSend = emailDomain.email;
    try {
      const respond = await sendEmailLogin({ email: emailSend });
      if (respond) {
        setEmailNotExist(false);
        const jwtToken = respond.data.jwt;
        const json = parseJwt(jwtToken);
        setAccounts([...DEFLAULT_ACCOUNTS, ...json.response]);
        setEmailDomain({
          ...emailDomain,
        });
        setDisplayDomainDropdown(true);
        setEmailNotExist(false);
        setEmailNotValid(false);
      }
    } catch (error) {
      try {
        const errorRes = error.response.data.error;
        if (errorRes.status === 404) {
          // if (errorRes.details.key === "wrong_password") {
          setEmailNotExist(true);
          setEmailNotValid(false);
          toast.error(t("email_does_not_exist"));
          // }
        } else if (errorRes.status === 400) {
          setEmailNotExist(false);
          setEmailNotValid(true);
        }
      } catch (error) {
        toast.error(t("connection_error"));
      }
    }
  };

  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setAccount(accounts.find((e) => e.id == value));
    setEmailDomain({
      ...emailDomain,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const respond = await sendInfoLogin({
        email: emailDomain.email,
        password: emailDomain.password,
      });
      if (respond) {
        const jwt = respond.data.jwt;
        const json = parseJwt(jwt);
        const { url } = json;

        toast.success(t("success_to_login"));
        localStorage.setItem("JWT_loginToken", respond.data.jwt);
        navigate(`/content/my-content`);
        // window.location.reload();
      }
    } catch (error) {
      const errorRes = error.response.data.error;
        if (errorRes.status === 400) {
          setCountLogin(errorRes.details.count);
        }


      const errorReturn = handleErrorFunction(error, v);
      if (errorReturn === v("password")) setCheckPasswordValid(false);
      else setCheckPasswordValid(true);

      if (errorReturn === v("wrong_password")) setCheckPassword(false);
      else setCheckPassword(true);

      if (errorReturn === v("exceed_login_count")) setCheckTimes(false);
      else setCheckTimes(true);

      if (errorReturn === v("client_not_active")) setCheckEmailActive(false);
      else setCheckEmailActive(true);

      if (errorReturn === v("account_not_active")) setCheckOrgActive(false);
      else setCheckOrgActive(true);
    }
  };

  const handleBack = () => {
    setDisplayDomainDropdown(false);
  };

  const handleBackReturn = () => {
    navigate(-1)
  }

  const hanldeForgotPassword = async () => {
    navigate("/forgot-password");
  };

  return (
    <>
      {loading === true && (
        <>
          {checkHost === true ? (
            <Stack spacing={2} className="loginform_layout">
             <div className="title-back">
          <ArrowBackOutlined
            className="arrowbackoutlined-icon"
            onClick={handleBackReturn}
          />
          <div className="title-text"> {t("login")}</div>
        </div>
              <Stack className="input-text-error">
                <Input
                  label={t("email_address")}
                  name="email"
                  value={emailDomain.email}
                  onChange={handleLoginChange}
                  className={emailNotExist === true ? "border-red" : ""}
                  disabled={displayDomainDropdown === true ? "disable" : ""}
                />
                <div
                  className={emailNotExist === true ? "error-text" : "hidden"}
                >
                  * {t("email_does_not_exist")}
                </div>
                <div
                  className={emailNotValid === true ? "error-text" : "hidden"}
                >
                  * {t("invalid_email")}
                </div>
              </Stack>
              {isRootDomain ? (
                displayDomainDropdown === true ? (
                  <Select
                    listItem={accounts.map((index) => {
                      return {
                        id: index.id,
                        name: index.name,
                      };
                    })}
                    label={t("choose_1_domain_to_login")}
                    name="domain"
                    value={emailDomain.domain}
                    onChange={handleLoginChange}
                  />
                ) : (
                  <></>
                )
              ) : (
                <Stack className="input-text-error">
                  <Input
                    type="password"
                    name="password"
                    value={emailDomain.password}
                    placeholder={t("enter_password")}
                    label={t("enter_password")}
                    onChange={handleLoginChange}
                    className={
                      checkPassword === false ||
                      checkPasswordValid === false ||
                      checkTimes === false ||
                      checkEmailActive === false
                        ? "border-red"
                        : ""
                    }
                  />

                  <div
                    className={
                      checkPasswordValid === false ? "error-text" : "hidden"
                    }
                  >
                    {v("password")}
                  </div>

                  <div
                    className={
                      checkPassword === false ? "error-text" : "hidden"
                    }
                  >
                    {v("wrong_password")},{" "}
                    {t("remaining_login_attempts_allowed")}: {countLogin}
                  </div>
                  <div
                    className={checkTimes === false ? "error-text" : "hidden"}
                  >
                    {v("exceed_login_count")}
                  </div>

                  <div
                    className={
                      checkEmailActive === false ? "error-text" : "hidden"
                    }
                  >
                    {v("client_not_active")}
                  </div>

                  <div
                    className={
                      checkOrgActive === false ? "error-text" : "hidden"
                    }
                  >
                    {v("account_not_active")}
                  </div>
                </Stack>
              )}

              <div className="item item1">
                {isRootDomain ? (
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="linkblue error-text"
                  ></Typography>
                ) : (
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="linkblue error-text"
                    onClick={hanldeForgotPassword}
                  >
                    {t("forgot_password")}
                  </Typography>
                )}
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {" "}
                  {isRootDomain ? (
                    <Button
                      variant="contained"
                      size="large"
                      className="button cancel-button"
                      onClick={handleBack}
                      style={
                        displayDomainDropdown === true
                          ? {}
                          : { display: "none" }
                      }
                    >
                      {t("back")}
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="contained"
                    size="large"
                    className="button"
                    onClick={isRootDomain ? handleSendEmail : handleLogin}
                  >
                    {isRootDomain ? t("next") : t("login")}
                  </Button>
                </div>
              </div>
              {isRootDomain ? (
                <>
                  <hr className="hr" />
                  <div className="item item2">
                    <Typography
                      variant="body2"
                      gutterBottom
                      className="linkblue"
                    >
                      {t("privacy_policy")}
                    </Typography>

                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className="title"
                    >
                      {t("need_an_account")}
                    </Typography>

                    <Link to="/signup" class="link">
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="linkblue"
                      >
                        {t("sign_up_")}
                      </Typography>
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
}
