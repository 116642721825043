import React from "react";
import { Typography } from "@mui/material";
import { Twitter, FacebookRounded } from "@mui/icons-material";
import "./FooterLanding.scss";
import { useTranslation } from "react-i18next";

export default function FooterLanding() {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.Footer.FooterLanding",
  });
  return (
    <div className="footerlanding_layout">
      <div className="footerlanding_body">
        <div className="item item1">
          <Typography variant="body1" gutterBottom className="text text1">
            {t("text1")}
          </Typography>
          <Typography variant="body2" gutterBottom className="text text2">
            {t("text2")}
          </Typography>
          <a
            variant="body2"
            gutterBottom
            className="text text2 text2-underline"
            target="_blank"
            style={{ fontSize: "small" }}
            href={process.env.REACT_APP_PRIVACY}
            rel="noreferrer"
          >
            {t("text3")}
          </a>
        </div>
        <div className="item item2">
          <Twitter className="icon" />
          <FacebookRounded className="icon" />
        </div>
      </div>
    </div>
  );
}
