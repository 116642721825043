import React, { memo, useCallback, useEffect, useState } from "react";
import { connectLMSApi } from "../../../api";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import "../HomePageOrg.scss";
import "./ConnectLMSPage.scss";
import ConnectLMSProvider from "./Context";
import Inner from "./Inner";

const ConnectLMSPage = memo(() => {
  const [connectLMSList, setConnectLMSList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: null,
  });

  const getConnectionList = useCallback(async () => {
    const params = {
      populate: ["lms"],
    };
    try {
      const response = await connectLMSApi.getConnectionLMSs(params);
      if (response.status === 200) {
        const { data, meta } = response.data;
        setConnectLMSList(data);
        setPagination(meta.pagination);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getConnectionList();
  }, [getConnectionList]);

  return (
    <ConnectLMSProvider
      value={{
        reloadPage: getConnectionList,
        connectLMSList,
        paginationConnectLMSList: pagination,
      }}
    >
      <HomeLayout
        addLMSConnection={pagination.total > 0}
        bodyManage={<Inner />}
        type="Manage Organization"
      />
    </ConnectLMSProvider>
  );
});

export default ConnectLMSPage;
