import { CancelOutlined, Edit, SaveAsOutlined } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getInfoAccount, updateInfoAccount } from "../../../api";
import { useAppContext } from "../../../context/AppContext";
import parseJwt from "../../../funtions/parseJwt";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import "../HomePageOrg.scss";
import "./OrganizationDetailPage.scss";

const OrganizationDetail = () => {
  const {
    userInfo: { accountID },
  } = useAppContext();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.OrganizationDetailPage",
  });
  const [infoAccount, setInfoAccount] = useState({
    fullName: "",
    email: "",
    language: "",
    phone: "",
  });

  const loadData = useCallback(async () => {
    if (!accountID) return;
    try {
      const response = await getInfoAccount(accountID);
      const jwt = response.data.jwt;
      const infoAccountData = parseJwt(jwt);
      setInfoAccount({
        fullName: infoAccountData.name,
        email: infoAccountData.contactEmail,
        language: infoAccountData.defaultLocale,
        phone: infoAccountData.phone || "",
      });
      i18n.changeLanguage(infoAccountData.defaultLocale);
    } catch (error) {
      toast.error(t("connection_error"));
    }
  }, [accountID]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation("translation");

  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setInfoAccount({
      ...infoAccount,
      [event.target.name]: event.target.value,
    });
  };

  const handleEdit = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    setOpen(false);
    i18n.changeLanguage(infoAccount.language);
    try {
      const response = await updateInfoAccount(
        {
          name: infoAccount.fullName,
          defaultLocale: infoAccount.language,
          contactEmail: infoAccount.email,
        },
        accountID
      );
      if (response) {
        toast.success(t("update_successful"));
      }
    } catch (error) {
      toast.error(t("connection_error"));
    }
  };

  const handleCancel = () => {
    loadData();
    setOpen(false);
  };

  return (
    <div className="homepageorg_layout">
      <div className="homepageorg_title">{t("title")}</div>
      <div className="view-info">
        {/* <div class="left">
          <img src="" alt="Avatar" />
        </div> */}
        <div className="right">
          <div className="right-item">
            <div>{t("name")}</div>
            <p>{infoAccount.fullName}</p>
          </div>
          <div className="right-item">
            <div>{t("email_addr")}</div>
            <p>{infoAccount.email}</p>
          </div>
          <div className="right-item">
            <div>{t("phone")}</div>
            <p>{infoAccount.phoneNumber}</p>
          </div>
          <div className="right-item">
            <div>{t("lang")}</div>
            <p>{infoAccount.language === "en" ? t("english") : t("vietnam")}</p>
          </div>
          <div className="right-button">
            <Button
              className="button-edit"
              variant="contained"
              startIcon={<Edit />}
              size="large"
              onClick={handleEdit}
            >
              {t("edit")}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="Box-Modal">
          <div>
            {/* <TextField
              label="Avatar"
              name="avatar"
              value={""}
              onChange={handleChange}
              fullWidth
            /> */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ background: "#fff", padding: "0 5px" }}
              >
                {t("language")}
              </InputLabel>
              <Select
                value={infoAccount.language || "vi"}
                onChange={handleChange}
                startIcon={<LanguageIcon />}
                name="language"
              >
                <MenuItem value="vi">Tiếng Việt</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label={t("fullname")}
              name="fullName"
              value={infoAccount.fullName}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
            <TextField
              label={t("email")}
              name="email"
              value={infoAccount.email}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
            <TextField
              label={t("phone")}
              name="phoneNumber"
              value={infoAccount.phoneNumber}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                startIcon={<SaveAsOutlined />}
                color="primary"
                onClick={handleSave}
                className="save-button"
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                startIcon={<CancelOutlined />}
                onClick={handleCancel}
                className="cancel-button"
              >
                {t("cancel")}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default function OrganizationDetailPage() {
  return (
    <div>
      <HomeLayout
        bodyManage={<OrganizationDetail />}
        type="Manage Organization"
      />
    </div>
  );
}
