import React from "react";
import BodyManageOrg from "../../../layouts/Body/BodyManageOrg";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";

export default function HomePageOrg() {
  return (
    <div>
      <HomeLayout
        addUser={true}
        bodyManage={<BodyManageOrg />}
        type="Manage Organization"
      />
    </div>
  );
}
