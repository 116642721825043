import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { memo, useState } from "react";
import CopyToClipboardButton from "../../../../components/Button/CopyToClipboardButton";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    display: "flex",
    alignItems: "center",
    columnGap: "4px",
    fontSize: "1em",
  },
});

const SecretField = memo(({ value }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <td
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <CustomWidthTooltip
          title={
            <CopyToClipboardButton
              value={value}
              classes={{
                arrow: "secret-copy-clipboard",
              }}
            />
          }
          placement="right"
          arrow
          open={isShown}
        >
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </CustomWidthTooltip>
      </td>
    </>
  );
});

export default SecretField;
