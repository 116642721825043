import React from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import "../HomePageContent.scss";
import "./ShareMePage.scss";
import BodyManageContent from "../../../layouts/Body/BodyManageContent";
import { useTranslation } from "react-i18next";

const ShareMe = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.ShareMePage",
  });
  return (
    <div className="homepagecontent_layout">
      <div className="homepagecontent_top">{t("share_with_me")}</div>

      <div className="shareme_bottom">
        <BodyManageContent collabContent={true} />
      </div>
    </div>
  );
};

export default function ShareMePage() {
  return (
    <div>
      <HomeLayout
        addContent={true}
        newFolder={true}
        bodyManage={<ShareMe />}
        type="Manage Content"
      />
    </div>
  );
}
