import WarningIcon from "@mui/icons-material/Warning";
import React, { memo } from "react";
import { LMS_TYPE, TUTORIAL_LINK } from "../../../../constants/connectLMS";
import { useTranslation } from "react-i18next";
import { common } from "../../../../utils/common";
import "../ConnectLMSPage.scss";
import KeyField from "../dataField/KeyField";
import SecretField from "../dataField/SecretField";
import UpdateEmails from "../dataField/UpdateEmails";
import UpdateNames from "../dataField/UpdateNames";
import LMSItemActions from "./LMSItemActions";
import LMSItemFunctions from "./LMSItemFunctions";
import PublicKeyField from "../dataField/PublicKeyField";
import RegistrationURLField from "../dataField/RegistrationURLField";


const LMSItem = memo(({ id, name, keyLti, secret, status, updatedAt, lms,public_key,registration_url, last_updated, version,client_id }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage.connectionItem",
  });
  
  // LOG PARAMS
  console.log("passed in params: "+ JSON.stringify({ id, name, keyLti, secret, status, updatedAt, lms,public_key,registration_url, last_updated, version,client_id }));

  return (
    <div className="connectLMS-list__item">

      <div className="header">
        <div className="lti-name">{name}</div>
        {lms?.type !== LMS_TYPE.OTHER && (
          <div className="lti-help">
            <WarningIcon />
            <span>
              {t("followThis")}
              <a
                href={TUTORIAL_LINK[lms?.type?.toLocaleUpperCase()]}
                target="_blank"
                rel="noreferrer"
              >
                {t("stepByStep")}
              </a>{" "}
              {t("setUpLti", { type: lms?.name })}
            </span>
          </div>
        )}
      </div>


      <div className="connection-fields">
        <table>
          <tbody>
            {/* only render in LTI v1.1 */}
            { version === 'ltiv1p1' &&(
              <>
                <tr>
                  <th>{t("key")}</th>
                  <KeyField value={keyLti} />
                </tr>

                <tr>
                  <th>{t("secret")}</th>
                  <SecretField value={secret} />
                </tr>
              </>
            )} 

            {/* only render in LTI v1.3 */}
            {
              version==='ltiv1p3' &&(
              <>
                <tr>
                  <th>{t("public_key")}</th>
                  <PublicKeyField value={public_key} />
                </tr>

                <tr>
                  <th>{t("registration_url")}</th>
                  <RegistrationURLField value={registration_url} />
                </tr>
              </>
            )}
      
            <tr>
              <th>{t("status")}</th>
              <td>{common.capitalizeFirstLetter(status)}</td>
            </tr>
            <tr>
              <th>{t("lastUpdated")}</th>
              <td>{updatedAt}</td>
            </tr>
            

            {/* only render in LTI v1.1 */}
            {
              version==='ltiv1p1' &&(
              <>
                <tr>
                  <th>{t("updateNames.title")}</th>
                  <td>
                    <UpdateNames />
                  </td>
                </tr>


                <tr>
                  <th>{t("updateEmails.title")}</th>
                  <td>
                    <UpdateEmails />
                  </td>
                </tr>
              </>
            )}

            {/* only render in LTI v1.3 */}
            {
              version==='ltiv1p3' &&(
              <>
                <tr>
                  <th>{t("lti_version")}</th>
                  <td>LTI v1.3</td>
                </tr>

                <tr>
                  <th>{t("client_id")}</th>
                  <td>{client_id}</td>
                </tr>
              </>
            )}

            



          </tbody>
        </table>
        <LMSItemActions id={id} status={status} version={version}/>
      </div>
      {/* only render in LTI v1.3 */}
      {
        version ==='ltiv1p3' &&(
          <LMSItemFunctions/>
      )}

    </div>
  );
});

export default LMSItem;
