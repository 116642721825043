import { createContext, useContext } from "react";

const initialUserInfo = {
  accountID: "",
  email: "",
  id: "",
  url: "",
  // account: "hoangthedo",
  // accountID: 250,
  // createdAt: "2024-02-26T18:42:11.097Z",
  // email: "huongpnc11@gmail.com",
  // exp: 1711826088,
  // fullName: "huong",
  // gender: "female",
  // iat: 1709234088,
  // id: 425,
  // locale: "vi",
  // pseId: 352,
  // roleId: 6,
  // settings: null,
  // storageQuota: 128,
  // updatedAt: "2024-02-26T18:42:11.097Z",
  // url: "hoangthedo.cohota.dev",
  // uuid: "f4face325142c5c2be4a549922cc895e55a8495a",
  // workflowState: "active",
};

const initialValue = {
  userInfo: initialUserInfo,
  setUserInfo: () => {},
};

const AppContext = createContext(initialValue);

export const useAppContext = () => useContext(AppContext);

const AppProvider = AppContext.Provider;

export default AppProvider;
