import {
  AddCircleOutline,
  CloudUpload,
  CreateNewFolder, 
  DomainAdd,
  PersonAdd,
} from "@mui/icons-material";
import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { addNewDomain, createNewFolder } from "../../api";
import { addNewDomain } from "../../api"; //Remove create new folder api
import ComingSoon from "../../assets/img/ComingSoon/folder.png";
import Input from "../../components/Input/Input";
import Menu from "../../components/Menu/Menu";
import { useAppContext } from "../../context/AppContext";
import parseJwt from "../../funtions/parseJwt";
import AddConnectLMSModal from "../../pages/HomePageOrg/ConnectLMSPage/modal/AddConnectLMSModal";
import { common } from "../../utils/common";
import "./SubHeader.scss";
import NewFolderForm from "./component/NewFolderForm";

export default function SubHeader({
  newFolder,
  addContent,
  addUser,
  addDomain,
  addLMSConnection,
  newOrganizationFolder,
}) {
  const {
    userInfo: { accountID },
  } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.SubHeader",
  });

  const [folder, setFolder] = useState({
    name: "",
    contextType: "",
    contextId: "",
  });

  const [domainAdd, setDomainAdd] = useState({
    domain: "",
    subdomain: "",
  });


  const currentParentDirectory = window.location.pathname.split('/')[3] ? window.location.pathname.split('/')[3] : "";


  const [openModalUploadLogo, setOpenModalUploadLogo] = useState(false);
  const handleOpenModalUploadLogo = () => setOpenModalUploadLogo(true);
  const handleCloseModalUploadLogo = () => setOpenModalUploadLogo(false);

  const [openModalNewFolder, setOpenModalNewFolder] = useState(false);
  const handleOpenModalNewFolder = () => {setOpenModalNewFolder(true)};
  const handleCloseModalNewFolder = () => setOpenModalNewFolder(false);

  const [openModalAddRows, setOpenModalAddRows] = useState(false);
  const handleOpenModalAddRows = () => setOpenModalAddRows(true);
  const handleCloseModalAddRows = () => {
    setOpenModalAddRows(false);
    setDomainAdd({
      domain: "",
      subdomain: "",
    });
  };

  const handleNewFolderChange = (event) => {
    const { name, value } = event.target;
    setFolder({
      ...folder,
      [name]: value,
    });
  };



  const hanldeChangeAddDomain = (event) => {
    const { name, value } = event.target;
    setDomainAdd({
      ...domainAdd,
      [name]: value,
    });
  };
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    setRoleId(json.roleId);
  }, []);

  const handleAddDomain = async () => {
    try {
      const res = await addNewDomain(domainAdd, accountID);
      if (res.status === 200) {
        toast.success(t("add_domain_successfully"));
        handleCloseModalAddRows();
        navigate("/organization/domain");
        window.location.reload();
        setDomainAdd({
          domain: "",
          subdomain: "",
        });
        //
      } else {
        toast.error(t("domain_add_failed"));
      }
    } catch (error) {
      try {
        const errorRes = error.response.data.error;
        if (errorRes.status === 400) {
          if (errorRes.details.key === "invalid_input") {
            toast.error(t("invalid_information_entered"));
          } else if (errorRes.details.key === "domain_existed") {
            toast.error(t("domain_already_exists"));
          }
        } else {
          toast.error(t("domain_add_failed"));
        }
      } catch (err) {
        toast.error(t("domain_add_failed"));
      }
    }
  };

  return (
    <div
      className={`subheader_container-extend ${
        common.getLtiLMS() ? "has-lti" : ""
      }`}
    >
      {!common.getLtiLMS() && (
        <div className="upload">
          <Button
            variant="contained"
            startIcon={<CloudUpload />}
            size="large"
            className="button-uploadlogo"
            onClick={handleOpenModalUploadLogo}
          >
            {t("upload_logo")}
          </Button>
        </div>
      )}
      <Modal
        open={openModalUploadLogo}
        onClose={handleCloseModalUploadLogo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="Box-Modal"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <img src={ComingSoon} alt="" width={200} />
        </Box>
      </Modal>
      <div className="subheader_container">
        {roleId === 7 ? (
          <></>
        ) : (
          <>
            <div className="subheader_container--left">
              {addContent && (
                <Link to={`/content/new?parent=${currentParentDirectory}`} className="add-btn">
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutline />}
                    size="large"
                    className="button "
                  >
                    {t("add_content")}
                  </Button>
                </Link>
              )}
              {newFolder && (
                <Button
                  variant="contained"
                  startIcon={<CreateNewFolder/>}
                  size="large"
                  className="button button-newfolder"
                  onClick={handleOpenModalNewFolder}
                >
                  {t("new_folder")}
                </Button>
              )}
            </div>
            {common.getLtiLMS() && <Menu />}
              <NewFolderForm
                openModalNewFolder={openModalNewFolder}
                handleCloseModalNewFolder={handleCloseModalNewFolder}
              />
            {addUser && (
              <Link to="/user/create">
                <Button
                  variant="contained"
                  startIcon={<PersonAdd />}
                  size="large"
                  className="button"
                >
                  {t("add_user")}
                </Button>
              </Link>
            )}
            {addDomain && (
              <Button
                variant="contained"
                startIcon={<DomainAdd />}
                size="large"
                className="button"
                onClick={handleOpenModalAddRows}
              >
                {t("add_domain")}
              </Button>
            )}
            <Modal
              open={openModalAddRows}
              onClose={handleCloseModalAddRows}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                className="Box-Modal"
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                <Input
                  label={t("enter_domain")}
                  value={domainAdd.domain}
                  name="domain"
                  onChange={hanldeChangeAddDomain}
                />
                <Input
                  label={t("enter_subdomain")}
                  value={domainAdd.subdomain}
                  name="subdomain"
                  onChange={hanldeChangeAddDomain}
                />

                {/* <Dropdown
              value={domainAdd.primary}
              listItem={[
                { id: 1, name: "true" },
                { id: 2, name: "false" },
              ]}
              label="Chọn primary"
              name="primary"
              onChange={hanldeChangeAddDomain}
            /> */}

                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    size="large"
                    className="button"
                    onClick={handleAddDomain}
                  >
                    {t("create")}
                  </Button>
                  <Button
                    size="large"
                    className="button"
                    style={{ color: "#3c4859" }}
                    onClick={handleCloseModalAddRows}
                  >
                    {t("cancel")}
                  </Button>
                </Box>
              </Box>
            </Modal>
            {addLMSConnection && <AddConnectLMSModal />}
          </>
        )}
      </div>
    </div>
  );
}
