import parseJwt from "../funtions/parseJwt";

const setJwtToken = (jwt) => localStorage.setItem("JWT_loginToken", jwt);

const setLtiValue = (value) => localStorage.setItem("lti", value);
const getLtiValue = () => localStorage.getItem("lti");

const getToken = () => {
  let token = localStorage.getItem("JWT_loginToken");
  const queryString = window.location.search;
  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    const jwt = urlParams.get("jwt");
    const lti = urlParams.get("lti");
    if (jwt) {
      token = jwt;
      setJwtToken(token);
    }
    if (lti === "true") {
      setLtiValue(lti);
    }
  }
  return token;
};

const getLtiLMS = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let lti = urlParams.get("lti");
  if (lti) {
    setLtiValue(lti);
  } else {
    lti = getLtiValue();
  }
  return Boolean(lti);
};

const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const common = {
  getToken,
  setJwtToken,
  getLtiLMS,
  capitalizeFirstLetter,
};
