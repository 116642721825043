import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import img from "../../assets/img/EmptyImage/sleeping-bell.svg";
import HeaderPage from "../../layouts/Header/HeaderPage/HeaderPage";
import HeaderBack from "../../layouts/HeaderBack";
import { common } from "../../utils/common";
import "./AnnouncementsPage.scss";

const announcementsList = [
  //   { id: 1, content: "Thông báo 1" },
  //   { id: 2, content: "Thông báo 2" },
];

export default function AnnouncementsPage() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.AnnouncementPage",
  });
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="announcements_page">
      {common.getLtiLMS() ? (
        <HeaderBack />
      ) : (
        <>
          <HeaderPage />
          <div className="back">
            <Button
              variant="contained"
              startIcon={<ArrowBack />}
              size="large"
              className="button-back"
              onClick={goBack}
            >
              {t("back")}
            </Button>
          </div>
        </>
      )}
      {announcementsList.length > 0 ? (
        <div className="announcements_content"></div>
      ) : (
        <div
          className={`announcements_empty ${
            common.getLtiLMS() ? "has-lti" : ""
          }`}
        >
          <img src={img} alt="" />
          <div>{t("no_announcements")}</div>
        </div>
      )}
    </div>
  );
}
