import React from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import emptyArchivedUser_img from "../../../assets/img/EmptyImage/empty-archiveduser.svg";
import "../HomePageOrg.scss";
import "./ArchivedUserPage.scss";
import { useTranslation } from "react-i18next";

const archivedUserList = [];

const ArchivedUser = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ArchivedUserPage",
  });
  return (
    <div className="homepageorg_layout">
      {archivedUserList.length > 0 ? (
        <div></div>
      ) : (
        <div className="archived-empty">
          <img src={emptyArchivedUser_img} alt="" />
          <div className="empty-title">{t("no_user_")}</div>
          <div className="empty-subtitle">{t("check_if_")}</div>
        </div>
      )}
    </div>
  );
};
export default function ArchivedUserPage() {
  return (
    <div>
      <HomeLayout
        addUser={true}
        bodyManage={<ArchivedUser />}
        type="Manage Organization"
      />
    </div>
  );
}
