import React,{useState,useEffect} from "react";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import EmptyFolder from "../../../layouts/EmptyFolder/EmptyFolder";
import BodyManageContent from "../../../layouts/Body/BodyManageContent";
import "./TrashPage.scss";
import { useTranslation } from "react-i18next";
import {routeConstants} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { common } from "../../../utils/common";
import  parseJwt  from "../../../funtions/parseJwt";

const data = [];

// const Trash = () => {
//   const { t } = useTranslation("translation", {
//     keyPrefix: "pages.HomePageContent.TrashPage",
//   });
//   return (
//     <div className="homepagecontent_layout">
//       <div className="homepagecontent_top">{t("trash_content")}</div>

//       {data.length > 0 ? (
//         <div className="trash_bottom"></div>
//       ) : (
//         <EmptyFolder type="trashContent" />
//       )}
//     </div>
//   );
// };

const Trash = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.TrashPage",
  });



  return (
    <div className="homepagecontent_layout">
      <div className="homepagecontent_top">{t('trash_content')}</div>

      <div className="trashcontent_bottom">
        <BodyManageContent trashContent={true} />
      </div>
    </div>
  );
};

export default function TrashPage() {
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [userInfo, setUserInfo] = useState({
    accountID: "",
    email: "",
    id: "",
  });

  const JWT_loginToken = common.getToken();
  const json = parseJwt(JWT_loginToken);

  const newRoleName = json.roleName;
  
  if (newRoleName !== "Administrator" && newRoleName !== 'Super User') {
    navigate(routeConstants.MY_CONTENT);
    return (<></>)
  }

  return (
    <div>
      <HomeLayout bodyManage={<Trash />} type="Manage Content" />
    </div>
  );
}
