import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import useAutocomplete from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import useAutocomplete from "@mui/material/useAutocomplete";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getAllUser, shareContent } from "../../api";
import handleErrorFunction from "../../funtions/handleErrorFunction";
import parseJwt from "../../funtions/parseJwt";
import { common } from "../../utils/common";

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  border: 2px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 45px;
    box-sizing: border-box;
    padding: 4px 6px;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${Autocomplete.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function Search({ contentId, handleCloseModalShare }) {
  const [allUser, setAllUser] = useState([
    {
      fullName: "",
      email: "",
      title: "",
    },
  ]);

  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.NavContent",
  });

  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;

  const [checkEmailShare, setCheckEmailShare] = useState("");
  const [checkEmailValid, setCheckEmailValid] = useState("");
  const [checkEmailOwner, setCheckEmailOwner] = useState("");

  const [newEmail, setNewEmail] = useState("");

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && newEmail.trim() !== "") {
      setAllUser((prev) => [
        ...prev,
        {
          fullName: "",
          email: newEmail.trim(),
          title: newEmail.trim(),
        },
      ]);
      setNewEmail("");
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: [],
    multiple: true,
    options: allUser,
    getOptionLabel: (option) => option.title,
    freeSolo: true,
  });

  const loadData = async () => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    const emailOwner = json.email;
    try {
      const respond = await getAllUser(json.accountID);
      const json = parseJwt(respond.data.jwt);
      const filteredUsers = json.clients.filter(
        (item) => item.channels[0].path !== emailOwner
      );
      const result = filteredUsers.map((item) => {
        return {
          fullName: item.fullName,
          email: item.channels[0].path,
          title: `${item.fullName} (${item.channels[0].path})`,
        };
      });
      setAllUser(result);
    } catch (err) {}
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleShareContent = async () => {
    const selectedEmails = value.map((option) => {
      if (typeof option === "string") {
        // If the element is a string (email), return it directly
        return option;
      } else {
        // If the element is an object, check if it exists in the allUser array and return email or title accordingly
        return allUser.some((user) => user.email === option.email)
          ? option.email
          : option.title;
      }
    });

    if (newEmail.trim() !== "") {
      selectedEmails.push(newEmail.trim());

      setAllUser((prev) => [
        ...prev,
        {
          fullName: "",
          email: newEmail.trim(),
          title: newEmail.trim(),
        },
      ]);
      setNewEmail("");
    }

    try {
      const res = await shareContent({ emails: selectedEmails }, contentId);
      if (res.status === 200) {
        handleCloseModalShare();
        toast.success(t("invite_user_successfully"));
      } else {
        toast.error(t("cannot_invite_user"));
      }
    } catch (error) {
      const errorReturn = handleErrorFunction(error, v);

      if (errorReturn === v("collaborate_existed")) setCheckEmailShare(false);
      else setCheckEmailShare(true);

      if (errorReturn === v("email")) setCheckEmailValid(false);
      else setCheckEmailValid(true);

      if (errorReturn === v("owner_existed")) setCheckEmailOwner(false);
      else setCheckEmailOwner(true);
    }
  };

  return (
    <>
      <Root>
        <div {...getRootProps()}>
          <Label {...getInputLabelProps()}>
            {t("enter_collaborator_email")}
          </Label>
          <Stack>
            <InputWrapper
              ref={setAnchorEl}
              className={focused ? "focused" : ""}
            >
              {value.map((option, index) => {
                if (typeof option === "string") {
                  return (
                    <StyledTag label={option} {...getTagProps({ index })} />
                  );
                }
                return (
                  <StyledTag label={option.title} {...getTagProps({ index })} />
                );
              })}
              {newEmail.trim() !== "" && (
                <StyledTag
                  label={newEmail.trim()}
                  onDelete={() => setNewEmail("")}
                />
              )}
              <input
                {...getInputProps({
                  onKeyPress: handleInputKeyPress,
                  value: newEmail,
                  onChange: (event) => setNewEmail(event.target.value),
                })}
              />
            </InputWrapper>
            <div
              className={checkEmailShare === false ? "error-text" : "hidden"}
            >
              {v("collaborate_existed")}
            </div>
            <div
              className={checkEmailValid === false ? "error-text" : "hidden"}
            >
              {v("email")}
            </div>
            <div
              className={checkEmailOwner === false ? "error-text" : "hidden"}
            >
              {v("owner_existed")}
            </div>
          </Stack>
        </div>
        <Stack>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.title}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
        </Stack>
      </Root>
      <Button
        className="button cancel-button"
        style={{ color: "#3c4859", width: 80 }}
        onClick={handleShareContent}
      >
        {t("share")}
      </Button>
    </>
  );
}
