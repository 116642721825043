import React from "react";
import "./NotFound.scss";
import {Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function NotFound() {
    const { t } = useTranslation("translation", {
        keyPrefix: "layouts.NotFound",
      });
  return (
    <div>
      <div className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg404">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">{t('look_like_you_re_lost')}</h3>

                  <p>{t('the_page_you_are_looking_for_not_avaible')}</p>

                  <Link to='/' className="link_404">
                    {t('go_to_home')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
