import { AccountCircle, FileUpload } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getMyAccount, updateMyAccount } from "../../../api";
import Input from "../../../components/Input/Input";
import { useAppContext } from "../../../context/AppContext";
import handleErrorFunction from "../../../funtions/handleErrorFunction";
import parseJwt from "../../../funtions/parseJwt";
import HeaderBack from "../../../layouts/HeaderBack";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { common } from "../../../utils/common";
import "../HomePageOrg.scss";
import "./MyAccountPage.scss";

const MyAccount = () => {
  const {
    userInfo: { id },
  } = useAppContext();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.MyAccountPage",
  });
  const v = useTranslation("translation", {
    keyPrefix: "validates",
  }).t;
  const [avatarImage, setAvatarImage] = useState(null);
  const inputRef = useRef(null);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setAvatarImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [myAccountUpdate, setMyAccountUpdate] = useState({
    firstName: "",
    lastName: "",
    email: "",
    uniqueId: "",
  });

  const [checkUniqueId, setCheckUniqueId] = useState("");

  const [checkFirstName, setCheckFirstName] = useState("");
  const [checkLastName, setCheckLastName] = useState("");

  const loadData = async () => {
    const res = await getMyAccount(id);
    try {
      if (res.status === 200) {
        const json = parseJwt(res.data.jwt);
        const fullName = json.fullName;
        const nameParts = fullName.split(" ");
        const firstName = nameParts[0].trim();
        const lastName = nameParts.slice(1).join(" ").trim();
        const uniqueId = json.uniqueId;
        setCheckUniqueId(uniqueId);
        const email = json.channels[0].path;
        setMyAccountUpdate({
          firstName: firstName,
          lastName: lastName,
          email: email,
          language: json.locale,
          uniqueId: uniqueId,
        });
      }
    } catch (error) {
      toast.error("connection_error");
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setMyAccountUpdate({
      ...myAccountUpdate,
      [name]: value,
    });

    switch (name) {
      case "firstName":
        setCheckFirstName("");
        break;
      case "lastName":
        setCheckLastName("");
        break;
      default:
        break;
    }
  };

  const handleUpdateMyAccount = async () => {
    try {
      if (myAccountUpdate.uniqueId === checkUniqueId) {
        const res = await updateMyAccount(
          {
            firstName: myAccountUpdate.firstName,
            lastName: myAccountUpdate.lastName,
            email: myAccountUpdate.email,
          },
          id
        );
        if (res.status === 200) {
          toast.success(t("myAccount_update_successfully"));
        }
      } else {
        const res = await updateMyAccount(myAccountUpdate, id);
        if (res.status === 200) {
          toast.success(t("myAccount_update_successfully"));
        }
      }
    } catch (error) {
      const errorReturn = handleErrorFunction(error, v);
      toast.error(errorReturn);
      if (errorReturn === v("firstName")) {
        setCheckFirstName(false);
      } else {
        setCheckFirstName(true);
      }

      if (errorReturn === v("lastName")) {
        setCheckLastName(false);
      } else {
        setCheckLastName(true);
      }
    }
  };

  const handleUploadButtonClick = () => {
    inputRef.current.click(); // Kích hoạt sự kiện click trên input file ẩn
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdateMyAccount();
    }
  };

  return (
    <div
      className={`homepageorg_layout ${
        common.getLtiLMS() ? "myaccount-page" : ""
      }`}
    >
      {common.getLtiLMS() ? <HeaderBack /> : <></>}
      <div className="homepageorg_title">{t("edit_my_account")}</div>
      <div className="myaccount_bottom">
        <div className="avatar-upload">
          <div className="avatar">
            {avatarImage ? (
              <img
                src={avatarImage}
                alt="Avatar"
                style={{ width: 50, height: 50 }}
              />
            ) : (
              <AccountCircle style={{ fontSize: 50 }} />
            )}
          </div>
          <div className="text-upload">
            <div className="text">{t("profile_picture")}</div>
            <div className="upload">
              <Button
                variant="contained"
                startIcon={<FileUpload />}
                className="button"
                onClick={handleUploadButtonClick}
              >
                {t("upload")}
              </Button>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUpload}
                ref={inputRef}
              />
            </div>
          </div>
        </div>

        <Stack className="input input-text-error">
          <Input
            label={t("first_name")}
            value={myAccountUpdate.firstName}
            name="firstName"
            onChange={handleChangeValue}
            className={checkFirstName === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />
          <div className={checkFirstName === false ? "error-text" : "hidden"}>
            {v("firstName")}
          </div>
        </Stack>
        <Stack className="input input-text-error">
          <Input
            label={t("last_name")}
            value={myAccountUpdate.lastName}
            name="lastName"
            onChange={handleChangeValue}
            className={checkLastName === false ? "border-red" : ""}
            onKeyDown={handleKeyDown}
          />
          <div className={checkLastName === false ? "error-text" : "hidden"}>
            {v("lastName")}
          </div>
        </Stack>
        <Stack className="input input-text-error">
          <Input
            label={t("email")}
            value={myAccountUpdate.email}
            name="email"
            onChange={handleChangeValue}
            disabled="disabled"
          />
        </Stack>
        <Stack className="input input-text-error">
          <Input
            label={t("uniqueID")}
            value={myAccountUpdate.uniqueId}
            name="uniqueId"
            onChange={handleChangeValue}
            onKeyDown={handleKeyDown}
          />
        </Stack>

        {/* <div className="dropdown">
          <Dropdown
            listItem={["English", "French", "Vietnamese"]}
            label={t("language")}
            value={myAccountUpdate.language}
            name="language"
            onChange={handleChangeValue}
          />
        </div> */}
        {/* <div className="checkbox-area">
          <div className="checkbox-title">{t("rich_text_")}</div>
          <div className="subtitle">{t("manage_addition_")}</div>
          <div className="checkbox">
            <FormGroup className="checkbox-group">
              <FormControlLabel
                control={<Checkbox />}
                label={t("inline_code")}
                className="checkbox-item"
              />

              <FormControlLabel
                control={<Checkbox />}
                label={t("code_spinnet")}
                className="checkbox-item"
              />
              <FormControlLabel
                control={<Checkbox />}
                label={t("language")}
                className="checkbox-item"
              />
            </FormGroup>
          </div>
        </div>
        <div className="checkbox-area">
          <div className="checkbox-title">{t("confusion_report")}</div>
          <div className="subtitle">{t("receive_email_")}</div>
          <div className="checkbox">
            <FormGroup className="checkbox-group">
              <FormControlLabel
                control={<Checkbox />}
                label={t("receive_confusion_")}
                className="checkbox-item"
              />
            </FormGroup>
          </div>
        </div>
        <div className="checkbox-area">
          <div className="checkbox-title">{t("learn_qouta")}</div>
          <div className="subtitle">{t("receive_weekly_")}</div>
          <div className="checkbox">
            <FormGroup className="checkbox-group">
              <FormControlLabel
                control={<Checkbox />}
                label={t("receive_learner_")}
                className="checkbox-item"
              />
            </FormGroup>
          </div>{" "}
        </div> */}
        <div className="two-button">
          <Button
            variant="contained"
            className="button"
            onClick={handleUpdateMyAccount}
          >
            {t("save")}
          </Button>
          <Button variant="contained" className="button cancel-button">
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default function MyAccountPage() {
  return (
    <div>
      <HomeLayout
        showSubHeader={!common.getLtiLMS()}
        showToolbar={!common.getLtiLMS()}
        bodyManage={<MyAccount />}
        type="Manage Organization"
      />
    </div>
  );
}
