import React from "react";
import emptyfolder_img from "../../assets/img/EmptyImage/empty-folder.svg";
import "./EmptyFolder.scss";
import { useTranslation } from "react-i18next";

export default function EmptyFolder({ type }) {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.EmptyFolder",
  });
  return (
    <div className="emptyfolder_layout">
      <img src={emptyfolder_img} alt="" />
      <div className="empty-title">
        {type === "trashContent" && t("no_trashed_content")}
        {type === "shareContent" && t("no_shared_content")}
        {type === "myContent" && t("no_my_content")}
        {type === "allContent" && t("no_all_content")}
      </div>
      <div className="empty-subtitle">
        {type === "trashContent" && t("there_are_no_1")}
        {type === "shareContent" && t("there_are_no_2")}
      </div>
    </div>
  );
}
