import React, { memo } from "react";
import { useConnectLMSConext } from "../Context";
import LMSItem from "./LMSItem";


const fakeData = [
  {
    type: "blackboard",
    tutorial_link: "https://help.h5p.com/hc/en-us/articles/7505840839325",
    name: "Blackboard",
    keyLti: "huong01-72838",
    secret: "VXguO10TQNxUoqCBBm2cTQdXVW75LY2w",
    status: "Enabled",
    last_updated: "2024-01-24 08:07:46",
    update_names:
      "Always update the user's name with the one provided by this LMS",
    update_emails:
      " Always update the user's e-mail address with the one provided by this LMS",
  },
  {
    type: "canvas",
    tutorial_link: "https://help.h5p.com/hc/en-us/articles/7507920890653",
    name: "Canvas test 02",
    // help: "Follow this step by step tutorial to set up LTI in Canvas",
    keyLti: "huong01-1d2f3",
    secret: "mjstaYIRmqcFAoN6qX2U6hodKNLALsUd",
    status: "Enabled",
    last_updated: "2024-01-24 07:33:46",
    update_names:
      "Always update the user's name with the one provided by this LMS",
    update_emails:
      " Always update the user's e-mail address with the one provided by this LMS",
  },
  {
    // Other with LTI version 1.1
    type: "other",
    name: "Other test 02",
    keyLti: "huong01-bc32b",
    secret: "Jqek7EI44SBTtcqUF4yV3Nx2zRA222Y5",
    status: "Enabled",
    last_updated: "2024-01-25 02:39:58",
    update_names:
      "Always update the user's name with the one provided by this LMS",
    update_emails:
      " Always update the user's e-mail address with the one provided by this LMS",
  },
];

const dummyData=[
  // {
  //   // LTI v1.3 connection dummy data
  //   tutorial_link: "https://help.h5p.com/hc/en-us/articles/7506408987677-Setting-up-H5P-com-in-Canvas-LTI-1-3",
  //   public_key:"https://khoatrananhhoclieutuongtaccom1.h5p.com/lti/jwks/1292300524345410579.json",
  //   registration_url:"https://khoatrananhhoclieutuongtaccom1.h5p.com/lti/register-canvas/1292300524345410579.json",
  //   status: "Enabled",
  //   last_updated: "2024-01-24 07:33:46",
  //   lti_version:"LTI v1.3",
  //   client_id: "dummyclientid"
  // },
  {
    // LTI v1.3 connection dummy data
    "id": 1,
    "updatedAt": "2024-06-17T07:31:17.936Z",
    "key": null,
    "secret": null,
    "public_key":"https://khoatrananhhoclieutuongtaccom1.h5p.com/lti/jwks/1292300524345410579.json",
    "registration_url":"https://khoatrananhhoclieutuongtaccom1.h5p.com/lti/register-canvas/1292300524345410579.json",
    "last_updated": "2024-01-24 07:33:46",
    "workFlowState": "active",
    "status": "enabled",
    "name": "Canvas 1.3",
    "version": "ltiv1p3",
    "createdAt": "2024-06-17T07:31:17.936Z",
    "client_id": "dummyclientid",
    "lms": {
        "id": 1,
        "name": "Canvas",
        "workFlowState": "active",
        "type": "canvas",
        "createdAt": "2024-06-17T06:26:01.896Z",
        "updatedAt": "2024-06-17T06:26:01.896Z"
    }
  },
  {
    "id": 7,
    "updatedAt": "2024-06-17T07:31:17.936Z",
    "key": "hoang-Hhv8i",
    "secret": "4AbXNPAOST2TkQD0ZCs1UL7aDlL9J7O4",
    "workFlowState": "active",
    "status": "enabled",
    "name": "Canvas",
    "version": "ltiv1p1",
    "createdAt": "2024-06-17T07:31:17.936Z",
    "lms": {
        "id": 1,
        "name": "Canvas",
        "workFlowState": "active",
        "type": "canvas",
        "createdAt": "2024-06-17T06:26:01.896Z",
        "updatedAt": "2024-06-17T06:26:01.896Z"
    }
  }
]

const LMSConnectionList = memo(() => {
  const { connectLMSList } = useConnectLMSConext();
  
  // log LMS list
  console.log(connectLMSList);

  return (
    <div className="connectLMS-list">
      {/* DUMMY DATA */}
      {/* {dummyData.map((item) => (
        <LMSItem key={item.key?item.key:item.public_key} keyLti={item.key} {...item} />
      ))} */}

      {/* ACTUAL DATA */}
      {connectLMSList.map((item) => (
        <LMSItem key={item.key} keyLti={item.key} {...item} />
      ))}
    </div>
  );
});

export default LMSConnectionList;
