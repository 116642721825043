import { Button, Grid } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connectLMSApi } from "../../../../api";
import Select from "../../../../components/Dropdown/Select";
import Input from "../../../../components/Input/Input";
import "../../../HomePageOrg/HomePageOrg.scss";
import "../ConnectLMSPage.scss";
import { useConnectLMSConext } from "../Context";

const ConnectionSettingsForm = memo(({ isModal = false, onCloseModal }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.ConnectLMSPage",
  });


  const [clientId,setClientId] = useState("dummyclientid");
  const [issuer,setIssuer] = useState("dummyissuer");
  const [loginUrl,setLoginUrl] = useState("dummyloginurl");
  const [tokenUrl,setTokenUrl] = useState("dummytokenurl");
  const [keySetUrl,setKeySetUrl] = useState("dummykeyseturl");
  const [audience,setAudience] = useState("dummyAudience");
  const [privateKey,setPrivateKey] = useState("dummyprivatekey");
  
  
  const handleClientIdChange = (e) =>{
    const {value} = e.target;
    setClientId(value);
  }

  const handleIssuerChange = (e) =>{
    const {value} = e.target;
    setIssuer(value);
  }

  const handleLoginUrlChange = (e) =>{
    const {value} = e.target;
    setLoginUrl(value);
  }

  const handleTokenUrlChange = (e) =>{
    const {value} = e.target;
    setTokenUrl(value);
  }

  const handleKeySetUrlChange = (e) =>{
    const {value} = e.target;
    setKeySetUrl(value);
  }

  const handleAudienceChange = (e) =>{
    const {value} = e.target;
    setAudience(value);
  }

  const handlePrivateKeyChange = (e) =>{
    const {value} = e.target;
    setPrivateKey(value);
  }

  const handleSubmit = () =>{
    alert("Submitted");
  }

  return (
    <>
      <Grid
        container
        spacing={2}
      >

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionItem.client_id")}
            value={clientId}
            onChange={handleClientIdChange}
            />
        </Grid>

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.issuer")}
            value={issuer}
            onChange={handleIssuerChange}
            />
        </Grid>


        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.login_url")}
            value={loginUrl}
            onChange={handleLoginUrlChange}
            />
        </Grid>

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.token_url")}
            value={tokenUrl}
            onChange={handleTokenUrlChange}
            />
        </Grid>

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.key_set_url")}
            value={keySetUrl}
            onChange={handleKeySetUrlChange}
            />
        </Grid>

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.audience")}
            value={audience}
            onChange={handleAudienceChange}
            />
        </Grid>

        <Grid item xs={12} md={`${isModal ? 12 : 7}`}>
            <Input
            type="text"
            label={t("connectionSettings.private_key")}
            value={privateKey}
            onChange={handlePrivateKeyChange}
            />
        </Grid>

      </Grid>
      <div className="connection-setting-save-btn">
        <Button
          variant="contained"
          className={`button ${isModal ? "save-btn" : ""}`}
          onClick={handleSubmit}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
});

export default ConnectionSettingsForm;
